<template>
    <CollapseBlock
        :title="i18n.t('callHistoryView.ai.keywordsHeader')"
    >
        <template #add-actions="{ open }">
            <div class="flex items-center gap-2 md:px-2">
                <div
                    :class="{ 'text-inactive-elements': !open }"
                    class="collapse-block__action"
                    @click="toggleViewList(open)"
                >
                    <i
                        :class="viewState === VIEW_LIST ? 'vc-lc-cloud' : 'vc-lc-list'"
                        class="hidden sm:inline-flex"
                    />
                    {{ viewState === VIEW_LIST ? i18n.t('callHistoryView.cloudView') : i18n.t('callHistoryView.listView') }}
                </div>
                <div
                    :class="{ 'text-inactive-elements': !open }"
                    class="collapse-block__action"
                    @click="toggleViewPart(open)"
                >
                    {{ viewPartState === VIEW_ALL ? i18n.t('callHistoryView.showPart') : i18n.t('callHistoryView.showAll') }}
                </div>
            </div>
        </template>
        <template #default="{ open }">
            <template v-if="viewState === VIEW_LIST">
                <CallKeywordsList
                    :open="open"
                    :view-part-state="viewPartState"
                    :caller1-words="caller1Words"
                    :caller2-words="caller2Words"
                />
            </template>
            <template v-else>
                <CallKeywordClouds
                    :open="open"
                    :view-part-state="viewPartState"
                    :caller1-words="caller1Words"
                    :caller2-words="caller2Words"
                />
            </template>
        </template>
    </CollapseBlock>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import CollapseBlock from '@/components/Common/CollapseBlock.vue'
import { TKeyWordsSpeakerMapped } from '@/types/types'
import CallKeywordClouds from '@/components/Blocks/AI/CallKeywordClouds.vue'
import CallKeywordsList from '@/components/Blocks/AI/CallKeywordsList.vue'
import i18n from '@/plugins/i18n.ts'


const VIEW_PART = 'part'
const VIEW_ALL = 'all'
const VIEW_LIST = 'wordList'
const VIEW_CLOUD = 'wordCloud'

const props = defineProps<{
    keywordsSpeaker0: Array<TKeyWordsSpeakerMapped>,
    keywordsSpeaker1: Array<TKeyWordsSpeakerMapped>,
}>()

/* Data */
const viewState = ref<string>(VIEW_CLOUD)
const viewPartState = ref<string>(VIEW_PART)

/* Computed */
const caller1Words = computed(() => {
    return props.keywordsSpeaker0 || []
})
const caller2Words = computed(() => {
    return props.keywordsSpeaker1 || []
})

/* Methods */
function toggleViewPart (isOpen: boolean) {
    if (!isOpen) {
        return
    }
    viewPartState.value = viewPartState.value === VIEW_ALL ? VIEW_PART : VIEW_ALL
}
function toggleViewList (isOpen: boolean) {
    if (!isOpen) {
        return
    }
    viewState.value = viewState.value === VIEW_LIST ? VIEW_CLOUD : VIEW_LIST
}
</script>

