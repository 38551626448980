import { ref } from 'vue'
import { IAiEntityMapping } from '@/types/aiManager'
import { ACCOUNT_ENTITY_TYPE, USER_ENTITY_TYPE } from '@/enums/aiManagement.enum.ts'

export default function useEntityMappingData () {
    const entityAccountsModel = ref<Array<number>>([])
    const entityUsersModel = ref<Array<number>>([])


    const resetEntityMappingData = () => {
        entityAccountsModel.value = []
        entityUsersModel.value = []
    }

    const setEntityAccountModel = (assignAccounts: Array<IAiEntityMapping>) => {
        entityAccountsModel.value = assignAccounts.map(i => i.AiEntityID)
    }
    const setEntityUserModel = (assignUsers: Array<IAiEntityMapping>) => {
        entityUsersModel.value = assignUsers.map(i => i.AiEntityID)
    }

    const getEntityMappingsData = () => {
        const accountMappings = entityAccountsModel.value.map(entity => ({
            AiEntityID: entity,
            AiEntityType: ACCOUNT_ENTITY_TYPE
        }))
        const userMappings = entityUsersModel.value.map(entity => ({
            AiEntityID: entity,
            AiEntityType: USER_ENTITY_TYPE
        }))

        return [ ...accountMappings, ...userMappings ]
    }

    return {
        entityAccountsModel,
        entityUsersModel,
        getEntityMappingsData,
        setEntityAccountModel,
        setEntityUserModel,
        resetEntityMappingData
    }
}
