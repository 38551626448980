<template>
    <div
        :class="{ 'is--opened': isOpen }"
        class="ai-custom-questions__wrapper"
    >
        <transition
            name="ai-chat-anim"
            mode="out-in"
        >
            <div
                v-if="!isOpen"
                class="ai-custom-questions__action relative"
                @click="isOpen = true"
            >
                <div
                    class="rounded-full bg-[var(--ai-questions-primary-color)] shadow-ai-primary cursor-pointer text-white w-16 h-16 relative flex-center"
                >
                    <i class="vc-lc-sparkles icon-3xl" />
                </div>
                <div
                    class="min-w-7 h-7 text-center absolute -top-1.5 -left-1.5 bg-white text-black rounded-full text-xs font-bold p-1.5 shadow-ai-primary select-none pointer-events-none"
                >
                    {{ customQuestions.length }}
                </div>
            </div>
            <div
                v-else
                class="ai-custom-questions"
            >
                <div class="ai-custom-questions__header">
                    <div class="ai-custom-questions__title red-ai-text">
                        <i class="vc-lc-sparkles" />
                        {{ i18n.t('callHistoryView.VoicenterAI') }}
                    </div>
                    <div>
                        <VcButtonIcon
                            icon="vc-lc-x"
                            @click="isOpen = false"
                        />
                    </div>
                </div>
                <div class="ai-custom-questions__content space-y-2">
                    <div
                        v-for="(question, qIndex) in customQuestions"
                        :key="qIndex"
                        class=""
                    >
                        <div class="flex flex-row-reverse items-start">
                            <div class="w-11/12">
                                <div class="text-end text-xs font-semibold py-1">
                                    {{ question.Entity }}
                                </div>
                                <div class="flex-grow rounded-xl pb-2 text-sm text-end">
                                    {{ question.Question }}
                                </div>
                            </div>
                        </div>
                        <div class="w-11/12">
                            <div class="flex items-center gap-1 text-xs font-semibold mb-1">
                                <i class="vc-lc-sparkles red-ai-text" />
                                {{ i18n.t('callHistoryView.VoicenterAI') }}
                            </div>
                            <div class="flex items-start w-full gap-3">
                                <div
                                    class="flex-grow bg-secondary-actions-bg--focus rounded-xl ltr:rounded-tl-none rtl:rounded-tr-none p-2 text-sm"
                                >
                                    {{ question.Answer }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="isUserAdmin"
                            class="py-1 text-end text-xxs"
                        >
                            <VcButton
                                icon="vc-lc-save"
                                type="borderless"
                                color="secondary"
                                size="small"
                                @click="openSaveToCategoryModal(question)"
                            >
                                {{ i18n.t('callHistoryView.customQuestions.saveToCategory') }}
                            </VcButton>
                        </div>
                    </div>
                    <div v-if="!customQuestions.length">
                        <div
                            class="flex-grow bg-secondary-actions-bg--focus rounded-xl ltr:rounded-tl-none rtl:rounded-tr-none p-2 text-sm"
                        >
                            {{ i18n.t('callHistoryView.customQuestions.noQuestions') }}
                        </div>
                    </div>
                </div>
                <div class="ai-custom-questions__footer">
                    <div class="w-full flex items-center gap-2 relative">
                        <VcInput
                            v-model="questionInputModel"
                            :disabled="loading || inputTokensLimitExceeded"
                            :rows="1"
                            type="textarea"
                            class="w-full"
                            @keydown.enter="onEnterKeyPressed"
                        />
                        <VcButton
                            :disabled="!questionInputModel || inputTokensLimitExceeded"
                            :loading="loading"
                            class="!rounded-full !px-2"
                            @click="onSendQuestion"
                        >
                            <span class="flex-center">
                                <i class="vc-lc-send" />
                            </span>
                        </VcButton>
                    </div>
                    <div class="flex items-center justify-between gap-2 pt-2 text-xs">
                        <div class="flex-center gap-1">
                            <AnswerDataTypePopover
                                v-model="answerDataType"
                                :disabled="inputTokensLimitExceeded"
                            />
                        </div>
                        <div class="flex-center gap-1">
                            {{
                                i18n.t('callHistoryView.customQuestions.remainingTokens', {tokens: availableTokens})
                            }}
                            <VcPopover
                                :teleported="isTeleported"
                                trigger="hover"
                                popover-width="280"
                            >
                                <template #reference>
                                    <i class="vc-lc-info cursor-pointer" />
                                </template>
                                <div class="text-xxs overflow-auto p-1 direction-ltr">
                                    <div
                                        v-for="lItem in Object.entries(remainingLimitsData)"
                                        :key="lItem[0]"
                                        class="flex justify-between items-center py-0.5"
                                    >
                                        <span>{{ lItem[0] }}:</span><span>{{ lItem[1] }}</span>
                                    </div>
                                </div>
                            </VcPopover>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <AddQuestionToCategoryModal
            v-if="isUserAdmin"
            ref="addQuestionToGroupModalRef"
        />
    </div>
</template>

<script setup lang="ts">
import useCustomQuestions from '@/composables/useCustomQuestions.ts'
import AnswerDataTypePopover from '@/components/Common/AnswerDataTypePopover.vue'
import { isTeleported } from '@/services/GlobalState.ts'
import { computed, ref, watch } from 'vue'
import { ICustomQAnswerDataType, ICustomQuestionsAskRequest, ICustomQuestionsQuestion } from '@/types/customQuestions'
import { TranscriptionDataRef } from '@/composables/useAIData.ts'
import { CdrDataRef } from '@/composables/useCDRData.ts'
import { getUrlQueryParams } from '@/composables/useQueryData.ts'
import { isUserAdmin } from '@/composables/useJWTPayloadData.ts'
import AddQuestionToCategoryModal from '@/components/CustomQuestions/AddQuestionToCategoryModal.vue'
import ApiAiManager from '@/services/API/ApiAiManager.ts'
import i18n from '@/plugins/i18n.ts'

const {
    customQuestions,
    remainingLimitsData,
    inputTokensLimitExceeded
} = useCustomQuestions()

const loading = ref(false)
const answerDataType = ref<ICustomQAnswerDataType>({
    AnswerDataType: 'string',
    AnswerDataTypeID: 2,
    AnswerDataSchema: undefined
})
const questionInputModel = ref('')
const addQuestionToGroupModalRef = ref<typeof AddQuestionToCategoryModal>()
const isOpen = ref(false)

const availableTokens = computed(() => {
    return Math.max(0, remainingLimitsData.value?.InputTokensDaily || 0)
})

function onEnterKeyPressed () {
    if (!questionInputModel.value) {
        return
    }
    onSendQuestion()
}

function resetData () {
    questionInputModel.value = ''
}

async function onSendQuestion () {
    if (!questionInputModel.value) {
        return
    }
    try {
        loading.value = true
        const { queryID } = getUrlQueryParams()
        const { AnswerDataTypeID, AnswerDataSchema } = answerDataType.value
        const formData: ICustomQuestionsAskRequest = {
            Value: questionInputModel.value,
            Transcript: [ ...TranscriptionDataRef.value ],
            IVRUniqueID: queryID,
            AnswerDataTypeID,
            AnswerDataSchema: AnswerDataTypeID > 3 ? { ...AnswerDataSchema } : undefined,
            AdditionalQuestions: [ ...customQuestions.value ],
            CdrData: { ...CdrDataRef.value }
        }
        await ApiAiManager.customQuestionAsk(formData)
    } finally {
        resetData()
        loading.value = false
    }
}

function openSaveToCategoryModal (question: ICustomQuestionsQuestion) {
    addQuestionToGroupModalRef.value?.open(question)
}

watch(
    customQuestions,
    () => {
        isOpen.value = customQuestions.value.length > 0
    },
    {
        immediate: true
    }
)
</script>

<style lang="scss">
.ai-custom-questions {
    @apply rounded-lg border border-ui-lines overflow-hidden bg-light-bg w-full sm:w-[520px] lg:w-[468px];
    transform-origin: bottom right;

    &__wrapper {
        @apply fixed bottom-4 right-4 lg:bottom-6 lg:right-6 z-10;
        max-width: calc(100vw - 32px);
    }


    &__header {
        @apply flex items-center justify-between gap-2 py-2 px-3 border-b border-ui-lines;
        box-shadow: 0 4px 8px 0 rgba(38, 49, 72, 0.08);
    }

    &__title {
        @apply text-lg font-medium flex items-center gap-2;
    }

    .red-ai-text {
        color: #FF0F26;
    }

    &__content {
        @apply p-4 overflow-y-auto max-h-96 min-h-52;
    }

    &__footer {
        @apply bg-main-bg p-2 rounded-b-lg border-t border-ui-lines;
    }

    p {
        @apply text-sm;
    }

    @keyframes aiBlockAnimate {
        0% {
            clip-path: circle(12% at 88% 85%);
        }
        100% {
            clip-path: circle(200% at 100% 100%);
        }
    }
}

.ai-chat-anim-enter-active {
    animation: aiBlockAnimate 0.2s linear;
}

.ai-chat-anim-leave-active {
    animation: aiBlockAnimate 0.2s linear reverse;
}

</style>
