import { computed, ref } from 'vue'
import { AiData, CallAiOnlyHistoryData, CallHistoryData, IAiOnlyAiData, Participants, Sentence } from '@/types/api'
import { TMessageItem, TMessageItemMapped } from '@/types/types'
import { calcRecordingDuration } from '@/helpers/apiData.helper.ts'
import Decryptor from '@voicenter-team/decryptor-js'

/* Data */
export const AIDataRef = ref<AiData | IAiOnlyAiData | undefined>(undefined)
export const ParticipantsRef = ref<Participants | undefined>(undefined)
export const TranscriptionDataRef = ref<Array<TMessageItemMapped>>([])

/* Computed */
export const hasAiData = computed(() => {
    return !!AIDataRef.value
})
export const debugAiInfo = computed(() => {
    return AIDataRef.value?.token_usage || {}
})
export const aiInsightsSummary = computed(() => {
    return AIDataRef.value?.insights?.summary || ''
})
export const clientName = computed(() => {
    return ParticipantsRef.value?.client?.name || ParticipantsRef.value?.caller?.name || ''
})
export const agentName = computed(() => {
    return ParticipantsRef.value?.agent?.name || ParticipantsRef.value?.callee?.name || ''
})

/* Methods */
export function getParticipants () {
    return ParticipantsRef.value
}

export function getSentences (): Array<Sentence> {
    return AIDataRef.value?.emotions?.sentences || []
}

export function getInsights () {
    return AIDataRef.value?.insights
}

export async function setAiData (data?: CallHistoryData | CallAiOnlyHistoryData) {
    AIDataRef.value = data?.ai_data
    ParticipantsRef.value = data?.ai_data?.insights?.participants
    TranscriptionDataRef.value = await setTranscriptionData(data)
}

export async function setTranscriptionData (data?: CallHistoryData | CallAiOnlyHistoryData) {
    const total_time = calcRecordingDuration(data)

    if (Array.isArray(data?.ai_data?.transcript)) {
        // not encrypted (AI only)
        return getMappedTranscription(data.ai_data.transcript, total_time)
    } else {
        // should be encrypted
        const aiDecryptionData = data?.encrypted_data?.ai_decryption_data
        const transcriptionHex = data?.ai_data?.transcript.transcript

        if (!transcriptionHex || !aiDecryptionData) {
            return []
        }
        const decryptor = new Decryptor()
        const res = await decryptor.data.decryptJsonByData<Array<TMessageItem>>(
            transcriptionHex,
            aiDecryptionData
        )

        if (res && Array.isArray(res)) {
            return getMappedTranscription(res, total_time)
        }
    }
    return []
}

function getMappedTranscription (data: Array<TMessageItem>, endTime: number): Array<TMessageItemMapped> {
    return data.map(el => {
        if (el.endTime !== null) {
            return el as TMessageItemMapped
        }

        return {
            ...el,
            endTime
        }
    })
}
