<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="visible"
        :header="i18n.t('callHistoryView.manageAi.addQuestionTitle')"
        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        width="460px"
        @close="onCloseModal"
    >
        <div class="modal-content w-full">
            <AlertMessageCard
                type="error"
                :title="i18n.t('callHistoryView.manageAi.questionLimitReachedTitle')"
                :message="i18n.t('callHistoryView.manageAi.questionLimitReachedMessage')"
                class="mb-3 lg:mb-5"
            />
            <div class="mb-5">
                <VcInputLabel
                    :label="i18n.t('callHistoryView.manageAi.form.questionToProceed')"
                    required
                />
                <p class="text-secondary text-sm mb-2">
                    {{ i18n.t('callHistoryView.manageAi.form.questionToProceedText') }}
                </p>
                <VcSelect
                    v-model="selectedQuestion"
                    :options="questionsFilteredList"
                    :config="{
                        labelKey: 'Key',
                        valueKey: 'Key'
                    }"
                    :teleported="isTeleported"
                    class="w-full"
                    @change="onSelectQuestion"
                />
            </div>
        </div>
        <template #footer>
            <div class="w-full flex items-center justify-end gap-2">
                <VcButton
                    type="outline"
                    color="secondary"
                    @click="onCloseModal"
                >
                    {{ i18n.t('callHistoryView.cancel') }}
                </VcButton>
                <VcButton
                    :disabled="!selectedQuestion"
                    color="primary"
                    @click="onProceed"
                >
                    {{ i18n.t('callHistoryView.proceed') }}
                </VcButton>
            </div>
        </template>
    </VcModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { isTeleported, modalAppendToElement } from '@/services/GlobalState.ts'
import { IAiQuestionRequest } from '@/types/aiManager'
import AlertMessageCard from '@/components/Card/AlertMessageCard.vue'
import { QUESTION_RELATION_TYPES } from '@/enums/aiManagement.enum.ts'
import i18n from '@/plugins/i18n.ts'

const props = defineProps<{
    questions?: Array<IAiQuestionRequest>
}>()

const visible = ref(false)
const emit = defineEmits<{
    (e: 'proceed', pl: IAiQuestionRequest): void
}>()

const selectedQuestion = ref<number | undefined>(undefined)
const selectedQuestionData = ref<IAiQuestionRequest | undefined>(undefined)

const questionsFilteredList = computed(() => {
    return props.questions?.filter(item => {
        if (item.QuestionRelationType === QUESTION_RELATION_TYPES.original) {
            return !props.questions?.find(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.replacement && i.Key === item.Key)
        }
        return true
    })
})

function onSelectQuestion (question?: IAiQuestionRequest) {
    selectedQuestionData.value = question ? { ...question } : undefined
}

function onCloseModal () {
    visible.value = false
    selectedQuestion.value = undefined
    selectedQuestionData.value = undefined
}

function onOpenModal () {
    visible.value = true
}

function onProceed () {
    if (selectedQuestionData.value) {
        emit('proceed', selectedQuestionData.value)
    }
}

defineExpose({
    open: onOpenModal,
    close: onCloseModal
})
</script>
