<template>
    <div class="create-new-ai-category w-full">
        <div class="create-new-ai-category__nav flex justify-between gap-2">
            <div class="flex-center flex-shrink-0">
                <VcViewSwitcher
                    v-model="viewSwitcherModel"
                    :options="viewSwitcherOptions"
                    :config="{ labelKey: 'name', iconKey: 'icon', valueKey: 'id' }"
                />
            </div>
            <div class="flex items-center justify-end gap-3 flex-shrink-0">
                <VcButton
                    type="outline"
                    color="secondary"
                    @click="onCancel"
                >
                    {{ i18n.t('callHistoryView.cancel') }}
                </VcButton>
                <VcButton
                    :loading="loading"
                    @click="onCreateCategory"
                >
                    {{ i18n.t('callHistoryView.create') }}
                </VcButton>
            </div>
        </div>
        <div class="create-new-ai-category__content py-6">
            <AiCategoryGeneralForm
                v-show="viewSwitcherModel === GENERAL_VIEW_TAB"
                ref="generalFormRef"
                v-model="categoryGeneralModel"
                v-model:engine="categoryEngineModel"
                v-model:entityAccounts="entityAccountsModel"
                v-model:entityUsers="entityUsersModel"
                :before-original-category-change="checkCategoryQuestions"
                :assigned-entity-errors="upsertDataErrors"
                @open:category="onOpenCategoryTab"
                @change:original="onOriginalCategoryChange"
            />
            <AiCategoryQuestions
                v-show="viewSwitcherModel === QUESTIONS_VIEW_TAB"
                :questions="categoryQuestionsModel"
                :max-insight-questions="maxInsightQuestions"
                @question:add="onAddNewQuestion"
                @question:edit="onEditQuestion"
                @question:remove="onRemoveQuestion"
            />
            <AiCategoryPromptConfigs
                v-show="viewSwitcherModel === PROMPTS_VIEW_TAB"
                ref="promptConfigRef"
                :key="categoryEngineModel"
                v-model="categoryPromptConfigs"
                :original-category="originalCategoryData"
            />
        </div>
        <AddCategoryQuestionModal
            ref="addNewQuestionModal"
            :used-question-keys="usedQuestionKeys"
            :show-alert-message="availableCountQuestions === 1"
            @save="onSaveNewQuestionModal"
        />
        <EditCategoryQuestionModal
            ref="editQuestionModal"
            :used-question-keys="usedQuestionKeys"
            @save="onUpdateQuestion"
        />
        <QuestionLimitReachedModal
            ref="proceedQuestionModelRef"
            :questions="categoryQuestionsModel"
            @proceed="onProceedNewQuestionModal"
        />
        <QuestionRemoveQuestionsModal
            ref="questionRemoveModalRef"
            @confirm="onConfirmChangeCategory"
        />
    </div>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from 'vue'
import AiCategoryGeneralForm from '@/components/AiSettings/Forms/AiCategoryGeneralForm.vue'
import {
    CategoryUpsertBulkErrorItem,
    IAiCategoryListItem,
    IAiCategoryUpsert,
    IAiCategoryUpsertRequest,
    IAiInsightsUpsert,
    IAiQuestion,
    IAiQuestionRequest
} from '@/types/aiManager'
import {
    categoryPromptDefaultModel,
    GENERAL_VIEW_TAB,
    getDefaultCategoryModel,
    MAX_INSIGHT_QUESTIONS,
    PROMPTS_VIEW_TAB,
    QUESTION_RELATION_TYPES,
    QUESTIONS_VIEW_TAB,
    viewSwitcherOptions
} from '@/enums/aiManagement.enum.ts'
import AiCategoryQuestions from '@/components/AiSettings/Forms/AiCategoryQuestions.vue'
import AiCategoryPromptConfigs from '@/components/AiSettings/Forms/AiCategoryPromptConfigs.vue'
import useManageAiCategoriesData, { isCustomPromptSettings, isEmptyPrompt } from '@/composables/useManageAiCategories.ts'
import cloneDeep from 'lodash/cloneDeep'
import AddCategoryQuestionModal from '@/components/AiSettings/AddCategoryQuestionModal.vue'
import QuestionLimitReachedModal from '@/components/AiSettings/QuestionLimitReachedModal.vue'
import EditCategoryQuestionModal from '@/components/AiSettings/EditCategoryQuestionModal.vue'
import QuestionRemoveQuestionsModal from '@/components/AiSettings/QuestionRemoveQuestionsModal.vue'
import get from 'lodash/get'
import useAiCategoryEditableData from '@/composables/useAiCategoryEditableData.ts'
import useCategoryUpsertErrors from '@/composables/useCategoryUpsertErrors.ts'
import useEntityMappingData from '@/composables/useEntityMappingData.ts'
import i18n from '@/plugins/i18n.ts'

/* Composable */
const { getCategoryById, AiCategories, upsertCategoryBulk } = useManageAiCategoriesData()

/* Emit */
const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'open:category', pl: number): void
    (e: 'save', pl: IAiCategoryUpsertRequest): void
}>()

/* Refs */
const generalFormRef = ref<typeof AiCategoryGeneralForm>()
const addNewQuestionModal = ref<typeof AddCategoryQuestionModal>()
const editQuestionModal = ref<typeof EditCategoryQuestionModal>()
const proceedQuestionModelRef = ref<typeof QuestionLimitReachedModal>()
const questionRemoveModalRef = ref<typeof QuestionRemoveQuestionsModal>()
const promptConfigRef = ref<typeof AiCategoryPromptConfigs>()

/* Data */
const loading = ref(false)
const viewSwitcherModel = ref(GENERAL_VIEW_TAB)

const {
    categoryGeneralModel,
    categoryEngineModel,
    categoryQuestionsModel,
    maxInsightQuestions,
    categoryPromptConfigs,
    originalCategoryData,
    usedQuestionKeys,
    availableCountQuestions,
    getQuestionsMappedWithUid,
    getPromptSettings,
    resetAllStates,
    setCategoryDataModel,
    addQuestionToGroup,
    updateGroupQuestion
} = useAiCategoryEditableData(getDefaultCategoryModel())

const {
    upsertDataErrors,
    resetAllErrors,
    setEntityDataErrors
} = useCategoryUpsertErrors()

const {
    entityAccountsModel,
    entityUsersModel,
    resetEntityMappingData,
    getEntityMappingsData
} = useEntityMappingData()

/* Methods */
function setCategoryModel (data: IAiCategoryUpsert) {
    resetData()
    viewSwitcherModel.value = GENERAL_VIEW_TAB

    setCategoryDataModel(data)
    generalFormRef.value?.resetForm()
    promptConfigRef.value?.resetForm()
}

function resetData () {
    const initData = {
        ...getDefaultCategoryModel(),
        OriginalCatID: AiCategories.value?.find(i => i.AccountID === 0)?.CategoryID
    }
    resetAllStates(initData)
    viewSwitcherModel.value = GENERAL_VIEW_TAB
    resetEntityMappingData()
    resetAllErrors()
    generalFormRef.value?.resetForm()
}

function onCancel () {
    emit('cancel')
    resetData()
}

function getPromptConfigByCategoryId (categoryId?: number) {
    if (!categoryId) {
        return undefined
    }
    const category = getCategoryById(categoryId)
    return category?.AiPrompts?.Insights?.PromptConfig
}

function replaceOriginalQuestions (questions: Array<Partial<IAiQuestion>>) {
    const customQuestions = categoryQuestionsModel.value.filter(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.custom)
    const originalQuestion = questions.filter(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.original)
    return [
        ...originalQuestion,
        ...customQuestions
    ]
}

function onRemoveQuestion (question: IAiQuestionRequest) {
    categoryQuestionsModel.value = categoryQuestionsModel.value.filter(i => !(i.QuestionRelationType === question.QuestionRelationType && i.Key === question.Key))
}

function onAddNewQuestion () {
    const isReachedLimit = availableCountQuestions.value < 1
    if (isReachedLimit) {
        proceedQuestionModelRef.value?.open()
    } else {
        addNewQuestionModal.value?.open()
    }
}

function onEditQuestion (question: IAiQuestionRequest) {
    editQuestionModal.value?.open({
        ...question,
        QuestionRelationType: question.QuestionRelationType !== QUESTION_RELATION_TYPES.custom ? QUESTION_RELATION_TYPES.replacement : QUESTION_RELATION_TYPES.custom
    })
}

function onConfirmChangeCategory (categoryId: number | undefined, questionKeys: Array<string>) {
    if (categoryId) {
        categoryQuestionsModel.value = [ ...categoryQuestionsModel.value ].filter(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.custom && !questionKeys.includes(i.Key || ''))
        nextTick(() => {
            categoryGeneralModel.value.OriginalCatID = categoryId
        })
    }
}

function checkCategoryQuestions (newCategory?: IAiCategoryListItem, oldCategoryId?: number) {
    if (!newCategory) {
        return true
    }
    let isCustomPrompt = false
    const currentPrompt = { ...categoryPromptConfigs.value }
    const defaultPrompt = getPromptConfigByCategoryId(oldCategoryId)
    if (!isEmptyPrompt(currentPrompt) && !isEmptyPrompt(defaultPrompt)) {
        isCustomPrompt = isCustomPromptSettings(currentPrompt, oldCategoryId)
    }

    const newCategoryQuestions = newCategory?.AiPrompts?.Insights?.Questions?.filter(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.original) || []
    const customCategoryQuestions = categoryQuestionsModel.value.filter(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.custom) || []
    if (!customCategoryQuestions.length && !isCustomPrompt) {
        return true
    }
    if ((newCategoryQuestions.length - customCategoryQuestions.length) >= newCategory?.MaxInsightQuestions) {
        questionRemoveModalRef.value?.open({
            categoryNewId: newCategory?.CategoryID,
            questionsToRemove: [ ...customCategoryQuestions ],
            minCountToRemove: -1,
            isCustomPrompt,
        })
        return false
    }
    if ((newCategoryQuestions.length + customCategoryQuestions.length) > newCategory.MaxInsightQuestions) {
        questionRemoveModalRef.value?.open({
            categoryNewId: newCategory.CategoryID,
            questionsToRemove: [ ...customCategoryQuestions ],
            minCountToRemove: (newCategoryQuestions.length + customCategoryQuestions.length) - newCategory.MaxInsightQuestions,
            isCustomPrompt
        })
        return false
    }
    if (isCustomPrompt) {
        questionRemoveModalRef.value?.open({
            categoryNewId: newCategory.CategoryID,
            questionsToRemove: [],
            minCountToRemove: 0,
            isCustomPrompt,
            onlyPrompt: true
        })
    }
    return true
}

function onUpdateQuestion (question: IAiQuestionRequest) {
    updateGroupQuestion(question)
    editQuestionModal.value?.close()
}

function onSaveNewQuestionModal (question: IAiQuestionRequest) {
    addQuestionToGroup(question)
    addNewQuestionModal.value?.close()
}

function onOriginalCategoryChange (category?: IAiCategoryListItem) {
    const promptConfigs = getPromptConfigByCategoryId(category?.CategoryID) || categoryPromptDefaultModel
    categoryPromptConfigs.value = cloneDeep(promptConfigs)
    maxInsightQuestions.value = originalCategoryData.value?.MaxInsightQuestions || MAX_INSIGHT_QUESTIONS
    categoryQuestionsModel.value = replaceOriginalQuestions(category?.AiPrompts?.Insights?.Questions || [])
        .map(getQuestionsMappedWithUid)
    categoryEngineModel.value = category?.AiPrompts?.Insights?.AiEngineID || categoryEngineModel.value
}

function onProceedNewQuestionModal (question: IAiQuestionRequest) {
    proceedQuestionModelRef.value?.close()
    onEditQuestion(question)
}

async function formValidate () {
    const isGeneralFormValid = await generalFormRef.value?.validate()
    const isPromptFormValid = await promptConfigRef.value?.validate()
    if (!isGeneralFormValid.isValid) {
        viewSwitcherModel.value = GENERAL_VIEW_TAB
    } else if (!isPromptFormValid.isValid) {
        viewSwitcherModel.value = PROMPTS_VIEW_TAB
    }

    return isGeneralFormValid.isValid && isPromptFormValid.isValid
}

async function onCreateCategory () {
    // validate forms
    const isValid = await formValidate()
    if (!isValid) {
        return
    }
    const {
        CategoryName,
        CategoryType,
        AccountID,
        MaxInsightQuestions,
        Description,
        OriginalCatID,
        Status
    } = categoryGeneralModel.value

    const EntityMappings = getEntityMappingsData()

    const Insights: IAiInsightsUpsert = {
        AiEngineID: categoryEngineModel.value,
        PromptConfig: {
            ...getPromptSettings()
        },
        EntityMappings,
        Questions: [ ...categoryQuestionsModel.value ].filter(i => i.QuestionRelationType !== QUESTION_RELATION_TYPES.original)
    }

    const groupRequestData: IAiCategoryUpsertRequest = {
        CategoryName,
        AccountID,
        Status,
        MaxInsightQuestions,
        Description,
        OriginalCatID,
        CategoryType,
        AiPrompts: {
            Insights
        }
    }

    await createAiCategory({
        ...groupRequestData
    })
}

async function createAiCategory (category: IAiCategoryUpsertRequest) {
    try {
        loading.value = true
        resetAllErrors()
        await upsertCategoryBulk([ category ])
        emit('save', category)
    } catch (e) {
        const status = get(e, 'axiosResponse.response.status')
        if (status === 409) {
            viewSwitcherModel.value = GENERAL_VIEW_TAB
            const response = (get(e, 'axiosResponse.response.data.Data') || []) as Array<CategoryUpsertBulkErrorItem>
            setDataErrors(response)
        }
    } finally {
        loading.value = false
    }
}

function setDataErrors (response: Array<CategoryUpsertBulkErrorItem>) {
    setEntityDataErrors(response)
}

function setDefaultCategoryData (categories: Array<IAiCategoryListItem>) {
    const defaultCategory = categories[0]
    if (defaultCategory && !categoryGeneralModel.value.OriginalCatID) {
        categoryGeneralModel.value.OriginalCatID = defaultCategory.CategoryID
        categoryEngineModel.value = defaultCategory.AiPrompts?.Insights?.AiEngineID || categoryEngineModel.value
        maxInsightQuestions.value = originalCategoryData.value?.MaxInsightQuestions || MAX_INSIGHT_QUESTIONS
        categoryQuestionsModel.value = replaceOriginalQuestions(originalCategoryData.value?.AiPrompts?.Insights?.Questions || []).map(getQuestionsMappedWithUid)
        categoryPromptConfigs.value = cloneDeep(getPromptConfigByCategoryId(defaultCategory.CategoryID) || categoryPromptDefaultModel)
    }
}

function onOpenCategoryTab (categoryId: number) {
    emit('open:category', categoryId)
}

defineExpose({
    setData: setCategoryModel,
    cancel: onCancel
})

watch(
    AiCategories,
    (categories) => {
        setDefaultCategoryData(categories)
    }
)

// resetAllStates()
</script>
