<template>
    <div class="search-input-field">
        <VcInput
            v-model="model"
            :placeholder="i18n.t('callHistoryView.searchUp')"
            prefix-icon="vc-lc-search"
            prefix-icon-size="2md"
            type="text"
            color="secondary"
            class="border-none"
            size="small"
            clearable
            v-bind="$attrs"
        />
    </div>
</template>

<script setup lang="ts">
import i18n from '@/plugins/i18n.ts'

const model = defineModel<string>()
</script>

<style lang="scss">
    .search-input-field {
        @apply
        inline-flex items-center gap-1
        border border-ui-lines hover:border-secondary-actions--focus
        rounded-[100px]
        text-xs
        h-auto
        cursor-pointer;
        transition: var(--base-transition);
        &:has(.el-input__wrapper.is-focus), &:has(input:not(:placeholder-shown)) {
            @apply border-secondary-actions--focus;
            width: 230px;
        }
        .el-input {
            --el-input-height: 26px;
            --el-input-border-radius: 100px;

            .el-input__wrapper {
                @apply px-2 border-none shadow-none cursor-pointer;
                box-shadow: none !important;
                .el-input__prefix-inner {
                    i {
                        margin-right: 0;
                    }
                }
                input {
                    transition: var(--base-transition);
                    width: 0;
                    opacity: 0;
                }

                &.is-focus, &:has(input:not(:placeholder-shown)) {
                    .el-input__prefix-inner {
                        i {
                            margin-right: .25rem;
                        }
                    }
                    input {
                        @apply opacity-100 w-40;
                    }
                }
            }
        }
    }
</style>
