import { reactive, ref, toRefs } from 'vue'
import { IPermissionList } from '@/types/permissions'
import ApiAiManager from '@/services/API/ApiAiManager.ts'

/* Entity Configs */
export const ENTITY_TYPE_ACCOUNT = 'Accounts'
export const ENTITY_TYPE_USER = 'Users'

/* Filters */
export const EntityFilter = [ ENTITY_TYPE_ACCOUNT, ENTITY_TYPE_USER ]
export const EntityObjectKeyFilter = {
    [ENTITY_TYPE_ACCOUNT]: [ 'AccountID', 'DistName' ],
    [ENTITY_TYPE_USER]: [ 'UserID', 'UserName', 'Status' ]
}

/* Entity States */
const permissionsLoadingState = ref(false)
const EntityPermissionState = reactive<IPermissionList>({
    Accounts: [],
    Users: []
})

/* Composable Setup */
export default function () {

    const { Accounts, Users } = toRefs(EntityPermissionState)

    const fetchPermissionList = async () => {
        // TODO: change it after backend update (objects to arrays in response)
        permissionsLoadingState.value = true
        try {
            const response = await ApiAiManager.getPermissionList()
            EntityPermissionState.Accounts = Object.values(response.Accounts || {})
            EntityPermissionState.Users = Object.values(response.Users || {}).filter(user => user.Status === 1)
        } catch (error) {
            console.error('Failed to fetch permission list:', error)
        } finally {
            permissionsLoadingState.value = false
        }
    }

    return {
        Accounts,
        Users,
        permissionsLoadingState,
        fetchPermissionList
    }
}
