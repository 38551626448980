import { computed, ref, Ref } from 'vue'
import { isUserAdmin } from '@/composables/useJWTPayloadData.ts'
import CallHistoryGeneralTab from '@/components/Tabs/CallHistoryGeneralTab.vue'
import { hasAiData } from '@/composables/useAIData.ts'
import CallHistoryAITab from '@/components/Tabs/CallHistoryAITab.vue'
import { hasDialerData, hasIvrData, isLeadInfoTabVisible } from '@/composables/useCDRData.ts'
import LeadInfoTab from '@/components/Tabs/LeadInfoTab.vue'
import { hasRelatedCalls } from '@/composables/useRelatedCallsData.ts'
import CallHistoryRelatedTab from '@/components/Tabs/CallHistoryRelatedTab.vue'
import CallHistoryIvrTab from '@/components/Tabs/CallHistoryIvrTab.vue'
import CallQueueDataTab from '@/components/Tabs/CallQueueDataTab.vue'
import CallDiallerDataTab from '@/components/Tabs/CallDiallerDataTab.vue'
import CallSipFlowTab from '@/components/Tabs/CallSipFlowTab.vue'
import CallRtcpStatsTab from '@/components/Tabs/CallRtcpStatsTab.vue'
import RecordingLogsTab from '@/components/Tabs/RecordingLogsTab.vue'
import ApiLogsTab from '@/components/Tabs/ApiLogsTab.vue'
import BillingLogsTab from '@/components/Tabs/BillingLogsTab.vue'
import CallHistoryFormsTab from '@/components/Tabs/CallHistoryFormsTab.vue'

export type TTabComponentData = {
    key: string
    dataKey?: string
    label: string
    icon: string
    lazy: boolean
    order?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any
    isVisible?: Ref<boolean>
}

export const TAB_GENERAL_KEY = 'general'
export const TAB_AI_DATA_KEY = 'ai_data'
export const TAB_LEAD_INFO_KEY = 'leadInfo'
export const TAB_RELATED_CALLS_KEY = 'relatedCalls'
export const TAB_IVR_DATA_KEY = 'IVR'
export const TAB_QUEUE_DATA_KEY = 'queueData'
export const TAB_DIALLER_DATA_KEY = 'diallerData'
export const TAB_SIP_FLOW_KEY = 'SIPFlows'
export const TAB_RTCP_STAT_KEY = 'RTCPStatistics'
export const TAB_RECORDING_LOGS_KEY = 'recordingLogs'
export const TAB_API_LOGS_KEY = 'APILogs'
export const TAB_BILLING_LOGS_KEY = 'billingLogs'
export const TAB_FORMS_KEY = 'forms'

const isAdmin = computed(() => {
    return !!isUserAdmin.value
})

/* General Tabs */
export const generalTab = {
    key: TAB_GENERAL_KEY,
    dataKey: 'cdr_data',
    label: 'callHistoryView.tabs.general',
    icon: 'vc-lc-file icon-base',
    lazy: true,
    order: 1,
    isVisible: ref(true),
    component: CallHistoryGeneralTab
}
/* AI Tab */
export const aiTab = {
    key: TAB_AI_DATA_KEY,
    dataKey: 'ai_data',
    label: 'callHistoryView.tabs.aiData',
    icon: 'vc-lc-clipboard-check icon-base',
    lazy: true,
    order: 2,
    isVisible: hasAiData,
    component: CallHistoryAITab
}
/* User Tabs */
export const leadInfoTab = {
    key: TAB_LEAD_INFO_KEY,
    label: 'callHistoryView.tabs.leadInfo',
    icon: 'vc-lc-filter icon-base',
    lazy: true,
    order: 3,
    isVisible: isLeadInfoTabVisible,
    component: LeadInfoTab
}
export const relatedCallsTab = {
    key: TAB_RELATED_CALLS_KEY,
    label: 'callHistoryView.tabs.relatedCalls',
    icon: 'vc-lc-phone icon-base',
    lazy: true,
    order: 4,
    isVisible: hasRelatedCalls,
    component: CallHistoryRelatedTab
}
export const IVRTab = {
    key: TAB_IVR_DATA_KEY,
    label: 'callHistoryView.tabs.ivr',
    icon: 'vc-lc-workflow icon-base',
    lazy: true,
    order: 5,
    isVisible: hasIvrData,
    component: CallHistoryIvrTab
}
export const queuesTab = {
    key: TAB_QUEUE_DATA_KEY,
    label: 'callHistoryView.tabs.queueData',
    icon: 'vc-lc-users icon-base',
    lazy: true,
    order: 6,
    isVisible: ref(true),
    component: CallQueueDataTab
}
export const diallerTab = {
    key: TAB_DIALLER_DATA_KEY,
    label: 'callHistoryView.tabs.diallerData',
    icon: 'vc-lc-smartphone-nfc icon-base',
    lazy: true,
    order: 7,
    isVisible: hasDialerData,
    component: CallDiallerDataTab
}
/* Admin Tabs */
export const sipFlowTab = {
    key: TAB_SIP_FLOW_KEY,
    label: 'callHistoryView.tabs.sip',
    icon: 'vc-lc-phone-forwarded icon-base',
    lazy: true,
    order: 8,
    isVisible: isAdmin,
    component: CallSipFlowTab
}
export const rtcpStatsTab = {
    key: TAB_RTCP_STAT_KEY,
    label: 'callHistoryView.tabs.rtcp',
    icon: 'vc-lc-router icon-base',
    lazy: true,
    order: 9,
    isVisible: isAdmin,
    component: CallRtcpStatsTab
}
export const recordLogsTab = {
    key: TAB_RECORDING_LOGS_KEY,
    label: 'callHistoryView.tabs.recording',
    icon: 'vc-lc-cassette-tape icon-base',
    lazy: true,
    order: 10,
    isVisible: ref(isAdmin.value && false), //TODO: remove it after implementing tab logic
    component: RecordingLogsTab
}
export const apiLogsTab = {
    key: TAB_API_LOGS_KEY,
    label: 'callHistoryView.tabs.apiLogs',
    icon: 'vc-lc-webhook icon-base',
    lazy: true,
    order: 11,
    isVisible: ref(isAdmin.value && false), //TODO: remove it after implementing tab logic
    component: ApiLogsTab
}
export const billingLogsTab = {
    key: TAB_BILLING_LOGS_KEY,
    label: 'callHistoryView.tabs.billing',
    icon: 'vc-lc-receipt-text icon-base',
    lazy: true,
    order: 12,
    isVisible: ref(isAdmin.value && false), //TODO: remove it after implementing tab logic
    component: BillingLogsTab
}
export const formsTab = {
    key: TAB_FORMS_KEY,
    label: 'callHistoryView.tabs.forms',
    icon: 'vc-lc-layout-panel-top icon-base',
    lazy: true,
    order: 13,
    isVisible: isAdmin,
    component: CallHistoryFormsTab
}

export const TABS_LIST: Array<TTabComponentData> = [
    generalTab,
    aiTab,
    leadInfoTab,
    relatedCallsTab,
    IVRTab,
    queuesTab,
    diallerTab,
    sipFlowTab,
    rtcpStatsTab,
    recordLogsTab,
    apiLogsTab,
    billingLogsTab,
    formsTab
]

export const VOICENTER_TABS_LIST = [
    TAB_GENERAL_KEY,
    TAB_AI_DATA_KEY,
    TAB_LEAD_INFO_KEY,
    TAB_RELATED_CALLS_KEY,
    TAB_IVR_DATA_KEY,
    TAB_QUEUE_DATA_KEY,
    TAB_DIALLER_DATA_KEY,
    TAB_SIP_FLOW_KEY,
    TAB_RTCP_STAT_KEY,
    TAB_RECORDING_LOGS_KEY,
    TAB_API_LOGS_KEY,
    TAB_BILLING_LOGS_KEY,
    TAB_FORMS_KEY,
]


export const OFFICERING_TABS_LIST = [
    TAB_AI_DATA_KEY
]
