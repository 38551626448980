<template>
    <div
        :class="{'is--printed': printState}"
        class="speaker-emotions-card w-full"
    >
        <div class="speaker-emotions-card__title">
            <div
                class="user-head-info"
            >
                <div
                    class="user-head-info__title"
                >
                    <span
                        class="user-state-color"
                        :style="{ backgroundColor: color }"
                    />
                    <span class="user-head-info__user inline-flex">
                        {{ data.name || i18n.t('callHistoryView.noName') }}
                    </span>
                </div>
                <div class="user-head-info__data">
                    {{ speakerDataText }}
                </div>
            </div>
            <div
                v-if="!printState"
                class="flex-shrink-0 meter-group-data"
            >
                <VcMeterGroup
                    :value="emotionMetrics"
                    :max="0"
                    :height="'8px'"
                    hide-label
                    class=""
                >
                    <template #meter="slotProps">
                        <span
                            :class="slotProps.class"
                            :title="slotProps.value.label"
                            :style="{
                                background: `linear-gradient(to ${isRtl ? 'left' : 'right'}, ${slotProps.value.color}, ${slotProps.value.color2})`,
                                width: slotProps.size

                            }"
                        />
                    </template>
                </VcMeterGroup>
            </div>
        </div>

        <div class="speaker-emotions-card__content">
            <div class="w-full p-3 grid grid-cols-4">
                <div class="col-span-3">
                    <EmotionsPolarChart
                        :user="user"
                        @emotion:click="clickEmotion"
                    />
                </div>

                <div>
                    <div class="py-4 px-3 rounded-sm bg-primary-alternative-actions-bg--focus">
                        <div class="content-block-title">
                            {{ i18n.t('callHistoryView.ai.personalityTraits') }}
                        </div>
                        <div class="pt-2">
                            <div
                                v-for="item in topPersonalityTraits"
                                :key="item"
                                class="text-item mb-2"
                            >
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import i18n from '@/plugins/i18n.ts'
import { IMeterItem } from '@voicenter-team/voicenter-ui-plus'
import {
    getEmotionColor,
    getEmotionStrength,
    ParticipantsDataExtended,
    totalCallDuration
} from '@/composables/useAiTabData.ts'
import { useCallAiTabInject } from '@/composables/callAiTabProvideInject.ts'
import TagsWorker from '@/services/TagsWorker.ts'
import { getParsedMessageTimeFromSeconds } from '@/helpers/dateTime.helper.ts'
import { USER_AGENT_TYPE, UserTypes } from '@/enums/aiTab.enum.ts'
import { AgentColor, ClientColor } from '@/composables/useColorsComposable.ts'
import EmotionsPolarChart from '@/components/Chart/EmotionsPolarChart.vue'
import { TEmotionCallerClickData } from '@/types/types'

const { emotionClickHandler } = useCallAiTabInject()

/* Props */
const props = withDefaults(
    defineProps<{
        data: ParticipantsDataExtended,
        user: UserTypes,
        printState?: boolean
    }>(),
    {
        printState: false
    })

/* Emit */
const emit = defineEmits<{
    (e: 'emotion:click', payload: TEmotionCallerClickData): void
}>()

/* Computed */
const color = computed(() => {
    return props.user === USER_AGENT_TYPE ? AgentColor.value : ClientColor.value
})
const isRtl = computed(() => {
    return TagsWorker.isRtl
})
const speakerDataText = computed(() => {
    return `${getParsedMessageTimeFromSeconds(props.data.total_talking_time)} (${talkTimePercentage.value}%)`
})
const talkTimePercentage = computed(() => {
    return Math.round(props.data.total_talking_time * 100 / totalCallDuration.value)
})
const topPersonalityTraits = computed(() => {
    return props.data.personality_traits?.slice(0, 5) ?? []
})
const emotionsWithStrength = computed(() => {
    return getEmotionStrength(props.data.emotions).sort((a, b) => b.strength - a.strength)
})
const strongestEmotions = computed(() => {
    return emotionsWithStrength.value.slice(0, 5)
})
const emotionMetrics = computed<Array<IMeterItem>>(() => {
    const _data = strongestEmotions.value
    return _data.map((item, index) => {
        const nextItem = _data[index + 1] ?? item

        return {
            value: item.strength,
            label: item.emotion,
            color: getEmotionColor(item.emotion),
            color2: getEmotionColor(nextItem.emotion)
        }
    })
})

/* Methods */
function clickEmotion (data: string) {
    if (emotionClickHandler) {
        emotionClickHandler({
            type: data,
            label: data,
            user: props.user
        })
    }

    emit('emotion:click', {
        type: data,
        label: data,
        user: props.user
    })
}
</script>

<style lang="scss">
.speaker-emotions-card {
    &__title {
        @apply flex flex-col py-3 px-3 xl:px-4 border-b border-ui-lines gap-2 lg:gap-3 leading-[28px];
    }
    &__content {
        @apply py-3 px-3 xl:px-4;
    }
    .meter-group-data {
        @apply w-full;
    }
    .user-head-info {
        @apply flex gap-2 flex-wrap  items-center;
    }
    .user-head-info__title {
        @apply font-medium gap-2 flex items-center;
    }
    .user-head-info__data {
        @apply text-xs lg:text-sm;
    }
    .user-state-color {
        @apply flex-shrink-0 rounded-full text-sm lg:text-base;
        width: 8px;
        height: 8px;
    }
    .content-block-title {
        @apply text-xs uppercase text-placeholders font-semibold;
    }
    .emotion-name-item, .text-item {
        @apply text-xs capitalize font-medium;
    }
}
@container (min-width: 1700px) {
    .speaker-emotions-card {
        .speaker-emotions-card__title {
            @apply flex-row justify-between items-center;
        }
        .meter-group-data {
            @apply xl:w-[200px];
        }
    }
}
.speaker-emotions-card.is--printed {
    .speaker-emotions-card__title {
        @apply flex-col text-sm items-start;
    }
    .user-head-info__user, .user-head-info__data {
        @apply leading-none h-auto flex items-center text-sm;
    }

    .user-head-info__title, .user-head-info {
        @apply flex items-center leading-none text-sm;
    }
    .meter-group-data {
        @apply w-[200px];
    }
    .user-state-color {
        width: 8px;
        height: 8px;
    }
    .speaker-emotions-card__content {
        @apply text-xs py-0;
    }
    .emotion-name-item, .text-item, .content-block-title {
        @apply text-xs;
    }
    .min-w-44 {
        @apply min-w-36;
    }
}
</style>
