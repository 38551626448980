<template>
    <div class="w-full queue-data-tab">
        <div class="queue-data-tab__content">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.queue.title') }}
            </div>
            <div class="queue-data-tab__info flex flex-wrap gap-y-3 gap-x-4 lg:gap-x-6 xl:gap-x-10">
                <div
                    v-for="(itemCard, i) in infoBlocks"
                    :key="`item-${i}`"
                    class="call-history-info-card overflow-hidden"
                    style="min-width:172px "
                >
                    <div class="uppercase font-semibold text-placeholders text-xs">
                        {{ itemCard.title }}
                    </div>
                    <div
                        class="text-default-text font-medium pt-3 lg:pt-4 flex items-center gap-1.5 whitespace-break-spaces"
                    >
                        <span>
                            {{ itemCard.content || i18n.t('callHistoryView.empty') }}
                        </span>
                    </div>
                </div>
                <div
                    class="call-history-info-card overflow-hidden"
                    style="min-width:172px "
                >
                    <div class="uppercase font-semibold text-placeholders text-xs">
                        {{ i18n.t(`callHistoryView.queue.status`) }}
                    </div>
                    <div
                        v-if="dialStatusName"
                        class="text-default-text font-medium pt-3 lg:pt-4 flex items-center gap-2 whitespace-break-spaces"
                    >
                        <i
                            :class="[
                                i18n.t(`callHistoryView.queue.dialStatus.${dialStatusName}.icon`),
                                'icon-md'
                            ]"
                            :style="{ color: i18n.t(`callHistoryView.queue.dialStatus.${dialStatusName}.color`) }"
                        />
                        <span
                            :style="{ color: i18n.t(`callHistoryView.queue.dialStatus.${dialStatusName}.color`) }"
                        >
                            {{ i18n.t(`callHistoryView.queue.dialStatus.${dialStatusName}.name`) }}
                        </span>
                        <VcPopover
                            :teleported="isTeleported"
                            trigger="hover"
                            popover-max-width="280"
                        >
                            <template #reference>
                                <i class="vc-lc-info icon-base text-icons cursor-pointer" />
                            </template>
                            <div class="p-2 text-center text-balance">
                                {{ i18n.t(`callHistoryView.queue.dialStatus.${dialStatusName}.description`) }}
                            </div>
                        </VcPopover>
                    </div>
                    <div
                        v-else
                        class="text-default-text font-medium pt-3 lg:pt-4 flex items-center gap-2 whitespace-break-spaces"
                    >
                        {{ i18n.t('callHistoryView.empty') }}
                    </div>
                </div>
            </div>
            <hr class="border-ui-lines my-10">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.queue.tableTitle') }}
            </div>
            <div class="queue-data-tab__table flex">
                <VcTable
                    :columns="queueTabTableEnum"
                    :options="queueData"
                    :empty-data-text="i18n.t('callHistoryView.noData')"
                    class="w-max"
                    stripe
                >
                    <template #time-row="{ row }">
                        {{ formatDate(row.StarTime) }}
                    </template>
                    <template #duration-row="{ row }">
                        {{ getParsedMessageTimeFromSeconds(row.RingDuration) }}
                    </template>
                </VcTable>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import i18n from '@/plugins/i18n.ts'
import { formatDate, getParsedMessageTimeFromSeconds, getParsedTimeFromSeconds } from '@/helpers/dateTime.helper.ts'
import queueTabTableEnum from '@/enums/tables/queueTab.table.enum.ts'
import { getCdrData } from '@/composables/useCDRData.ts'
import { isTeleported } from '@/services/GlobalState.ts'


const cdrData = computed(() => {
    return getCdrData()
})
const queueData = computed(() => {
    return cdrData.value?.QueueAgentRing || []
})
const dialStatusName = computed(() => {
    return (cdrData.value?.dialstatus_name || '').toLocaleLowerCase()
})

const infoBlocks = computed(() => {
    return [
        {
            title: i18n.t('callHistoryView.queue.queueName'),
            content: cdrData.value?.queueName || i18n.t('callHistoryView.empty')
        },
        {
            title: i18n.t('callHistoryView.queue.queueID'),
            content: cdrData.value?.queueID || i18n.t('callHistoryView.empty')
        },
        {
            title: i18n.t('callHistoryView.queue.waitingTime'),
            content: getParsedTimeFromSeconds(cdrData.value?.SecondsWaitingInQueue || 0)
        }
    ]
})
</script>

