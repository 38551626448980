import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import i18n from '@/plugins/i18n.ts'
import { computed, ComputedRef } from 'vue'

const queueTabTableEnum: ComputedRef<ColumnType> = computed(() => {
    return [
        {
            columnItemKey: 'time-column',
            rowItemKeyKey: 'time-row',
            rowItemLabelKey: 'StarTime',
            columnItemLabel: i18n.t('callHistoryView.queue.table.time'),
            sortBy: 'StarTime',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'agent-column',
            rowItemKeyKey: 'agent-row',
            rowItemLabelKey: 'ExtensionName',
            columnItemLabel: i18n.t('callHistoryView.queue.table.agent'),
            sortBy: 'ExtensionName',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'duration-column',
            rowItemKeyKey: 'duration-row',
            rowItemLabelKey: 'RingDuration',
            columnItemLabel: i18n.t('callHistoryView.queue.table.duration'),
            sortBy: 'RingDuration',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'outcome-column',
            rowItemKeyKey: 'outcome-row',
            rowItemLabelKey: 'RingingStatus',
            columnItemLabel: i18n.t('callHistoryView.queue.table.status'),
            sortBy: 'RingingStatus',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        }
    ]
})

export default queueTabTableEnum
