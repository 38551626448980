import { App } from 'vue'
import NotifyService from '@/composables/useNotifyService'
import CustomI18n from '@/plugins/i18n.ts'

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $notify: typeof NotifyService;
        $t: typeof CustomI18n['t']
    }
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$notify = { ...NotifyService }
        // app.provide(globalNotifySymbol, NotifyService)
    }
}
