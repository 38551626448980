import BaseApiManager from '@/services/API/BaseApiManager.ts'
import {
    CategoryUpdateStatusRequest,
    IAiCategoryListResponse,
    IAiCategoryUpsertBulkErrorResponse,
    IAiCategoryUpsertRequest,
    IAiQuestionRequest,
    ICallAiConfigData
} from '@/types/aiManager'
import { RawAxiosRequestHeaders } from 'axios'
import { DISABLED_GROUP_STATUS, ENABLED_GROUP_STATUS } from '@/enums/aiManagement.enum.ts'
import { EntityFilter, EntityObjectKeyFilter } from '@/composables/usePermissionEntities.ts'
import { IPermissionListResponse } from '@/types/permissions'
import {
    ICustomQuestionOptimizeRequest,
    ICustomQuestionOptimizeResponseError,
    ICustomQuestionOptimizeResponseSuccess,
    ICustomQuestionsAskRequest,
    ICustomQuestionsAskResponseError,
    ICustomQuestionsAskResponseSuccess,
    ICustomQuestionsListResponse
} from '@/types/customQuestions'
import useCustomQuestions from '@/composables/useCustomQuestions.ts'

class ApiAiManagerService extends BaseApiManager {
    constructor () {
        super()
    }

    public async getAIConfData () {
        const url = '/CallAiConfig/Config/'
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()
        const { Data } = await this.service.get(url, headers) as { Data: ICallAiConfigData }

        return Data
    }

    public async getCategoryList () {
        const url = '/CallAiConfig/CategoryList/'
        const StatusList = [ ENABLED_GROUP_STATUS, DISABLED_GROUP_STATUS ]
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const { Data } = await this.service.post(url, { StatusList }, headers) as IAiCategoryListResponse

        return Data || []
    }

    public async categoryUpdateStatus (formData: CategoryUpdateStatusRequest) {
        const url = '/CallAiConfig/CategoryUpdateStatus/'
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const { Data } = await this.service.post(url, { Data: [ formData ] }, headers) as IAiCategoryListResponse

        return Data || []
    }

    public async getPermissionList (filter = EntityFilter) {
        const url = '/Login/PermissionList/'
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const { Data } = await this.service.post(url, {
            ByID: false,
            EntityFilter: filter,
            EntityObjectKeyFilter
        }, headers) as IPermissionListResponse

        return Data || []
    }

    public async upsertCategoryBulk (categories: Array<IAiCategoryUpsertRequest>) {
        const url = '/CallAiConfig/CategoryUpsertBulk/'
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const { Data } = await this.service.post(url, { Data: categories }, headers) as IAiCategoryUpsertBulkErrorResponse

        return Data
    }

    public async removeQuestionsBulk (questions: Array<number>) {
        const url = '/CallAiConfig/QuestionDeleteBulk/'
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const formRequest = questions.map(i => ({ QuestionID: i }))

        const { Data } = await this.service.post(url, { Data: formRequest }, headers) as IAiCategoryUpsertBulkErrorResponse

        return Data
    }

    public async updateQuestionsBulk (questions: Array<IAiQuestionRequest>) {
        const url = '/CallAiConfig/QuestionUpsertBulk/'
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const response = await this.service.post(url, { Data: questions }, headers) as IAiCategoryUpsertBulkErrorResponse
        const ReasonPhrase = response.ReasonPhrase
        return ReasonPhrase ? JSON.parse(ReasonPhrase)[0] : undefined
    }


    public async getCustomQuestionList (queryID: string) {
        const headers = this.getDefaultHeaders()
        const { Data } = await this.service.get(`/CustomQuestion/List/${queryID}`, headers) as {
            Data: ICustomQuestionsListResponse
        }

        return Data
    }

    public async customQuestionAsk (formData: ICustomQuestionsAskRequest) {
        const {
            refreshRemainingLimitsData,
            addNewCustomQuestion,
            setInputTokensLimitExceededState
        } = useCustomQuestions()
        // /CustomQuestion/Ask/
        const headers = this.getDefaultHeaders()
        const {
            Data,
            Status
        } = await this.service.post('/CustomQuestion/Ask/', formData, headers) as ICustomQuestionsAskResponseSuccess | ICustomQuestionsAskResponseError
        if (Status === 400) {
            setInputTokensLimitExceededState(true)
            refreshRemainingLimitsData(Data)
        } else {
            setInputTokensLimitExceededState(false)
            refreshRemainingLimitsData(Data.ConfigData?.RemainingLimitsData?.RemainingLimits)
            const answerData = Data.OutputData
            addNewCustomQuestion({
                Question: answerData.Value,
                Answer: answerData.Answer,
                AnswerDataTypeID: answerData.AnswerDataTypeID,
                AnswerDataSchema: answerData.AnswerDataSchema,
                Entity: answerData.Entity
            })
        }

        return Data
    }

    public async customQuestionOptimize (data: ICustomQuestionOptimizeRequest) {
        // /CustomQuestion/Optimize/
        const headers = this.getDefaultHeaders()
        const {
            refreshRemainingLimitsData,
            setInputTokensLimitExceededState
        } = useCustomQuestions()
        const {
            Data,
            Status
        } = await this.service.post('/CustomQuestion/Optimize/', data, headers) as ICustomQuestionOptimizeResponseSuccess | ICustomQuestionOptimizeResponseError
        if (Status === 400) {
            setInputTokensLimitExceededState(true)
            refreshRemainingLimitsData(Data)
            return {}
        } else {
            setInputTokensLimitExceededState(false)
            refreshRemainingLimitsData(Data.ConfigData?.TokenLimits?.RemainingLimits)
            return Data.OutputData
        }
    }
}

export default new ApiAiManagerService()
