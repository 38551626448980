<template>
    <div class="w-full overflow-hidden">
        <CollapseBlock
            :title="i18n.t('callHistoryView.ai.questionsHeader')"
            class="main-key-points max-w-full"
        >
            <template #add-actions="{ open }">
                <div class="flex md:px-2 w-auto">
                    <div
                        :class="{ 'text-inactive-elements': !open }"
                        class="collapse-block__action uppercase"
                        @click="toggleExpandAll(open)"
                    >
                        <i class="vc-lc-list" />
                        {{ expandAll ? i18n.t('callHistoryView.collapseAll') : i18n.t('callHistoryView.expandAll') }}
                    </div>
                </div>
            </template>
            <template #default="{ open }">
                <div
                    v-if="open"
                    class="main-key-points__content"
                >
                    <div class="main-key-points__columns flex-grow">
                        <div
                            v-for="(column, i) in chunkedMessages"
                            :key="`col-${i}`"
                            class="main-key-points__column"
                        >
                            <ContentEllipsesCard
                                v-for="(msg, j) in column"
                                ref="contentCardRef"
                                :key="`msg-${i}-${j}`"
                                :question="msg"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </CollapseBlock>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { Question } from '@/types/api'
import i18n from '@/plugins/i18n.ts'
import CollapseBlock from '@/components/Common/CollapseBlock.vue'
import ContentEllipsesCard from '@/components/Card/ContentEllipsesCard.vue'

const { questions } = defineProps<{
    questions?: Array<Question>
}>()

const contentCardRef = ref<Array<typeof ContentEllipsesCard>>([])
const expandAll = ref<boolean>(false)

const chunkedMessages = computed(() => {
    if (!questions) {
        return []
    }
    const columnCount = 2
    const cols: Array<Array<Question>> = [ ...new Array(columnCount) ].map(() => [])
    questions?.forEach((item, i) => {
        const index = i % cols.length
        cols[index].push(item)
    })
    return cols
})

function toggleExpandAll (isOpen: boolean) {
    if (!isOpen) {
        return
    }
    const newVal = !expandAll.value
    if (newVal) {
        contentCardRef.value?.forEach(i => i.expand())
    } else {
        contentCardRef.value?.forEach(i => i.collapse())
    }
    expandAll.value = newVal
}

</script>

<style lang="scss">
.main-key-points {
    &__content {
        @apply w-full flex flex-col lg:flex-row gap-x-4 gap-y-3 pt-3;
    }

    &__column {
        @apply flex flex-col gap-3;
    }

    .main-key-points__columns {
        @apply grid grid-cols-1 md:grid-cols-2  gap-x-4 gap-y-3;
    }
}
</style>
