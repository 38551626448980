<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="modalVisible"
        :header="i18n.t('callHistoryView.manageAi.addQuestionTitle')"
        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        width="480px"
        @close="onCloseModal"
    >
        <div class="w-full">
            <div>
                <AlertMessageCard
                    v-if="oneQuestionAlert"
                    type="info"
                    :title="i18n.t('callHistoryView.manageAi.oneQuestionAllowedTitle')"
                    :message="i18n.t('callHistoryView.manageAi.oneQuestionAllowedMessage')"
                    class="mb-3 lg:mb-5"
                />
                <AlertMessageCard
                    v-else-if="questionLimitReachedAlert"
                    type="info"
                    :title="i18n.t('callHistoryView.manageAi.questionLimitReachedTitle')"
                    :message="i18n.t('callHistoryView.manageAi.questionLimitReachedMessage')"
                    class="mb-3 lg:mb-5"
                />
            </div>
            <div class="w-full mb-5">
                <VcFormItem label="Select Category">
                    <VcSelect
                        v-model="categoryID"
                        :loading="isAiCategoriesLoading"
                        :options="categories"
                        :config="{
                            labelKey: 'CategoryName',
                            valueKey: 'CategoryID'
                        }"
                        :teleported="isTeleported"
                        @change="onCategoryChange"
                    >
                        <template #option="{ option }">
                            <div class="flex items-center justify-between gap-2">
                                <span>{{ option.CategoryName }}</span>
                                <span class="px-2 text-secondary">({{
                                    option.QuestionsLength
                                }}/{{ option.MaxInsightQuestions }})</span>
                            </div>
                        </template>
                    </VcSelect>
                </VcFormItem>
            </div>
            <div
                v-if="questionLimitReachedAlert"
                class="mb-5"
            >
                <VcInputLabel
                    :label="i18n.t('callHistoryView.manageAi.form.questionToOverwrite')"
                    required
                />
                <p class="text-secondary text-sm mb-2">
                    {{ i18n.t('callHistoryView.manageAi.form.questionToProceedText') }}
                </p>
                <VcSelect
                    v-model="questionToReplaceID"
                    :options="questionsFilteredList"
                    :config="{
                        labelKey: 'Key',
                        valueKey: 'QuestionID'
                    }"
                    :teleported="isTeleported"
                    class="w-full"
                    @change="onSelectQuestionToReplace"
                />
                <div
                    v-if="questionToReplaceData"
                    class="py-2"
                >
                    <VcCollapseItem
                        title="Original Prompt:"
                        header-class="text-sm font-medium"
                    >
                        <div class="w-full text-sm rounded bg-main-bg p-1.5 border border-ui-lines">
                            {{ questionToReplaceData.Value }}
                        </div>
                    </VcCollapseItem>
                </div>
            </div>
            <AiGeneralQuestionForm
                ref="questionGeneralFormRef"
                v-model="questionData"
                :used-keys="usedQuestionKeys"
            />
        </div>
        <template #footer>
            <div class="w-full flex items-center justify-between gap-2">
                <div
                    ref="optimizeBtnRef"
                    class="flex-center"
                >
                    <VcSplitButton
                        v-if="isButtonVisible"
                        :loading="optimizeLoading"
                        :disabled="isButtonDisabled"
                        :options="optimizePromptOptions"
                        :teleported="isTeleported"
                        color="primary-alternative"
                        @click="handleOptimizePrompt"
                    />
                </div>
                <div class="flex items-center gap-2">
                    <VcButton
                        type="outline"
                        color="secondary"
                        @click="onCloseModal"
                    >
                        {{ i18n.t('callHistoryView.cancel') }}
                    </VcButton>
                    <VcButton
                        :loading="loading"
                        :disabled="(questionLimitReachedAlert && !questionToReplaceID) || !categoryID"
                        color="primary"
                        @click="onSaveModal"
                    >
                        {{ i18n.t('callHistoryView.add') }}
                    </VcButton>
                </div>
            </div>
        </template>
    </VcModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { isTeleported, modalAppendToElement } from '@/services/GlobalState.ts'
import { ICustomQuestionsQuestion } from '@/types/customQuestions'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import { ENABLED_GROUP_STATUS, QUESTION_RELATION_TYPES } from '@/enums/aiManagement.enum.ts'
import { IAiCategoryListItem, IAiQuestionRequest } from '@/types/aiManager'
import AlertMessageCard from '@/components/Card/AlertMessageCard.vue'
import AiGeneralQuestionForm from '@/components/AiSettings/Forms/AiGeneralQuestionForm.vue'
import { useGlobalDataInject } from '@/composables/useGlobalProvideInject.ts'
import i18n from '@/plugins/i18n.ts'
import { VcSplitButton } from '@voicenter-team/voicenter-ui-plus'
import useOptimizeQuestionButton from '@/composables/useOptimizeQuestionButton.ts'

const DEFAULT_DATA_TYPE_ID = 2
const DEFAULT_DATA_TYPE = 'string'

const { refreshData } = useGlobalDataInject()
const { AiCategories, fetchAiCategoryList, isAiCategoriesLoading, upsertQuestion } = useManageAiCategoriesData()

const loading = ref(false)
const modalVisible = ref(false)
const questionData = ref<IAiQuestionRequest>({
    Value: '',
    Key: '',
    AnswerDataType: DEFAULT_DATA_TYPE,
    AnswerDataTypeID: DEFAULT_DATA_TYPE_ID,
    AnswerDataSchema: undefined,
    QuestionRelationType: QUESTION_RELATION_TYPES.custom,
    QuestionID: undefined
})
const categoryID = ref<number | undefined>(undefined)
const categoryData = ref<IAiCategoryListItem | undefined>(undefined)
const questionToReplaceID = ref<number | undefined>(undefined)
const questionToReplaceData = ref<IAiQuestionRequest | undefined>(undefined)
const questionGeneralFormRef = ref<typeof AiGeneralQuestionForm>()
const optimizeBtnRef = ref()

/* Optimize prompt/schema button */
const {
    isButtonVisible,
    optimizeLoading,
    isButtonDisabled,
    optimizePromptOptions,
    handleOptimizePrompt
} = useOptimizeQuestionButton(questionData, optimizeBtnRef)

const categories = computed(() => {
    return AiCategories.value.filter(i => i.AccountID && i.Status === ENABLED_GROUP_STATUS)
})
const oneQuestionAlert = computed(() => {
    return categoryData.value && (categoryData.value.MaxInsightQuestions - (categoryData.value.QuestionsLength || 0)) === 1
})
const questionLimitReachedAlert = computed(() => {
    return categoryData.value && (categoryData.value.MaxInsightQuestions - (categoryData.value.QuestionsLength || 0)) < 1
})
const usedQuestionKeys = computed(() => {
    return categoryData.value?.AiPrompts?.Insights?.Questions?.map(i => ({ Key: i.Key ?? '' }))
})
const questionsFilteredList = computed(() => {
    const questions = categoryData.value?.AiPrompts?.Insights?.Questions
    return questions?.filter(item => {
        if (item.QuestionRelationType === QUESTION_RELATION_TYPES.original) {
            return !questions?.find(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.replacement && i.Key === item.Key)
        }
        return true
    })
})

function resetData () {
    questionData.value = {
        Value: '',
        Key: '',
        AnswerDataType: DEFAULT_DATA_TYPE,
        AnswerDataTypeID: DEFAULT_DATA_TYPE_ID,
        AnswerDataSchema: undefined,
        QuestionRelationType: QUESTION_RELATION_TYPES.custom,
        QuestionID: undefined
    }
    loading.value = false
    categoryID.value = undefined
    categoryData.value = undefined
    questionToReplaceID.value = undefined
    questionToReplaceData.value = undefined
}

function onCloseModal () {
    modalVisible.value = false
    resetData()
}

function onOpenModal (initialData: ICustomQuestionsQuestion) {
    const { Question = '', AnswerDataTypeID = DEFAULT_DATA_TYPE_ID, AnswerDataSchema } = initialData
    questionData.value.Value = Question
    questionData.value.AnswerDataTypeID = AnswerDataTypeID
    questionData.value.AnswerDataSchema = AnswerDataSchema
    fetchAiCategoryList()
    modalVisible.value = true
}

function onSaveModal () {
    const PromptID = categoryData.value?.AiPrompts?.Insights?.PromptID
    questionGeneralFormRef.value?.validate().then(({ isValid }: { isValid: boolean }) => {
        if (isValid && PromptID) {
            loading.value = true
            const { Key, Value, AnswerDataSchema, AnswerDataTypeID, QuestionRelationType } = questionData.value
            const formData = {
                Key: Key?.replace(/\s+/g, '_'),
                Value,
                PromptID,
                AnswerDataSchema,
                AnswerDataTypeID,
                QuestionRelationType
            }
            onSaveQuestion(formData)
            onCloseModal()
        }
    })
}

function onSaveQuestion (formData: IAiQuestionRequest) {
    loading.value = true
    upsertQuestion(formData)
        .then(() => {
            if (refreshData) {
                refreshData(true)
            }
            onCloseModal()
        })
        .finally(() => {
            loading.value = false
        })
}

function onCategoryChange (category?: IAiCategoryListItem) {
    categoryData.value = category ? { ...category } : undefined
    questionToReplaceID.value = undefined
    questionData.value = {
        ...questionData.value,
        QuestionID: undefined,
        QuestionRelationType: QUESTION_RELATION_TYPES.custom,
        AnswerDataType: DEFAULT_DATA_TYPE,
        AnswerDataTypeID: DEFAULT_DATA_TYPE_ID,
        AnswerDataSchema: undefined,
        Key: ''
    }
    questionGeneralFormRef.value?.resetForm()
}

function onSelectQuestionToReplace (question?: IAiQuestionRequest) {
    if (question) {
        questionData.value = {
            ...questionData.value,
            QuestionID: question.QuestionID,
            QuestionRelationType: question.QuestionRelationType !== QUESTION_RELATION_TYPES.custom ? QUESTION_RELATION_TYPES.replacement : QUESTION_RELATION_TYPES.custom,
            AnswerDataType: question.AnswerDataType,
            AnswerDataTypeID: question.AnswerDataTypeID,
            AnswerDataSchema: question.AnswerDataSchema,
            Key: question.Key
        }
    }
    questionToReplaceData.value = question ? { ...question } : undefined
}


defineExpose({
    open: onOpenModal,
    close: onCloseModal
})
</script>
