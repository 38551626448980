<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="visible"
        :header="modalHeader"

        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        @close="onCloseModal"
    >
        <div class="lg:px-5">
            <FormItemRenderer
                ref="formItemRenderer"
                v-model="formModel"
                :categories="formParameterCategories"
                :form-name="formData?.FormName || ''"
                :col-count="2"
            />
        </div>
        <template #footer>
            <div class="w-full flex justify-between">
                <VcButton
                    color="secondary"
                    type="outline"
                    icon="vc-lc-x"
                    @click="onCloseModal"
                >
                    {{ i18n.t('callHistoryView.close') }}
                </VcButton>
                <VcButton
                    :loading="loading"
                    icon="vc-lc-save"
                    @click="onSave"
                >
                    {{ i18n.t('callHistoryView.save') }}
                </VcButton>
            </div>
        </template>
    </VcModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { IFormsMappedData } from '@/types/api'
import { FORM_DATA_LAST_UPDATED_KEY, formsData, mapFormParameterCategories } from '@/composables/useCallForms.ts'
import FormItemRenderer from '@/components/Forms/FormItemRenderer.vue'
import { FormRendererModelType } from '@/types/form-renderer'
import { flatten } from 'flat'
import ApiManager from '@/services/API/ApiManager.ts'
import { ObjectAnyType } from '@/types/types'
import format from 'date-fns/format'
import i18n from '@/plugins/i18n.ts'
import { setTechnicalData } from '@/composables/useCDRData.ts'
import { isWidgetState, usedWidgetShadowRootEl } from '@/services/GlobalState.ts'


const emit = defineEmits([ 'updated:form' ])

const loading = ref<boolean>(false)
const visible = ref(false)
const modalHeader = ref('')
const formItemRenderer = ref<typeof FormItemRenderer>()
const formModel = ref<FormRendererModelType>({})
const formData = ref<IFormsMappedData | undefined>()

const formParameterCategories = computed(() => {
    const formParameterCategories = formData.value?.ParameterCategories ?? []

    return mapFormParameterCategories(formParameterCategories)
})
const modalAppendToElement = computed(() => {
    if (isWidgetState.value && !!usedWidgetShadowRootEl.value) {
        const el = usedWidgetShadowRootEl.value as HTMLElement
        return el || 'body'
    }
    return 'body'
})

function onCloseModal () {
    visible.value = false
}
function onOpenModal (data: IFormsMappedData) {
    formData.value = { ...data }
    formModel.value = { ...data.FormData }
    modalHeader.value = `Edit ${data.FormName || 'Form'}`
    visible.value = true
}
function formatFormLastUpdatedDateString () {
    const now = new Date()

    return format(now, 'yyyy-MM-dd HH-mm-ss')
}

async function onSave () {
    const isValid = await formItemRenderer.value?.validateForm()
    if (!isValid) {
        return
    }
    loading.value = true
    const currentModel = { ...formModel.value }
    const allData = { ...formsData.value }
    allData[`f${formData.value?.FormID}`] = {
        ...currentModel,
        [FORM_DATA_LAST_UPDATED_KEY]: formatFormLastUpdatedDateString()
    }
    const formsDataFlatten: ObjectAnyType = flatten({
        Forms: {
            ...allData
        },
    },  { safe: true })

    ApiManager.InsertCallForm(formsDataFlatten)
        .then(() => {
            setTechnicalData({
                ...currentModel,
                [FORM_DATA_LAST_UPDATED_KEY]: formatFormLastUpdatedDateString()
            }, `f${formData.value?.FormID}`)
            emit('updated:form')
            onCloseModal()

        })
        .finally(() => loading.value = false)

}

defineExpose({
    close: onCloseModal,
    open: onOpenModal
})
</script>

