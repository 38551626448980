<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="visible"
        :header="i18n.t('callHistoryView.confirmation.action')"
        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        width="460px"
        @close="onCloseModal"
    >
        <div class="w-full">
            <div
                v-if="isCustomPrompt"
                class="w-full text-center text-balance font-medium"
            >
                {{ i18n.t('callHistoryView.manageAi.confirmToChangeCustomPrompt') }}
            </div>
            <template v-if="!onlyPrompt">
                <div
                    v-if="minCountToRemove === -1"
                    class="w-full text-center text-balance font-medium"
                >
                    {{ i18n.t('callHistoryView.manageAi.confirmToRemoveAllCustomQuestions') }}
                </div>
                <div v-else>
                    <div class="w-full text-center text-balance font-medium">
                        {{
                            i18n.t('callHistoryView.manageAi.confirmToRemoveSomeCustomQuestions', {number: minCountToRemove})
                        }}
                    </div>
                    <div class="py-3 lg:py-5 flex justify-center">
                        <VcCheckboxGroup
                            v-model="selectedQuestions"
                            :checkboxes="questionsToRemove"
                            :visible-all="false"
                            :config="{
                                valueKey: 'Key',
                                labelKey: 'Key'
                            }"
                        />
                    </div>
                </div>
            </template>
        </div>
        <template #footer>
            <div class="w-full flex items-center justify-end gap-3">
                <VcButton
                    type="outline"
                    color="secondary"
                    @click="onCloseModal"
                >
                    {{ i18n.t('callHistoryView.cancel') }}
                </VcButton>
                <VcButton
                    :disabled="selectedQuestions.length < minCountToRemove"
                    color="primary"
                    @click="onConfirmRemove"
                >
                    {{ i18n.t('callHistoryView.confirm') }}
                </VcButton>
            </div>
        </template>
    </VcModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { modalAppendToElement } from '@/services/GlobalState.ts'
import { IAiQuestionRequest } from '@/types/aiManager'
import i18n from '@/plugins/i18n.ts'

type IModalSetupData = {
    categoryNewId: number | undefined,
    questionsToRemove: Array<IAiQuestionRequest>,
    minCountToRemove: number,
    isCustomPrompt?: boolean
    onlyPrompt?: boolean
}

const emit = defineEmits<{
    (e: 'confirm', catId: number | undefined, questionKeys: Array<string>): void
}>()

const visible = ref(false)
const categoryNewId = ref<number | undefined>(undefined)
const questionsToRemove = ref<Array<IAiQuestionRequest>>([])
const minCountToRemove = ref(-1) // -1 - remove all questions
const selectedQuestions = ref<Array<string>>([])
const isCustomPrompt = ref(false)
const onlyPrompt = ref(false)

function resetModal () {
    categoryNewId.value = undefined
    questionsToRemove.value = []
    minCountToRemove.value = -1
    selectedQuestions.value = []
}

function onCloseModal () {
    visible.value = false
    resetModal()
}

function onConfirmRemove () {
    const questions = minCountToRemove.value === -1 ? questionsToRemove.value.map(i => i.Key || '') : [ ...selectedQuestions.value ]
    emit('confirm', categoryNewId.value, [ ...questions ])
    onCloseModal()
}

function onOpenModal (modalSetupData: IModalSetupData) {
    categoryNewId.value = modalSetupData.categoryNewId
    questionsToRemove.value = modalSetupData.questionsToRemove
    minCountToRemove.value = modalSetupData.minCountToRemove
    isCustomPrompt.value = modalSetupData.isCustomPrompt ?? false
    onlyPrompt.value = modalSetupData.onlyPrompt ?? false
    visible.value = true
}

defineExpose({
    open: onOpenModal,
    close: onCloseModal
})
</script>


