<template>
    <div class="manage-ai-categories">
        <div class="manage-ai-categories__action">
            <VcButton
                :loading="isLoading"
                icon="vc-lc-webhook"
                type="outline"
                color="secondary"
                @click="openManageModal"
            >
                {{ i18n.t('callHistoryView.manageAi.action') }}
            </VcButton>
        </div>
        <div class="manage-ai-categories__modal">
            <ManageAiCategoriesModal
                ref="manageAiCategoriesModalRef"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import ManageAiCategoriesModal from '@/components/AiSettings/ManageAiCategoriesModal.vue'
import { computed, ref } from 'vue'
import usePermissionEntities from '@/composables/usePermissionEntities.ts'
import i18n from '@/plugins/i18n.ts'

const { isAiConfigsLoading } = useManageAiCategoriesData()
const { permissionsLoadingState, fetchPermissionList } = usePermissionEntities()

const manageAiCategoriesModalRef = ref<typeof ManageAiCategoriesModal>()

const isLoading = computed(() => {
    return isAiConfigsLoading.value || permissionsLoadingState.value
})

function openManageModal () {
    manageAiCategoriesModalRef.value?.open()
}

fetchPermissionList()
</script>
