let globalUniqueIdState = 0

export default function useUniqueID () {
    return ++globalUniqueIdState
}

export function useUniqueIDWithPrefix (prefix?: string): () => string | number {
    let uniqueId = 0
    return function () {
        if (prefix) {
            return `${prefix}-${++uniqueId}`
        }
        return ++uniqueId
    }
}
