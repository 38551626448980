import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import i18n from '@/plugins/i18n.ts'
import { computed, ComputedRef } from 'vue'

const tableAiCategoryQuestionsColumns: ComputedRef<ColumnType> = computed(() => {
    return [
        {
            columnItemKey: 'key-column',
            rowItemKeyKey: 'key-row',
            rowItemLabelKey: 'Key',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.questionParameter'),
            sortBy: 'Key',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'prompt-column',
            rowItemKeyKey: 'prompt-row',
            rowItemLabelKey: 'Value',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.questionPrompt'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'relation-column',
            rowItemKeyKey: 'relation-row',
            rowItemLabelKey: 'RelationName',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.questionRelation'),
            columnAlignment: 'center',
            rowItemAlignment: 'center',
            sortBy: 'RelationName'
        },
        {
            columnItemKey: 'data-column',
            rowItemKeyKey: 'data-row',
            rowItemLabelKey: 'AnswerDataType',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.questionDataType'),
            columnAlignment: 'center',
            rowItemAlignment: 'center',
            sortBy: 'AnswerDataType',
            columnClasses: 'break-keep whitespace-nowrap'
        },
        {
            columnItemKey: 'actions-column',
            rowItemKeyKey: 'actions-row',
            rowItemLabelKey: 'Actions',
            columnItemLabel: '',
            columnAlignment: 'center',
            rowItemAlignment: 'center',
        }
    ]
})

export default tableAiCategoryQuestionsColumns
