<template>
    <div class="w-full sip-flow-tab relative">
        <div class="sip-flow-tab__content">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.sip.messagesTitle') }}
            </div>
            <div class="sip-flow-tab__messages">
                <VcTable
                    :columns="sipFlowTableColumns"
                    :options="dataMapped"
                    :loading="dataLoading"
                    :empty-data-text="i18n.t('callHistoryView.noData')"
                    with-pagination
                    compact
                    stripe
                >
                    <template #FullView-row="{ row }">
                        <div class="w-full flex-center px-2">
                            <i
                                class="vc-lc-file-code icon-base"
                                @click="openPreviewFlowMsg(row)"
                            />
                        </div>
                    </template>
                </VcTable>
            </div>
            <hr class="border-ui-lines my-10">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.sip.flowTitle') }}
            </div>
            <div
                class="sip-flow-tab__flow w-full"
                dir="ltr"
            >
                <VcSipFlow
                    ref="sipFlowRef"
                    :active-line="activeMessage"
                    :options="options"
                    :events="dataMapped"
                    :format-date="formatDateFn"
                    :loading="dataLoading"

                    message-key="_id"
                    @click:message="openPreviewFlowMsg"
                />
            </div>
        </div>
        <SipFlowSidebar
            ref="sipFlowSidebarRef"
            @close="onClosePreview"
        />

        <SipIcons />
    </div>
</template>

<script setup lang="ts">
import { ISipFlowOptions, SipMessageFlowType, VcSipFlow } from '@voicenter-team/voicenter-ui-plus'
import SipFlowSidebar from '@/components/Blocks/Sip/SipFlowSidebar.vue'
import { ref } from 'vue'
import useSipFlowMessages from '@/composables/useSipFlowMessages.ts'
import { formatDate } from '@/helpers/dateTime.helper.ts'
import sipFlowTableColumns from '@/enums/tables/sipMessages.table.enum.ts'
import SipIcons from '@/components/Icons/SipIcons.vue'
import i18n from '@/plugins/i18n.ts'

const FORMAT = 'yyyy-MM-dd hh:mm:ss.SSS'

const { dataMapped, dataLoading } = useSipFlowMessages()

const sipFlowSidebarRef = ref<typeof SipFlowSidebar>()
const activeMessage = ref<string | undefined>(undefined)

function formatDateFn (date: number | string | Date) {
    return formatDate(date, FORMAT)
}

function openPreviewFlowMsg (data?: SipMessageFlowType) {
    if (!data) {
        return
    }
    activeMessage.value = `${data._id}`
    sipFlowSidebarRef.value?.open(data)
}

function onClosePreview () {
    activeMessage.value = undefined
}

const options: ISipFlowOptions = {
    listServerIcons: {
        Default: '#OpenSIPS',
        Asterisk: '#Asterisk',
        Audiocodes: '#Audiocodes',
        Bria: '',
        Broadsoft: '#Broadsoft',
        Cisco: '',
        Fanvil: '#Fanvil',
        FreeSWITCH: '',
        Grandstream: '#Grandstream',
        Jitsi: '',
        Kamailio: '',
        Mediant: '',
        Microsip: '',
        OpenSIPS: '#OpenSIPS',
        SBC: '',
        SNOM: '#Snom',
        Teams: '#Teams',
        VoicenterSoftPhone: '#VoicenterSoftPhone',
        WebRTC: '',
        Xcite: '',
        Yealink: '#Yealink',
        Zoiper: ''
    }
}

</script>

