import { IAiCategoryListItem, IAiCategoryUpsert, IAiPromptConfig } from '@/types/aiManager'
import i18n from '@/plugins/i18n'
import { computed } from 'vue'

export const ACCOUNT_ENTITY_TYPE = 1
export const USER_ENTITY_TYPE = 2
export const ENABLED_GROUP_STATUS = 1
export const DISABLED_GROUP_STATUS = 2
export const DELETED_GROUP_STATUS = 3
export const CATEGORY_TYPE_SYSTEM = 'System'
export const CATEGORY_TYPE_ACCOUNT = 'Account'
export const MAX_INSIGHT_QUESTIONS = 10

export const QUESTION_RELATION_TYPES = {
    original: 1,
    replacement: 2,
    custom: 3
}

export const defaultCreateCategoryModel: IAiCategoryUpsert = {
    CategoryID: undefined,
    Status: ENABLED_GROUP_STATUS,
    AccountID: undefined,
    OriginalCatID: undefined,
    CategoryName: '',
    Description: '',
    MaxInsightQuestions: MAX_INSIGHT_QUESTIONS,
    CategoryType: CATEGORY_TYPE_ACCOUNT
}
export const categoryPromptDefaultModel: IAiPromptConfig = {
    Body: '',
    Footer: '',
    System: '',
    Caching: {
        Class: '',
        Version: ''
    },
    MaxTokens: 0,
    Transcript: ''
}

export const GENERAL_VIEW_TAB = 'general'
export const QUESTIONS_VIEW_TAB = 'questions'
export const PROMPTS_VIEW_TAB = 'prompts'

export const viewSwitcherOptions = computed(() => {
    return [
        {
            id: GENERAL_VIEW_TAB,
            name: i18n.t('callHistoryView.manageAi.tabs.general'),
            icon: 'vc-lc-file'
        },
        {
            id: QUESTIONS_VIEW_TAB,
            name: i18n.t('callHistoryView.manageAi.tabs.questions'),
            icon: 'vc-lc-message-circle-question'
        },
        {
            id: PROMPTS_VIEW_TAB,
            name: i18n.t('callHistoryView.manageAi.tabs.promptConfiguration'),
            icon: 'vc-lc-cog'
        }
    ]
})

export function getDefaultCategoryModel (setupData: IAiCategoryUpsert = {}) {
    return {
        ...defaultCreateCategoryModel,
        ...setupData,
    }
}

export function isSystemCategory (category: IAiCategoryListItem) {
    return !category.AccountID || category.CategoryType === CATEGORY_TYPE_SYSTEM
}

export const optimizePromptOptionsAll = computed(() => {
    return [
        {
            id: 1,
            label: i18n.t('callHistoryView.manageAi.enhancePromptOptions.optimizePrompt'),
            value: {
                OptimizePrompt: true,
                OptimizeSchema: false
            }
        },
        {
            id: 2,
            label: i18n.t('callHistoryView.manageAi.enhancePromptOptions.optimizeSchema'),
            value: {
                OptimizePrompt: false,
                OptimizeSchema: true
            }
        },
        {
            id: 3,
            label: i18n.t('callHistoryView.manageAi.enhancePromptOptions.optimizeAll'),
            value: {
                OptimizePrompt: true,
                OptimizeSchema: true
            }
        },
    ]
})
export const optimizePromptOption = computed(() => {
    return [
        {
            id: 1,
            label: i18n.t('callHistoryView.manageAi.enhancePromptOptions.optimizePrompt'),
            value: {
                OptimizePrompt: true,
                OptimizeSchema: false
            }
        }
    ]
})
