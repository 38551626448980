<template>
    <div class="edit-ai-category w-full">
        <div class="edit-ai-category__nav flex justify-between gap-2">
            <div class="flex-center flex-shrink-0">
                <VcViewSwitcher
                    v-model="viewSwitcherModel"
                    :options="viewSwitcherOptions"
                    :config="{ labelKey: 'name', iconKey: 'icon', valueKey: 'id' }"
                />
            </div>
            <div class="flex items-center justify-end gap-3 flex-shrink-0">
                <VcButton
                    type="outline"
                    color="secondary"
                    @click="onCancel"
                >
                    {{ i18n.t('callHistoryView.cancel') }}
                </VcButton>
                <VcButton
                    :loading="loading"
                    @click="onSaveCategory"
                >
                    {{ i18n.t('callHistoryView.save') }}
                </VcButton>
            </div>
        </div>
        <div class="edit-ai-category__content py-6">
            <AiCategoryGeneralForm
                v-show="viewSwitcherModel === GENERAL_VIEW_TAB"
                ref="generalFormRef"
                v-model="categoryGeneralModel"
                v-model:engine="categoryEngineModel"
                v-model:entityAccounts="entityAccountsModel"
                v-model:entityUsers="entityUsersModel"
                :assigned-entity-errors="upsertDataErrors"
                edit-mode
                @open:category="onOpenCategoryTab"
            />
            <AiCategoryQuestions
                v-show="viewSwitcherModel === QUESTIONS_VIEW_TAB"
                :questions="categoryQuestionsModel"
                :max-insight-questions="maxInsightQuestions"
                :loading="loadingQuestions"
                @question:add="onAddNewQuestion"
                @question:edit="onEditQuestion"
                @question:remove="onRemoveQuestion"
            />
            <AiCategoryPromptConfigs
                v-show="viewSwitcherModel === PROMPTS_VIEW_TAB"
                ref="promptConfigRef"
                :key="categoryEngineModel"
                v-model="categoryPromptConfigs"
                :original-category="originalCategoryData"
            />
        </div>
        <AddCategoryQuestionModal
            ref="addNewQuestionModal"
            :used-question-keys="usedQuestionKeys"
            :show-alert-message="availableCountQuestions === 1"
            @save="onSaveNewQuestionModal"
        />
        <EditCategoryQuestionModal
            ref="editQuestionModal"
            :used-question-keys="usedQuestionKeys"
            @save="onUpdateQuestion"
        />
        <QuestionLimitReachedModal
            ref="proceedQuestionModelRef"
            :questions="categoryQuestionsModel"
            @proceed="onProceedNewQuestionModal"
        />
    </div>
</template>

<script setup lang="ts">
import {
    CategoryUpsertBulkErrorItem,
    IAiCategoryUpsert,
    IAiCategoryUpsertRequest,
    IAiInsightsUpsert,
    IAiQuestionRequest
} from '@/types/aiManager'
import useAiCategoryEditableData from '@/composables/useAiCategoryEditableData.ts'
import {
    GENERAL_VIEW_TAB,
    PROMPTS_VIEW_TAB,
    QUESTION_RELATION_TYPES,
    QUESTIONS_VIEW_TAB,
    viewSwitcherOptions
} from '@/enums/aiManagement.enum.ts'
import { ref, watch } from 'vue'
import AiCategoryGeneralForm from '@/components/AiSettings/Forms/AiCategoryGeneralForm.vue'
import AiCategoryQuestions from '@/components/AiSettings/Forms/AiCategoryQuestions.vue'
import QuestionLimitReachedModal from '@/components/AiSettings/QuestionLimitReachedModal.vue'
import AddCategoryQuestionModal from '@/components/AiSettings/AddCategoryQuestionModal.vue'
import EditCategoryQuestionModal from '@/components/AiSettings/EditCategoryQuestionModal.vue'
import AiCategoryPromptConfigs from '@/components/AiSettings/Forms/AiCategoryPromptConfigs.vue'
import i18n from '@/plugins/i18n.ts'
import { useConfirmModal } from '@voicenter-team/voicenter-ui-plus'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import get from 'lodash/get'
import useCategoryUpsertErrors from '@/composables/useCategoryUpsertErrors'
import useEntityMappingData from '@/composables/useEntityMappingData.ts'

const {
    removeQuestionsBulk,
    fetchAiCategoryList,
    upsertQuestion,
    upsertCategoryBulk
} = useManageAiCategoriesData()

/* Props */
const props = defineProps<{
    category: IAiCategoryUpsert
}>()
/* Emit */
const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'open:category', pl: number): void
    (e: 'save', pl: IAiCategoryUpsertRequest): void
}>()

/* Refs */
const generalFormRef = ref<typeof AiCategoryGeneralForm>()
const addNewQuestionModal = ref<typeof AddCategoryQuestionModal>()
const editQuestionModal = ref<typeof EditCategoryQuestionModal>()
const proceedQuestionModelRef = ref<typeof QuestionLimitReachedModal>()
const promptConfigRef = ref<typeof AiCategoryPromptConfigs>()

/* Data */
const {
    categoryGeneralModel,
    categoryEngineModel,
    categoryQuestionsModel,
    maxInsightQuestions,
    availableCountQuestions,
    usedQuestionKeys,
    categoryPromptConfigs,
    originalCategoryData,
    addQuestionToGroup,
    resetAllStates,
    setCategoryDataModel,
    updateGroupQuestion,
    getPromptSettings,
    uniqueQuestionId
} = useAiCategoryEditableData(props.category)

const {
    upsertDataErrors,
    resetAllErrors,
    setEntityDataErrors
} = useCategoryUpsertErrors()
const {
    resetEntityMappingData,
    setEntityAccountModel,
    setEntityUserModel,
    entityAccountsModel,
    entityUsersModel,
    getEntityMappingsData
} = useEntityMappingData()

const loading = ref(false)
const loadingQuestions = ref(false)
const viewSwitcherModel = ref(GENERAL_VIEW_TAB)

/* Methods */
function resetData () {
    const initData = { ...props.category }
    resetAllStates(initData)
    viewSwitcherModel.value = GENERAL_VIEW_TAB
    resetEntityMappingData()
    resetAllErrors()
    generalFormRef.value?.resetForm()
}

function onOpenCategoryTab (categoryId: number) {
    emit('open:category', categoryId)
}

function onCancel () {
    emit('cancel')
    resetData()
}

async function formValidate () {
    const isGeneralFormValid = await generalFormRef.value?.validate()
    const isPromptFormValid = await promptConfigRef.value?.validate()
    if (!isGeneralFormValid.isValid) {
        viewSwitcherModel.value = GENERAL_VIEW_TAB
    } else if (!isPromptFormValid.isValid) {
        viewSwitcherModel.value = 'prompts'
    }

    return isGeneralFormValid.isValid && isPromptFormValid.isValid
}

async function onSaveCategory () {
    const isValid = await formValidate()
    if (!isValid) {
        return
    }

    const {
        CategoryName,
        CategoryType,
        AccountID,
        MaxInsightQuestions,
        Description,
        OriginalCatID,
        Status,
        CategoryID
    } = categoryGeneralModel.value

    const EntityMappings = getEntityMappingsData()
    const PromptID = props.category.AiPrompts?.Insights?.PromptID

    const Insights: IAiInsightsUpsert = {
        AiEngineID: categoryEngineModel.value,
        PromptConfig: {
            ...getPromptSettings()
        },
        EntityMappings,
        PromptID,
        // Questions: [ ...categoryQuestionsModel.value ].filter(i => i.QuestionRelationType !== QUESTION_RELATION_TYPES.original)
    }

    const groupRequestData: IAiCategoryUpsertRequest = {
        CategoryID,
        CategoryName,
        AccountID,
        Status,
        MaxInsightQuestions,
        Description,
        OriginalCatID,
        CategoryType,
        AiPrompts: {
            Insights
        }
    }

    await updateAiCategory({
        ...groupRequestData
    })
}

async function updateAiCategory (category: IAiCategoryUpsertRequest) {
    try {
        loading.value = true
        resetAllErrors()
        await upsertCategoryBulk([ category ])
        await fetchAiCategoryList()
    } catch (e) {
        const status = get(e, 'axiosResponse.response.status')
        if (status === 409) {
            viewSwitcherModel.value = GENERAL_VIEW_TAB
            const response = (get(e, 'axiosResponse.response.data.Data') || []) as Array<CategoryUpsertBulkErrorItem>
            setDataErrors(response)
        }
    } finally {
        loading.value = false
    }
}

function setDataErrors (response: Array<CategoryUpsertBulkErrorItem>) {
    setEntityDataErrors(response)
}

function onRemoveQuestion (question: IAiQuestionRequest) {
    if (question.QuestionRelationType === QUESTION_RELATION_TYPES.original) {
        return
    }
    const questionId = question.QuestionID
    if (questionId) {
        useConfirmModal({
            type: 'delete',
            header: i18n.t('callHistoryView.confirmation.delete'),
            message: i18n.t('callHistoryView.confirmation.entityDeleteMessage', { entity: question.Key })
        }).then(res => {
            if (res) {
                removeQuestion(questionId)
            }
        })
    } else {
        categoryQuestionsModel.value = categoryQuestionsModel.value.filter(i => !(i.QuestionRelationType === question.QuestionRelationType && i.Key === question.Key))
    }

}

async function removeQuestion (questionId: number) {
    try {
        loadingQuestions.value = true
        await removeQuestionsBulk([ questionId ])
        categoryQuestionsModel.value = categoryQuestionsModel.value.filter(i => i.QuestionID !== questionId)
        await fetchAiCategoryList()
    } finally {
        loadingQuestions.value = false
    }
}

async function onUpdateQuestion (question: IAiQuestionRequest) {
    try {
        if (question.QuestionRelationType !== QUESTION_RELATION_TYPES.original) {
            question.PromptID = props.category.AiPrompts?.Insights.PromptID
            const responseID = await upsertQuestion(question)
            if (!question.QuestionID) {
                question.QuestionID = responseID
            }
            await fetchAiCategoryList()
        }
        updateGroupQuestion(question)
        editQuestionModal.value?.close()
    } catch (e) {
        editQuestionModal.value?.close()
    }
}

function onAddNewQuestion () {
    const isReachedLimit = availableCountQuestions.value < 1
    if (isReachedLimit) {
        proceedQuestionModelRef.value?.open()
    } else {
        addNewQuestionModal.value?.open()
    }
}

function onEditQuestion (question: IAiQuestionRequest) {
    const questionID = question.QuestionRelationType === QUESTION_RELATION_TYPES.original ? undefined : question.QuestionID
    const uid = question.QuestionRelationType === QUESTION_RELATION_TYPES.original ? uniqueQuestionId() : question.uid
    editQuestionModal.value?.open({
        ...question,
        uid,
        QuestionID: questionID,
        QuestionRelationType: question.QuestionRelationType !== QUESTION_RELATION_TYPES.custom ? QUESTION_RELATION_TYPES.replacement : QUESTION_RELATION_TYPES.custom
    })
}

async function onSaveNewQuestionModal (question: IAiQuestionRequest) {
    // TODO: should add new question request with groupId
    question.PromptID = props.category.AiPrompts?.Insights.PromptID
    question.QuestionID = await upsertQuestion(question)
    categoryQuestionsModel.value.push(question)
    addQuestionToGroup(question)
    addNewQuestionModal.value?.close()
    await fetchAiCategoryList()
}

function onProceedNewQuestionModal (question: IAiQuestionRequest) {
    proceedQuestionModelRef.value?.close()
    onEditQuestion(question)
}

function setCategoryModel (data: IAiCategoryUpsert) {
    resetData()
    viewSwitcherModel.value = GENERAL_VIEW_TAB
    setCategoryDataModel(data, data.CategoryType)
    generalFormRef.value?.resetForm()
}

watch(
    () => props.category,
    (val) => {
        setCategoryModel(val)
        setEntityAccountModel(val.AssignAccounts || [])
        setEntityUserModel(val.AssignUsers || [])
    },
    { immediate: true }
)


defineExpose({
    setData: setCategoryModel,
    cancel: onCancel
})
</script>

