<template>
    <transition
        name="collapse-transition"
        v-on="hooks"
    >
        <slot />
    </transition>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { RendererElement } from 'vue'

/* Computed */
const hooks = computed(() => {
    return {
        beforeEnter,
        enter,
        afterEnter,
        beforeLeave,
        leave,
        afterLeave
    }
})

/* Methods */
function beforeEnter (el: RendererElement): void {
    if (!el.dataset) el.dataset = {}
    el.dataset.oldPaddingTop = el.style.paddingTop
    el.dataset.oldPaddingBottom = el.style.paddingBottom
    el.style.maxHeight = 0
    el.style.paddingTop = 0
    el.style.paddingBottom = 0
}

function enter (el: RendererElement): void {
    el.dataset.oldOverflow = el.style.overflow
    if (el.scrollHeight !== 0) {
        el.style.maxHeight = `${el.scrollHeight}px`
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
    } else {
        el.style.maxHeight = 0
        el.style.paddingTop = el.dataset.oldPaddingTop
        el.style.paddingBottom = el.dataset.oldPaddingBottom
    }
    el.style.overflow = 'hidden'
}

function afterEnter (el: RendererElement): void {
    el.style.maxHeight = ''
    el.style.overflow = el.dataset.oldOverflow
}
function beforeLeave (el: RendererElement): void {
    if (!el.dataset) el.dataset = {}
    el.dataset.oldPaddingTop = el.style.paddingTop
    el.dataset.oldPaddingBottom = el.style.paddingBottom
    el.dataset.oldOverflow = el.style.overflow
    el.style.maxHeight = `${el.scrollHeight}px`
    el.style.overflow = 'hidden'
}
function leave (el: RendererElement): void {
    if (el.scrollHeight !== 0) {
        el.style.maxHeight = 0
        el.style.paddingTop = 0
        el.style.paddingBottom = 0
    }
}
function afterLeave (el: RendererElement): void {
    el.style.maxHeight = ''
    el.style.overflow = el.dataset.oldOverflow
    el.style.paddingTop = el.dataset.oldPaddingTop
    el.style.paddingBottom = el.dataset.oldPaddingBottom
}
</script>
