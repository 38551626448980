// i18n.ts
import { App } from 'vue'
import { flatten } from '@/helpers/object.helper'
import en from '@/locales/en.json'
import he from '@/locales/he.json'
import mustache from 'mustache'

type TranslationData = Record<string, string | number | undefined>
type Messages = Record<string, Record<string, string>>


const messages: Messages = {
    en: flatten(en),
    he: flatten(he),
}

const FALLBACK_LOCALE = import.meta.env.VITE_APP_FALLBACK_LOCALE ?? 'en'

class CustomI18n {
    private locale: string
    private messages: Record<string, Record<string, string>>

    constructor () {
        this.locale = FALLBACK_LOCALE
        this.messages = messages
    }

    t (key: string, data?: TranslationData): string {
        const translations = this.messages[this.locale] || this.messages[FALLBACK_LOCALE]

        const translation = translations[key]

        if (!translation) {
            return key
        }

        if (!data) {
            return translation
        }

        mustache.tags = [ '{', '}' ]

        return mustache.render(translation, data)

    }

    private setLocale (lang: string): void {
        if (this.messages[lang]) {
            this.locale = lang
        } else {
            console.warn(`Locale ${lang} not available, falling back to ${FALLBACK_LOCALE}`)
            this.locale = FALLBACK_LOCALE
        }
    }

    getTranslations (lang: string) {
        return this.messages[lang]
    }

    setTranslations (locale = 'en', data: Record<string, string>) {
        this.setLocale(locale)
        this.messages[locale] = data
    }

    install (app: App) {
        app.config.globalProperties.$t = this.t.bind(this)
    }
}

const i18n = new CustomI18n()

export default i18n
