import { computed, reactive, ref, toRefs } from 'vue'
import {
    IAiCategoryListItem,
    IAiCategoryUpsertRequest,
    IAiPromptConfig,
    IAiPromptConfigUpsert,
    IAiQuestionRequest,
    ICallAiConfigData
} from '@/types/aiManager'
import ApiAiManager from '@/services/API/ApiAiManager.ts'
import i18n from '@/plugins/i18n.ts'
import { ACCOUNT_ENTITY_TYPE, isSystemCategory, USER_ENTITY_TYPE } from '@/enums/aiManagement.enum.ts'


const AiConfigsState = reactive<ICallAiConfigData>({
    AiEngines: [],
    AiAnswerTypes: [],
    AiCategoryStatus: [],
    AiQuestionRelationTypes: []
})

const AiCategoriesState = reactive<{ AiCategories: Array<IAiCategoryListItem> }>({
    AiCategories: []
})

const isAiConfigsLoading = ref(false)
const isAiCategoriesLoading = ref(true)

function mapAiCategoriesResponse (data: Array<IAiCategoryListItem>) {
    return data.map(item => ({
        ...item,
        AccountName: item.AccountID === 0 ? i18n.t('callHistoryView.manageAi.defaultAccount') : item.AccountName,
        QuestionsLength: item.AiPrompts?.Insights?.Questions?.length ?? 0,
        AssignUsers: item.AiPrompts?.Insights?.EntityMappings?.filter(i => i.AiEntityType === USER_ENTITY_TYPE) || [],
        AssignAccounts: item.AiPrompts?.Insights?.EntityMappings?.filter(i => i.AiEntityType === ACCOUNT_ENTITY_TYPE) || [],
        OriginalCategoryName: item.OriginalCategoryName || '---'
    }))
}

function getCategoryById (categoryId?: number) {
    if (categoryId === undefined) {
        return undefined
    }
    return AiCategoriesState.AiCategories.find(i => i.CategoryID === categoryId)
}

export function isCustomPromptSettings (prompt: Partial<IAiPromptConfig> | IAiPromptConfigUpsert, originalCategoryId?: number) {
    const fieldsToCompare: Array<keyof IAiPromptConfig> = [ 'Body', 'Footer', 'System' ]
    const originalCategorySettings = getCategoryById(originalCategoryId)?.AiPrompts?.Insights?.PromptConfig
    if (!originalCategorySettings) {
        return true
    }
    return fieldsToCompare.some((field) => prompt[field] !== originalCategorySettings[field])
}

export function isEmptyPrompt (currentPromptConfig?: IAiPromptConfigUpsert) {
    if (!currentPromptConfig) {
        return true
    }
    const fieldsToCompare: Array<keyof IAiPromptConfig> = [ 'Body', 'Footer', 'System' ]
    return fieldsToCompare.every(i => !!currentPromptConfig[i])
}

export default function useManageAiCategoriesData () {

    const {
        AiEngines,
        AiAnswerTypes,
        AiCategoryStatus,
        AiQuestionRelationTypes
    } = toRefs(AiConfigsState)
    const { AiCategories } = toRefs(AiCategoriesState)

    const fetchAiConfigs = async () => {
        try {
            isAiConfigsLoading.value = true
            const result = await ApiAiManager.getAIConfData()

            AiConfigsState.AiCategoryStatus = result.AiCategoryStatus || []
            AiConfigsState.AiAnswerTypes = result.AiAnswerTypes || []
            AiConfigsState.AiCategoryStatus = result.AiCategoryStatus || []
            AiConfigsState.AiEngines = result.AiEngines || []
            AiConfigsState.AiQuestionRelationTypes = result.AiQuestionRelationTypes || []
        } finally {
            isAiConfigsLoading.value = false
        }
    }

    const AiOriginalCategories = computed(() => {
        return AiCategories.value.filter(i => isSystemCategory(i))
    })

    const fetchAiCategoryList = async () => {
        try {
            isAiCategoriesLoading.value = true
            const result = await ApiAiManager.getCategoryList()

            AiCategoriesState.AiCategories = mapAiCategoriesResponse(result || [])
        } finally {
            isAiCategoriesLoading.value = false
        }
    }

    const changeCategoryStatus = async (category: IAiCategoryListItem, newStatus: number) => {
        const {
            CategoryID,
            OriginalCatID,
        } = category
        const formData = {
            Status: newStatus,
            CategoryID,
            OriginalCatID
        }

        return await ApiAiManager.categoryUpdateStatus(formData)
    }

    const upsertCategoryBulk = async (categories: Array<IAiCategoryUpsertRequest>) => {
        return await ApiAiManager.upsertCategoryBulk(categories)
    }

    const upsertQuestion = async (question: IAiQuestionRequest) => {
        return await ApiAiManager.updateQuestionsBulk([ question ])
    }

    const removeQuestionsBulk = async (questionIDs: Array<number>) => {
        return await ApiAiManager.removeQuestionsBulk(questionIDs)
    }


    return {
        AiEngines,
        AiAnswerTypes,
        AiCategoryStatus,
        isAiConfigsLoading,
        isAiCategoriesLoading,
        AiQuestionRelationTypes,
        AiCategories,
        AiOriginalCategories,
        fetchAiConfigs,
        fetchAiCategoryList,
        upsertCategoryBulk,
        upsertQuestion,
        removeQuestionsBulk,
        changeCategoryStatus,
        getCategoryById
    }
}
