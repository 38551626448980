import i18n from '@/plugins/i18n.ts'
import { CdrData } from '@/types/api'
import { getCallerName, getTargetName } from '@/helpers/cdrData.helper.ts'

export default function (cdrData?: CdrData, technicalData: Array<{title: string, content: string | number}> = []) {
    return [
        {
            blockTitle: i18n.t('callHistoryView.general.targetHeader'),
            key: 'Target',
            infoBlocks: [
                {
                    title: i18n.t('callHistoryView.general.target.number'),
                    content: cdrData ? getTargetName(cdrData) : '---'
                },
                {
                    title: i18n.t('callHistoryView.general.target.country'),
                    content: cdrData?.countryName
                },
                {
                    title: i18n.t('callHistoryView.general.target.extension'),
                    content: cdrData?.oUT_extension_name
                },
                {
                    title: i18n.t('callHistoryView.general.target.extensionName'),
                    content: cdrData?.oUT_extension_user
                }
            ]
        },
        {
            blockTitle: i18n.t('callHistoryView.general.callerHeader'),
            key: 'Caller',
            infoBlocks: [
                {
                    title: i18n.t('callHistoryView.general.caller.number'),
                    content: cdrData ? getCallerName(cdrData) : '---'
                },
                {
                    title: i18n.t('callHistoryView.general.caller.country'),
                    content: cdrData?.countryName
                },
                {
                    title: i18n.t('callHistoryView.general.caller.extension'),
                    content: cdrData?.iNC_extension_name
                },
                {
                    title: i18n.t('callHistoryView.general.caller.extensionName'),
                    content: cdrData?.iNC_extension_user
                },
                {
                    title: i18n.t('callHistoryView.general.caller.representative'),
                    content: cdrData?.representativeName
                },
                {
                    title: i18n.t('callHistoryView.general.caller.representativeCode'),
                    content: cdrData?.representativePIN
                }
            ]
        },
        {
            blockTitle: i18n.t('callHistoryView.general.queueHeader'),
            key: 'Queue',
            infoBlocks: [
                {
                    title: i18n.t('callHistoryView.general.queue.id'),
                    content: cdrData?.queueID
                },
                {
                    title: i18n.t('callHistoryView.general.queue.name'),
                    content: cdrData?.queueName
                }
            ]
        },
        {
            blockTitle: i18n.t('callHistoryView.general.technicalData'),
            key: 'Technical',
            infoBlocks: [ ...technicalData ]
        }
    ]
}
