<template>
    <div class="w-full">
        <VcTable
            :loading="isAiCategoriesLoading"
            :columns="tableAiCategoriesColumns"
            :options="AiCategories"
            :empty-data-text="i18n.t('callHistoryView.noData')"
            :use-teleported-popovers="isTeleported"
            with-pagination
            compact
            stripe
        >
            <template #header-actions>
                <div class="flex justify-end items-center">
                    <VcButton
                        icon="vc-lc-plus"
                        type="borderless"
                        color="primary"
                        @click="onAddCategory"
                    >
                        {{ i18n.t('callHistoryView.manageAi.addCategoryAction') }}
                    </VcButton>
                </div>
            </template>
            <template #questions-row="{row}">
                <div class="flex-center h-auto">
                    <span
                        class="text-primary bg-primary-actions-bg--focus rounded-full inline-flex items-center justify-center text-sm py-1 px-1.5 font-medium leading-none"
                    >
                        {{
                            row.QuestionsLength
                        }}
                    </span>
                </div>
            </template>
            <template #assignAcc-row="{row}">
                <div class="flex-center h-auto">
                    <CategoryAssignsPopup
                        :assigns="row.AssignAccounts || []"
                    />
                </div>
            </template>
            <template #assignUsers-row="{row}">
                <div class="flex-center h-auto">
                    <CategoryAssignsPopup
                        :assigns="row.AssignUsers || []"
                    />
                </div>
            </template>
            <template #status-row="{row}">
                <VcButton
                    :loading="categoryRowLoading === row.CategoryID"
                    :disabled="!isCategoryEditable(row)"
                    :color="statusButtonColors[row.Status]"
                    size="small"
                    type="outline"
                    @click="onChangeCategoryStatus(row)"
                >
                    {{ row.StatusName }}
                </VcButton>
            </template>
            <template #actions-row="{ row }">
                <div class="flex-center gap-2">
                    <VcButtonIcon
                        :disabled="!isCategoryEditable(row)"
                        color="secondary"
                        icon="vc-lc-pencil"
                        small
                        @click="onEditCategory(row)"
                    />
                    <VcButtonIcon
                        color="secondary"
                        icon="vc-lc-copy-plus"
                        small
                        @click="onCopyCategory(row)"
                    />
                    <VcPopover
                        :teleported="isTeleported"
                        :disabled="!isCategoryEditable(row) || canDelete(row)"
                        trigger="hover"
                    >
                        <template #reference>
                            <VcButtonIcon
                                loading
                                :disabled="!isCategoryEditable(row) || !canDelete(row)"
                                color="secondary"
                                icon="vc-lc-trash-2"
                                small
                                @click="onRemoveCategory(row)"
                            />
                        </template>
                        <div class="p-4 text-sm text-gray-600">
                            {{ i18n.t('callHistoryView.manageAi.deleteCategoryWarning') }}
                        </div>
                    </VcPopover>
                </div>
            </template>
        </VcTable>
    </div>
</template>

<script setup lang="ts">
import {
    CATEGORY_TYPE_SYSTEM,
    DELETED_GROUP_STATUS,
    DISABLED_GROUP_STATUS,
    ENABLED_GROUP_STATUS
} from '@/enums/aiManagement.enum'
import useManageAiCategoriesData from '@/composables/useManageAiCategories'
import { useConfirmModal } from '@voicenter-team/voicenter-ui-plus'
import CategoryAssignsPopup from '@/components/AiSettings/CategoryAssignsPopup.vue'
import { IAiCategoryListItem } from '@/types/aiManager'
import { ref } from 'vue'
import i18n from '@/plugins/i18n.ts'
import tableAiCategoriesColumns from '@/enums/tables/AiCategories.table.enum.ts'
import { isTeleported } from '@/services/GlobalState.ts'


const emit = defineEmits<{
    (e: 'category:add'): void
    (e: 'category:edit', pl: IAiCategoryListItem): void
    (e: 'category:copy', pl: IAiCategoryListItem): void
}>()

const statusButtonColors: Record<number, 'primary' | 'destructive'> = {
    [ENABLED_GROUP_STATUS]: 'primary',
    [DISABLED_GROUP_STATUS]: 'destructive',
    [DELETED_GROUP_STATUS]: 'destructive'
}

const { isAiCategoriesLoading, AiCategories, changeCategoryStatus, fetchAiCategoryList } = useManageAiCategoriesData()

const categoryRowLoading = ref<number | undefined>(undefined)

const isCategoryEditable = (category: IAiCategoryListItem) => {
    return category.CategoryType !== CATEGORY_TYPE_SYSTEM
}
const hasAssignedEntities = (category: IAiCategoryListItem) => {
    return category.AssignAccounts?.length || category.AssignUsers?.length
}
const canDelete = (category: IAiCategoryListItem) => {
    return !hasAssignedEntities(category)
}

function onAddCategory () {
    emit('category:add')
}

function onEditCategory (category: IAiCategoryListItem) {
    emit('category:edit', category)
}

async function onChangeCategoryStatus (category: IAiCategoryListItem) {
    try {
        categoryRowLoading.value = category.CategoryID
        const confirm = await useConfirmModal({
            type: 'confirm',
            header: i18n.t('callHistoryView.confirmation.changeStatus'),
            message: i18n.t('callHistoryView.confirmation.changeStatusText')
        })
        if (confirm) {
            const oppositeStatus = category.Status === ENABLED_GROUP_STATUS ? DISABLED_GROUP_STATUS : ENABLED_GROUP_STATUS
            await changeCategoryStatus(category, oppositeStatus)
            await fetchAiCategoryList()
        }
    } finally {
        categoryRowLoading.value = undefined
    }
}

function onCopyCategory (category: IAiCategoryListItem) {
    emit('category:copy', category)
}

async function onRemoveCategory (category: IAiCategoryListItem) {
    try {
        const confirm = await useConfirmModal({
            type: 'delete',
            header: i18n.t('callHistoryView.confirmation.delete'),
            message: i18n.t('callHistoryView.confirmation.entityDeleteMessage', { entity: category.CategoryName })
        })
        if (confirm) {
            await changeCategoryStatus(category, DELETED_GROUP_STATUS)
            await fetchAiCategoryList()
        }
    } finally {
        // categoryRowLoading.value = undefined
    }
}
</script>
