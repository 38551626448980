import { computed, ref } from 'vue'
import { JWTPayloadData } from '@/types/api'

export const jwtPayloadData = ref<JWTPayloadData | undefined>(undefined)

export const isUserAdmin = computed(() => {
    return jwtPayloadData.value?.IsAdmin
})

export function setJWTPayloadData (jwtPayload?: JWTPayloadData) {
    jwtPayloadData.value = jwtPayload
}

export function getAccountID () {
    return jwtPayloadData.value?.AccountID || undefined
}

export function getUserID () {
    return jwtPayloadData.value?.UserID || undefined
}

export function getTokenFromResponseData () {
    return jwtPayloadData.value?.jwt || ''
}

//PermissionType === 3 && SectionType == 2
export function getSectionPermissions (permissionType: number, sectionType: number) {
    return jwtPayloadData.value?.UserPermissionsList?.find(item => item.PermissionType === permissionType && item.SectionType === sectionType)
}
