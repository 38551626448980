import useEnvComposable from '@/composables/useEnvComposable.ts'
import { ApiService } from '@/services/ApiService.ts'
import { getUrlQueryParams, isInjected, queryToken } from '@/composables/useQueryData.ts'
import { isWidgetState } from '@/services/GlobalState.ts'
import { RawAxiosRequestHeaders } from 'axios'

const isDev = import.meta.env.MODE === 'development'

export class BaseApiManager {
    private API_MANAGER_BASE_URL = useEnvComposable('VITE_APP_API_MANAGER_BASE_URL')
    private USE_API_MANAGER = useEnvComposable('VITE_APP_USE_API_MANAGER')
    private LOCAL_BASE_URL = '/ai/data'
    public PDF_URL = '/Pdf/Generate'
    private readonly API_MANAGER_SERVICE
    private readonly LOCAL_SERVICE

    constructor () {
        const _url = this.API_MANAGER_BASE_URL
        this.API_MANAGER_SERVICE = new ApiService(_url)
        this.LOCAL_SERVICE = new ApiService(this.LOCAL_BASE_URL)
    }

    get service () {
        //TODO: temporary solution (isDev), need to remove it
        if (this.USE_API_MANAGER === 'true' || isDev || (isInjected.value && queryToken.value) || isWidgetState.value) {
            return this.API_MANAGER_SERVICE
        } else {
            return this.LOCAL_SERVICE
        }
    }

    get pdfService () {
        if (this.API_MANAGER_BASE_URL) {
            return this.API_MANAGER_SERVICE
        } else {
            return this.LOCAL_SERVICE
        }
    }

    getDefaultHeaders () {
        const headers: RawAxiosRequestHeaders = {}

        const { queryToken } = getUrlQueryParams()

        if (queryToken) {
            headers['Authorization'] = `Bearer ${queryToken}`
        }

        return headers
    }
}

export default BaseApiManager
