import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import i18n from '@/plugins/i18n.ts'
import { computed, ComputedRef } from 'vue'

const sipFlowTableColumns: ComputedRef<ColumnType> = computed(() => {
    return [
        {
            columnItemKey: 'Timestamp-column',
            rowItemKeyKey: 'Timestamp-row',
            rowItemLabelKey: 'label',
            columnItemLabel: i18n.t('callHistoryView.sip.table.timestamp'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'SourceIP-column',
            rowItemKeyKey: 'SourceIP-row',
            rowItemLabelKey: 'objectData._source.srcip',
            columnItemLabel: i18n.t('callHistoryView.sip.table.sourceIP'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'SourcePort-column',
            rowItemKeyKey: 'SourcePort-row',
            rowItemLabelKey: 'objectData._source.src_port',
            columnItemLabel: i18n.t('callHistoryView.sip.table.sourcePort'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        }, {
            columnItemKey: 'DestIP-column',
            rowItemKeyKey: 'DestIP-row',
            rowItemLabelKey: 'objectData._source.dstip',
            columnItemLabel: i18n.t('callHistoryView.sip.table.destIP'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'DestPort-column',
            rowItemKeyKey: 'DestPort-row',
            rowItemLabelKey: 'objectData._source.dst_port',
            columnItemLabel: i18n.t('callHistoryView.sip.table.destPort'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'Method-column',
            rowItemKeyKey: 'Method-row',
            rowItemLabelKey: 'eventType',
            columnItemLabel: i18n.t('callHistoryView.sip.table.method'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'Resp-column',
            rowItemKeyKey: 'Resp-row',
            rowItemLabelKey: 'message',
            columnItemLabel: i18n.t('callHistoryView.sip.table.resp'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'CallID-column',
            rowItemKeyKey: 'CallID-row',
            rowItemLabelKey: 'objectData._source.callid',
            columnItemLabel: i18n.t('callHistoryView.sip.table.callID'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'FullView-column',
            rowItemKeyKey: 'FullView-row',
            rowItemLabelKey: 'FullView',
            columnItemLabel: i18n.t('callHistoryView.sip.table.fullView'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        }
    ]
})

export default sipFlowTableColumns
