import { RawAxiosRequestHeaders } from 'axios'
import { FormsListResponse, ICallHistoryResponse, IRelatedCallsResponse } from '@/types/api'
import { getUrlQueryParams } from '@/composables/useQueryData.ts'
import { ObjectAnyType } from '@/types/types'
import { ISipFlowGetDataResponse } from '@/types/sip'
import { IRtcpDataResponse, IRtcpTimeLineResponse } from '@/types/rtcp'
import { getIntervalByCallDuration } from '@/helpers/sipFlow.helper.ts'
import useEnvComposable from '@/composables/useEnvComposable.ts'
import { testData } from '@/enums/testData/testVoiceData.ts'
import { testData as testAiOnlyData } from '@/enums/testData/testAiOnlyData.ts'
import BaseApiManager from '@/services/API/BaseApiManager.ts'

class ApiManagerService extends BaseApiManager {

    constructor () {
        super()
    }

    public async CallHistory (callId: string) {

        // TODO: temporary solution, need to remove it
        if (import.meta.env.MODE === 'development' && useEnvComposable('VITE_APP_TEST_LOCAL_DATA')) {
            return useEnvComposable('VITE_APP_LAYOUT_NAME') === 'aiOnly' ? testAiOnlyData : testData
        }

        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const { Data } = await this.service.get(`Call/History/${callId}`, headers) as ICallHistoryResponse

        return Data
    }

    public async CallHistoryPdf () {
        //TODO: temporary solution, need to remove it
        // if (import.meta.env.MODE === 'development') {
        //     return testData
        // }
        const { queryID } = getUrlQueryParams()
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        const { Data } = await this.pdfService.get(`Call/History/${queryID}`, headers) as ICallHistoryResponse

        return Data
    }

    public async FormsList () {
        const headers = this.getDefaultHeaders()

        const data = {
            AccountID: [],
            FormStatus: [ 1 ]
        }
        const { FormsList = [] } = await this.service.post('Forms/List/', data, headers) as FormsListResponse

        return FormsList
    }

    public async InsertCallForm (FormData: ObjectAnyType) {
        const { queryID } = getUrlQueryParams()
        const headers = this.getDefaultHeaders()
        const IVR_unique_id = queryID
        return await this.service.post('Forms/InsertCallForm/', {
            FormData,
            IVR_unique_id,
            IsKeepOldData: '0'
        }, headers)
    }

    public async RelatedCalls () {
        const { queryID } = getUrlQueryParams()
        const headers = this.getDefaultHeaders()
        const { Data } = await this.service.get(`Call/RelatedCalls/${queryID}`, headers) as IRelatedCallsResponse

        return Data?.related_calls_list || []
    }

    public generatePdf (options: ObjectAnyType) {
        const headers: RawAxiosRequestHeaders = this.getDefaultHeaders()

        return this.getFileResponse(this.PDF_URL, options, headers)
    }

    private getFileResponse (url: string, data: ObjectAnyType, headers?: RawAxiosRequestHeaders): Promise<Blob> {
        const options = {
            method: 'post',
            responseType: 'blob',
            url,
            data,
            headers
        } as ObjectAnyType

        return new Promise((resolve, reject) => {
            return this.service.axios(options)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    public async getSipFlowData () {
        const { queryID } = getUrlQueryParams()
        const headers = this.getDefaultHeaders()

        const {
            Data = {
                mainData: [],
                aliases: []
            }
        } = await this.service.get(`Call/SipFlowData/${queryID}`, headers) as ISipFlowGetDataResponse
        return Data
    }

    public async getRTCPData () {
        const { queryID } = getUrlQueryParams()
        const headers = this.getDefaultHeaders()
        const body = {
            ivrUniqueID: queryID,
            payloadTypes: [
                200, 201
            ]
        }

        const { Data = [] } = await this.service.post('/Call/GetRTCPData', body, headers) as IRtcpDataResponse

        return Data
    }

    public async getSingleCallTimeline () {
        const { queryID } = getUrlQueryParams()
        const interval = getIntervalByCallDuration()
        const headers = this.getDefaultHeaders()

        const body = {
            ivrUniqueID: queryID,
            aggs: {
                Mos: { avg: { field: 'mos_payload.instant.mos' } },
                Jitter: { avg: { field: 'mos_payload.instant.jitter' } },
                PacketLoss: { avg: { field: 'mos_payload.instant.pkt_loss' } }
            },
            date_histogram: {
                field: '@timestamp',
                interval,
                min_doc_count: 1
            }
        }

        const { Data = {} } = await this.service.post('/Call/RTCPTimeline', body, headers) as IRtcpTimeLineResponse

        return Data.buckets || []
    }
}

export default new ApiManagerService()
