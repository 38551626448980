import i18n from '@/plugins/i18n.ts'
import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import { computed, ComputedRef } from 'vue'


const callFormsTableColumns: ComputedRef<ColumnType> = computed(() => {
    return [
        {
            columnItemKey: 'name-column',
            rowItemKeyKey: 'name-row',
            rowItemLabelKey: 'FormName',
            columnItemLabel: i18n.t('callHistoryView.forms.table.name'),
            sortBy: 'FormName',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'system-column',
            rowItemKeyKey: 'system-row',
            rowItemLabelKey: 'FormSystemName',
            columnItemLabel: i18n.t('callHistoryView.forms.table.system'),
            sortBy: 'FormSystemName',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'questions-column',
            rowItemKeyKey: 'questions-row',
            rowItemLabelKey: 'Questions',
            columnItemLabel: i18n.t('callHistoryView.forms.table.questions'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'answers-column',
            rowItemKeyKey: 'answers-row',
            rowItemLabelKey: 'Answers',
            columnItemLabel: i18n.t('callHistoryView.forms.table.answers'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'preview-column',
            rowItemKeyKey: 'preview-row',
            rowItemLabelKey: '',
            columnItemLabel: i18n.t('callHistoryView.actions'),
            columnAlignment: 'left',
            rowItemAlignment: 'center',
            width: '120px'
        }
    ]
})

export default callFormsTableColumns
