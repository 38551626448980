<template>
    <div class="w-full call-keyword-list">
        <div
            v-if="viewPartState === VIEW_ALL"
            class="w-full"
        >
            <div class="keywords-block">
                <template
                    v-for="(group, index) in callerAllWordsMapped"
                    :key="`callGrAll-${index}`"
                >
                    <span
                        v-for="(word, i) in group.words"
                        :key="`word-${index}-${i}`"
                        :class="`gr-${5 - index}`"
                    >
                        {{ word }}
                    </span>
                </template>
            </div>
        </div>
        <div
            v-if="viewPartState === VIEW_PART"
            class="grid grid-cols-1 sm:grid-cols-2 gap-4 xl:gap-6"
        >
            <div>
                <div class="uppercase text-placeholders text-xs font-semibold mb-2">
                    {{ `${clientName || i18n.t('callHistoryView.noName')}:` }}
                </div>
                <div class="keywords-block">
                    <template
                        v-for="(group, index) in caller1WordsMapped"
                        :key="`callGr0-${index}`"
                    >
                        <span
                            v-for="(word, i) in group.words"
                            :key="`word-${index}-${i}`"
                            :class="`gr-${5 - index}`"
                        >
                            {{ word }}
                        </span>
                    </template>
                </div>
            </div>
            <div>
                <div class="uppercase text-placeholders text-xs font-semibold mb-2">
                    {{ `${agentName || i18n.t('callHistoryView.noName')}:` }}
                </div>
                <div class="keywords-block">
                    <template
                        v-for="(group, index) in caller2WordsMapped"
                        :key="`callGr1-${index}`"
                    >
                        <span
                            v-for="(word, i) in group.words"
                            :key="`word-${index}-${i}`"
                            :class="`gr-${5 - index}`"
                        >
                            {{ word }}
                        </span>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TKeyWordsSpeakerMapped } from '@/types/types'
import { computed } from 'vue'
import { getSortedGroupedList } from '@/composables/useAiTabData.ts'
import i18n from '@/plugins/i18n.ts'
import { agentName, clientName } from '@/composables/useAIData.ts'

const VIEW_PART = 'part'
const VIEW_ALL = 'all'


interface IProps {
    open?: boolean
    viewPartState?: string
    caller1Words?: Array<TKeyWordsSpeakerMapped>
    caller2Words?: Array<TKeyWordsSpeakerMapped>
}

const props = withDefaults(
    defineProps<IProps>(),
    {
        viewPartState: 'part',
        caller1Words: () => [],
        caller2Words: () => []
    }
)

const caller1WordsMapped = computed(() => {
    return getSortedGroupedList(props.caller1Words || [])
})
const caller2WordsMapped = computed(() => {
    return getSortedGroupedList(props.caller2Words || [])
})
const callerAllWordsMapped = computed(() => {
    return getSortedGroupedList([ ...props.caller1Words, ...props.caller2Words ])
})

</script>

<style lang="scss">
    .call-keyword-list {
        .keywords-block {
            @apply flex gap-x-4 gap-y-2 flex-wrap items-baseline text-xs font-medium;
            color: var(--keywords-base-color);

            .gr-5 {
                @apply text-xl md:text-3xl font-semibold gap-x-10;
                color: var(--keywords-color-5);
            }
            .gr-4 {
                @apply text-lg md:text-2xl font-semibold gap-x-8;
                color: var(--keywords-color-4);
            }
            .gr-3 {
                @apply text-base md:text-lg font-medium;
                color: var(--keywords-color-3);
            }
            .gr-2 {
                @apply text-sm md:text-base font-semibold;
                color: var(--keywords-color-2);
            }
            .gr-1 {
                @apply text-xs font-medium;
                color: var(--keywords-color-1);
            }
        }
    }
</style>
