<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="visible"
        :header="i18n.t('callHistoryView.manageAi.categoriesSettingsModalHeader')"

        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        width="70vw"
        content-class=""
        maximizable
        @close="onCloseModal"
    >
        <div
            v-if="isVisibleContent"
            class="w-full min-h-[260px]"
        >
            <EditableTabs
                ref="editableTabsRef"
            >
                <template #tab-list>
                    <PromptCategoriesTable
                        @category:copy="onCopyCategory"
                        @category:edit="onOpenEditCategoryTab"
                        @category:add="onOpenCreateCategoryTab"
                    />
                </template>
                <template #tab-create>
                    <CreateAiCategoryTab
                        ref="createCategoryTabRef"
                        @open:category="onOpenCategoryTab"
                        @save="onSaveCreateCategoryTab"
                        @cancel="onCreateCategoryTabCancel"
                    />
                </template>
                <template #tab-edit="{ tabData }">
                    <EditAiCategoryTab
                        ref="editCategoryTabRef"
                        :category="tabData.entityData as IAiCategoryUpsert"
                        @open:category="onOpenCategoryTab"
                        @cancel="onEditCategoryTabCancel"
                    />
                </template>
            </EditableTabs>
        </div>
        <div
            v-else
            class="w-full animate-pulse duration-200 p-3 lg:p-4 rounded bg-secondary-actions-bg--focus"
        >
            <div class="text-center">
                {{ `${i18n.t('callHistoryView.loading')}...` }}
            </div>
        </div>
    </VcModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { isWidgetState, usedWidgetShadowRootEl } from '@/services/GlobalState.ts'
import PromptCategoriesTable from '@/components/AiSettings/PromptCategoriesTable.vue'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import EditableTabs from '@/components/Common/EditableTabs.vue'
import { IAiCategoryListItem, IAiCategoryUpsert } from '@/types/aiManager'
import cloneDeep from 'lodash/cloneDeep'
import CreateAiCategoryTab from '@/components/AiSettings/CreateAiCategoryTab.vue'
import i18n from '@/plugins/i18n.ts'
import { isSystemCategory } from '@/enums/aiManagement.enum.ts'
import { getAccountID } from '@/composables/useJWTPayloadData.ts'
import EditAiCategoryTab from '@/components/AiSettings/EditAiCategoryTab.vue'

const { fetchAiCategoryList, AiCategories } = useManageAiCategoriesData()

const visible = ref(false)
const isVisibleContent = ref(false)
const editableTabsRef = ref<typeof EditableTabs>()
const createCategoryTabRef = ref<typeof CreateAiCategoryTab>()

const modalAppendToElement = computed(() => {
    if (isWidgetState.value && !!usedWidgetShadowRootEl.value) {
        const el = usedWidgetShadowRootEl.value as HTMLElement
        return el || 'body'
    }
    return 'body'
})

function onOpenCreateCategoryTab () {
    editableTabsRef.value?.openCreateTab()
}

function onOpenEditCategoryTab (category: IAiCategoryListItem) {
    editableTabsRef.value?.openEditTab(cloneDeep(category), category.CategoryID, category.CategoryName)
}

function onCopyCategory (category: IAiCategoryListItem) {
    // TODO: need to add prompt default settings
    const copyData = {
        ...category,
        CategoryName: `${category.CategoryName} ${i18n.t('callHistoryView.copy')}`,
        OriginalCatID: isSystemCategory(category) ? category.CategoryID : category.OriginalCatID,
        AccountID: category.AccountID || getAccountID()
    }
    createCategoryTabRef.value?.setData(copyData)
    editableTabsRef.value?.openCreateTab()
}

function onCloseModal () {
    visible.value = false
    isVisibleContent.value = false
}

function onCreateCategoryTabCancel () {
    editableTabsRef.value?.openListTab()
}

function onSaveCreateCategoryTab () {
    createCategoryTabRef.value?.cancel()
    editableTabsRef.value?.openListTab()
    fetchAiCategoryList()
}

function onOpenCategoryTab (categoryId: number) {
    const category = AiCategories.value.find(i => i.CategoryID === categoryId)
    if (category) {
        onOpenEditCategoryTab(category)
    }
}

function onEditCategoryTabCancel () {
    editableTabsRef.value?.closeActiveTab()
}

function onOpenModal () {
    visible.value = true
    fetchAiCategoryList()
    setTimeout(() => {
        isVisibleContent.value = true
    }, 300)
}

defineExpose({
    close: onCloseModal,
    open: onOpenModal
})
</script>
