<template>
    <div class="w-full category-general-form">
        <div class="w-full max-w-[800px]">
            <VcForm
                ref="formRef"
                :model="{
                    ...localModel,
                    Engine: engineModel
                }"
                class="flex flex-col gap-8 xl:gap-10"
            >
                <BaseVerticalFormItemBlock
                    :block-title="i18n.t('callHistoryView.manageAi.form.generalBlockTitle')"
                    :block-description="i18n.t('callHistoryView.manageAi.form.generalBlockText')"
                >
                    <VcFormItem
                        :rules="[required]"
                        :label="i18n.t('callHistoryView.manageAi.form.groupName')"
                        prop="CategoryName"
                    >
                        <VcInput
                            v-model="localModel.CategoryName"
                            :placeholder="i18n.t('callHistoryView.manageAi.form.groupNamePlaceholder')"
                        />
                    </VcFormItem>
                    <VcFormItem
                        :label="i18n.t('callHistoryView.manageAi.form.notes')"
                        prop="Description"
                    >
                        <VcInput
                            v-model="localModel.Description"
                            :placeholder="i18n.t('callHistoryView.manageAi.form.notesPlaceholder')"
                            :rows="3"
                            type="textarea"
                        />
                    </VcFormItem>
                </BaseVerticalFormItemBlock>
                <BaseVerticalFormItemBlock
                    :block-title="i18n.t('callHistoryView.manageAi.form.originalCategoryTitle')"
                    :block-description="i18n.t('callHistoryView.manageAi.form.originalCategoryText')"
                >
                    <VcFormItem
                        :rules="[required]"
                        :label="i18n.t('callHistoryView.manageAi.form.selectCategory')"
                        prop="OriginalCatID"
                    >
                        <VcSelect
                            :model-value="localModel.OriginalCatID"
                            :options="categoryOptions"
                            :config="{
                                valueKey: 'CategoryID',
                                labelKey: 'CategoryName'
                            }"
                            :disabled="editMode"
                            :teleported="isTeleported"
                            :placeholder="i18n.t('callHistoryView.manageAi.form.selectCategoryPlaceholder')"
                            @option:click="onCategoryOptionClick"
                        />
                    </VcFormItem>
                </BaseVerticalFormItemBlock>
                <BaseVerticalFormItemBlock
                    :block-title="i18n.t('callHistoryView.manageAi.form.engineTitle')"
                    :block-description="i18n.t('callHistoryView.manageAi.form.engineText')"
                >
                    <VcFormItem
                        :rules="[required]"
                        :label="i18n.t('callHistoryView.manageAi.form.engine')"
                        prop="Engine"
                    >
                        <VcSelect
                            v-model="engineModel"
                            :placeholder="i18n.t('callHistoryView.manageAi.form.enginePlaceholder')"
                            :options="aiEngineList"
                            :config="{
                                labelKey: 'EngineName',
                                valueKey: 'ID'
                            }"
                            :teleported="isTeleported"
                        />
                    </VcFormItem>
                    <VcFormItem
                        :label="i18n.t('callHistoryView.manageAi.form.vendor')"
                    >
                        <VcInput
                            :value="engineCurrent?.VendorName"
                            disabled
                        />
                    </VcFormItem>
                </BaseVerticalFormItemBlock>
                <BaseVerticalFormItemBlock
                    :block-title="i18n.t('callHistoryView.manageAi.form.ownerAccountTitle')"
                    :block-description="i18n.t('callHistoryView.manageAi.form.ownerAccountText')"
                >
                    <VcFormItem
                        :rules="[required]"
                        :label="i18n.t('callHistoryView.manageAi.form.ownerAccount')"
                        prop="AccountID"
                    >
                        <VcSelect
                            v-model="localModel.AccountID"
                            :options="Accounts"
                            :config="{
                                labelKey: 'DistName',
                                valueKey: 'AccountID'
                            }"
                            :placeholder="i18n.t('callHistoryView.manageAi.form.selectAccount')"
                            :teleported="isTeleported"
                        />
                    </VcFormItem>
                </BaseVerticalFormItemBlock>
                <div ref="assignedAccountsRef">
                    <BaseVerticalFormItemBlock
                        :block-title="i18n.t('callHistoryView.manageAi.form.assignedAccountTitle')"
                        :block-description="i18n.t('callHistoryView.manageAi.form.assignedAccountText')"
                    >
                        <VcFormItem
                            :label="i18n.t('callHistoryView.manageAi.form.assignedAccounts')"
                            prop=""
                        >
                            <VcSelect
                                v-model="accountEntityMapping"
                                :options="Accounts"
                                :config="{
                                    labelKey: 'DistName',
                                    valueKey: 'AccountID'
                                }"
                                :placeholder="i18n.t('callHistoryView.manageAi.form.selectAccounts')"
                                :multiple="true"
                                :teleported="isTeleported"
                            />
                        </VcFormItem>
                        <div v-if="hasAssignedAccountsError">
                            <AlertMessageCard
                                type="warning"
                                :title="assignedAccountsErrorTitle"
                                :message="i18n.t('callHistoryView.manageAi.assignedAccountsErrorBody')"
                            >
                                <template #actions>
                                    <div class="pt-2">
                                        <div v-if="isAccountUnknownCategory">
                                            {{ i18n.t('callHistoryView.manageAi.unknownCategoryText') }}
                                        </div>
                                        <div
                                            v-else
                                            class="pt-3 space-y-1.5"
                                        >
                                            <!-- if we have a one group and this group exists in list  -->
                                            <div
                                                v-if="assignedEntityErrors?.Accounts.length === 1"
                                                class="flex text-primary-alternative-actions hover:text-primary-alternative-actions--focus items-center gap-2 font-semibold cursor-pointer"
                                                @click.stop="openCategory(assignedEntityErrors?.Accounts[0].CategoryID)"
                                            >
                                                {{ i18n.t('callHistoryView.openCategory') }}
                                                <i class="vc-lc-external-link" />
                                            </div>
                                            <template v-else>
                                                <div
                                                    v-for="category in assignedEntityErrors?.Accounts"
                                                    :key="category.CategoryID"
                                                    class="flex text-primary-alternative-actions hover:text-primary-alternative-actions--focus items-center gap-2 font-semibold cursor-pointer"
                                                    @click.stop="openCategory(category.CategoryID)"
                                                >
                                                    {{ category.CategoryName || i18n.t('callHistoryView.noName') }}
                                                    <i
                                                        v-if="category.CategoryExists"
                                                        class="vc-lc-external-link"
                                                    />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </AlertMessageCard>
                        </div>
                    </BaseVerticalFormItemBlock>
                </div>
                <div ref="assignedUsersRef">
                    <BaseVerticalFormItemBlock
                        :block-title="i18n.t('callHistoryView.manageAi.form.assignedUsersTitle')"
                        :block-description="i18n.t('callHistoryView.manageAi.form.assignedUsersText')"
                    >
                        <VcFormItem
                            :label="i18n.t('callHistoryView.manageAi.form.assignedUsers')"
                        >
                            <VcSelect
                                v-model="usersEntityMapping"
                                :options="Users"
                                :config="{
                                    labelKey: 'UserName',
                                    valueKey: 'UserID'
                                }"
                                :placeholder="i18n.t('callHistoryView.manageAi.form.selectAccounts')"
                                :multiple="true"
                                :teleported="isTeleported"
                            />
                        </VcFormItem>
                        <div v-if="hasAssignedUsersError">
                            <AlertMessageCard
                                type="warning"
                                :title="assignedUsersErrorTitle"
                                :message="i18n.t('callHistoryView.manageAi.assignedUsersErrorBody')"
                            >
                                <template #actions>
                                    <div class="pt-2">
                                        <div v-if="isUserUnknownCategory">
                                            {{ i18n.t('callHistoryView.manageAi.unknownUserCategoryText') }}
                                        </div>
                                        <!-- if we have a one group and this group exists in list  -->
                                        <div
                                            v-else
                                            class="pt-3 space-y-1.5"
                                        >
                                            <div
                                                v-if="assignedEntityErrors?.Users.length === 1"
                                                class="flex text-primary-alternative-actions hover:text-primary-alternative-actions--focus items-center gap-2 font-semibold cursor-pointer"
                                                @click.stop="openCategory(assignedEntityErrors?.Users[0].CategoryID)"
                                            >
                                                {{ i18n.t('callHistoryView.openCategory') }}
                                                <i class="vc-lc-external-link" />
                                            </div>
                                            <template v-else>
                                                <div
                                                    v-for="category in assignedEntityErrors?.Users"
                                                    :key="category.CategoryID"
                                                    class="flex text-primary-alternative-actions hover:text-primary-alternative-actions--focus items-center gap-2 font-semibold cursor-pointer"
                                                    @click.stop="openCategory(category.CategoryID)"
                                                >
                                                    {{ category.CategoryName || i18n.t('callHistoryView.noName') }}
                                                    <i class="vc-lc-external-link" />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </AlertMessageCard>
                        </div>
                    </BaseVerticalFormItemBlock>
                </div>
            </VcForm>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { VcForm } from '@voicenter-team/voicenter-ui-plus'
import BaseVerticalFormItemBlock from '@/components/Forms/BaseVerticalFormItemBlock.vue'
import useValidationRules from '@/composables/useValidationRules.ts'
import { IAiCategoryListItem, IAiCategoryUpsert, UpsertDataAccountsError, UpsertDataUsersError } from '@/types/aiManager'
import { useVModel } from '@vueuse/core'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import usePermissionEntities from '@/composables/usePermissionEntities.ts'
import { getAccountID } from '@/composables/useJWTPayloadData.ts'
import AlertMessageCard from '@/components/Card/AlertMessageCard.vue'
import i18n from '@/plugins/i18n.ts'
import { isTeleported } from '@/services/GlobalState.ts'

/* Composable */
const { required } = useValidationRules()
const { AiOriginalCategories, AiEngines } = useManageAiCategoriesData()
const { Accounts, Users } = usePermissionEntities()

/* Props */
const props = defineProps<{
    modelValue: IAiCategoryUpsert
    engine?: number
    entityUsers?: Array<number>
    entityAccounts?: Array<number>
    beforeOriginalCategoryChange?: (category?: IAiCategoryListItem, oldId?: number) => boolean
    assignedEntityErrors?: {
        Accounts: Array<UpsertDataAccountsError>
        Users: Array<UpsertDataUsersError>
    },
    editMode?: boolean
}>()
/* Emits */
const emit = defineEmits<{
    (e: 'update:modelValue', pl: IAiCategoryUpsert): void
    (e: 'change:original', pl?: IAiCategoryListItem, oldCategoryID?: number): void
    (e: 'change:entityUsers', pl?: Array<number>): void
    (e: 'change:entityAccounts', pl?: Array<number>): void
    (e: 'update:engine', pl: number): void
    (e: 'open:category', pl: number): void
}>()

/* Data */
const localModel = useVModel(props, 'modelValue', emit)
const engineModel = useVModel(props, 'engine', emit)
const accountEntityMapping = useVModel(props, 'entityAccounts', emit)
const usersEntityMapping = useVModel(props, 'entityUsers', emit)
/* Refs */
const assignedAccountsRef = ref<HTMLElement>()
const assignedUsersRef = ref<HTMLElement>()
const formRef = ref<typeof VcForm>()

/* Computed */
const categoryOptions = computed(() => {
    return [ ...AiOriginalCategories.value ]
})
const aiEngineList = computed(() => {
    return AiEngines.value || []
})
const engineCurrent = computed(() => {
    return aiEngineList.value.find(i => i.ID === engineModel.value)
})
const hasAssignedAccountsError = computed(() => {
    return !!props.assignedEntityErrors?.Accounts?.length
})
const hasAssignedUsersError = computed(() => {
    return !!props.assignedEntityErrors?.Users?.length
})
const assignedAccountsErrorTitle = computed(() => {
    if (!hasAssignedAccountsError.value) {
        return ''
    }
    const accounts = props.assignedEntityErrors?.Accounts.reduce<Array<string>>((acc, curr) => {
        const accountNames = curr.Accounts.map(i => i.AccountName).join(', ')
        acc.push(accountNames)
        return acc
    }, [])
    return i18n.t('callHistoryView.manageAi.assignedAccountsErrorTitle', { accounts: accounts?.join(', ') })
})
const isAccountUnknownCategory = computed(() => {
    return hasAssignedAccountsError.value && props.assignedEntityErrors?.Accounts.length === 1 && !props.assignedEntityErrors?.Accounts[0]?.CategoryExists
})

const assignedUsersErrorTitle = computed(() => {
    if (!hasAssignedUsersError.value) {
        return ''
    }
    const users = props.assignedEntityErrors?.Users.reduce<Array<string>>((acc, curr) => {
        const accountNames = curr.Users.map(i => i.UserName).join(', ')
        acc.push(accountNames)
        return acc
    }, [])
    return i18n.t('callHistoryView.manageAi.assignedUsersErrorTitle', { users: users?.join(', ') })
})
const isUserUnknownCategory = computed(() => {
    return hasAssignedAccountsError.value && props.assignedEntityErrors?.Accounts.length === 1 && !props.assignedEntityErrors?.Accounts[0]?.CategoryExists
})

/* Methods */
function onChangeOriginalCategory (category?: IAiCategoryListItem, oldCategoryID?: number) {
    emit('change:original', category, oldCategoryID)
}

function onCategoryOptionClick (category?: IAiCategoryListItem) {
    if (props.editMode) {
        return
    }
    if (props.beforeOriginalCategoryChange) {
        const res = props.beforeOriginalCategoryChange(category, localModel.value.OriginalCatID)
        if (res) {
            localModel.value.OriginalCatID = category?.CategoryID
            onChangeOriginalCategory(category)
        }
    } else {
        localModel.value.OriginalCatID = category?.CategoryID
        onChangeOriginalCategory(category)
    }
}

function openCategory (categoryId: number) {
    if (categoryId !== undefined) {
        emit('open:category', categoryId)
    }
}

/* Watcher */
watch(
    Accounts,
    (val) => {
        const currentId = localModel.value.AccountID ?? getAccountID()
        const accountInList = val?.find(i => i.AccountID === currentId)
        if (accountInList) {
            localModel.value.AccountID = accountInList.AccountID
        } else {
            localModel.value.AccountID = undefined
        }
    },
    {
        immediate: true
    }
)
watch(
    hasAssignedAccountsError,
    (val) => {
        if (val) {
            nextTick(() => {
                assignedAccountsRef.value?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                })
            })
        }
    }
)
watch(
    hasAssignedUsersError,
    (val) => {
        if (val) {
            nextTick(() => {
                assignedUsersRef.value?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                })
            })
        }
    }
)
/* Expose */
defineExpose({
    validate: () => formRef.value?.validate(),
    resetForm: () => formRef.value?.resetFields()
})
</script>
