<template>
    <div class="w-full call-history-forms">
        <div class="block-title mb-3 lg:mb-6">
            {{ i18n.t('callHistoryView.forms.title') }}
        </div>
        <div class="call-history-forms__content flex justify-start">
            <VcTable
                :columns="callFormsTableColumns"
                :options="formsMappedData"
                :loading="formsLoadingState || refreshLoading"
                :empty-data-text="i18n.t('callHistoryView.noData')"
                with-pagination
                stripe
            >
                <template #preview-row="{ row }">
                    <div class="flex-center gap-1">
                        <VcButtonIcon
                            icon="vc-lc-eye"
                            @click="onOpenPreview(row)"
                        />
                        <VcButtonIcon
                            icon="vc-lc-square-pen"
                            @click="onOpenEdit(row)"
                        />
                    </div>
                </template>
            </VcTable>
        </div>
        <CallFormPreviewModal
            ref="callFormPreviewModalRef"
        />
        <CallFormEditModal
            ref="callFormEditModalRef"
            @updated:form="onRefreshFormList"
        />
    </div>
</template>

<script setup lang="ts">
import { formsLoadingState, formsMappedData, getCallFormsList } from '@/composables/useCallForms.ts'
import callFormsTableColumns from '@/enums/tables/callForms.table.enum.ts'
import { IFormsMappedData } from '@/types/api'
import CallFormPreviewModal from '@/components/Modals/CallFormPreviewModal.vue'
import { ref } from 'vue'
import CallFormEditModal from '@/components/Modals/CallFormEditModal.vue'
import i18n from '@/plugins/i18n.ts'


getCallFormsList()

const callFormPreviewModalRef = ref<typeof CallFormPreviewModal>()
const callFormEditModalRef = ref<typeof CallFormEditModal>()
const refreshLoading = ref<boolean>(false)

function onOpenEdit (data: IFormsMappedData) {
    callFormEditModalRef.value?.open(data)
}

async function onRefreshFormList () {
    refreshLoading.value = true
    try {
        // await refreshCallHistoryData()
        await getCallFormsList()
    } finally {
        refreshLoading.value = false
    }
}

function onOpenPreview (data: IFormsMappedData) {
    callFormPreviewModalRef.value?.open(data)
}
</script>
