<template>
    <div class="w-full category-prompt-configuration">
        <BaseVerticalExpandItem
            :block-title="i18n.t('callHistoryView.manageAi.form.promptConfigurationTitle')"
            :block-description="i18n.t('callHistoryView.manageAi.form.promptConfigurationText')"
        >
            <VcCard class="p-3 lg:p-6 prompt-configuration__card">
                <div class="prompt-configuration__card-content">
                    <transition
                        appear
                        name="fade2"
                        mode="out-in"
                        :duration="{ enter: 200, leave: 200 }"
                    >
                        <div
                            v-if="isCustomSettings"
                            class="space-y-3"
                        >
                            <AlertMessageCard
                                :title="i18n.t('callHistoryView.manageAi.alert.configurationDifferentFromParent.title')"
                                :message="i18n.t('callHistoryView.manageAi.alert.configurationDifferentFromParent.message')"
                                type="warning"
                            />
                            <VcButton
                                size="small"
                                type="outline"
                                color="secondary"
                                @click="resetSettings"
                            >
                                {{ i18n.t('callHistoryView.resetChanges') }}
                            </VcButton>
                        </div>
                    </transition>
                    <p class="text-sm py-3 lg:py-6">
                        {{ i18n.t('callHistoryView.manageAi.form.promptSpecText') }}
                    </p>
                    <div>
                        <VcForm
                            ref="promptFormRef"
                            :model="{
                                ...promptLocalModel
                            }"
                        >
                            <div class="flex items-center gap-3">
                                <VcFormItem
                                    :label="i18n.t('callHistoryView.manageAi.form.maxTokens')"
                                >
                                    <VcInput
                                        :value="promptLocalModel.MaxTokens"
                                        disabled
                                    />
                                </VcFormItem>
                            </div>
                            <div>
                                <VcFormItem
                                    :rules="[required]"
                                    :label="i18n.t('callHistoryView.manageAi.form.system')"
                                    prop="System"
                                >
                                    <VcInput
                                        v-model="promptLocalModel.System"
                                        type="textarea"
                                        rows="3"
                                    />
                                </VcFormItem>
                            </div>
                            <div>
                                <VcFormItem
                                    :rules="[required]"
                                    :label="i18n.t('callHistoryView.manageAi.form.promptBody')"
                                    prop="Body"
                                >
                                    <VcInput v-model="promptLocalModel.Body" />
                                </VcFormItem>
                            </div>
                            <div>
                                <VcFormItem
                                    :rules="[required]"
                                    :label="i18n.t('callHistoryView.manageAi.form.promptFooter')"
                                    prop="Footer"
                                >
                                    <VcInput v-model="promptLocalModel.Footer" />
                                </VcFormItem>
                            </div>
                        </VcForm>
                    </div>
                </div>
            </VcCard>
        </BaseVerticalExpandItem>
    </div>
</template>

<script setup lang="ts">
import BaseVerticalExpandItem from '@/components/Forms/BaseVerticalExpandItem.vue'
import { IAiCategoryListItem, IAiPromptConfig } from '@/types/aiManager'
import { useVModel } from '@vueuse/core'
import { computed, ref } from 'vue'
import { VcForm } from '@voicenter-team/voicenter-ui-plus'
import useValidationRules from '@/composables/useValidationRules.ts'
import AlertMessageCard from '@/components/Card/AlertMessageCard.vue'
import i18n from '@/plugins/i18n.ts'

const FieldsToCompare: Array<keyof IAiPromptConfig> = [ 'Body', 'Footer', 'System' ]

const { required } = useValidationRules()

const props = defineProps<{
    modelValue: IAiPromptConfig
    originalCategory?: IAiCategoryListItem
}>()

const emit = defineEmits<{
    (e: 'update:modelValue', pl: IAiPromptConfig): void
}>()

const promptLocalModel = useVModel(props, 'modelValue', emit)

const promptFormRef = ref<typeof VcForm>()

const isCustomSettings = computed(() => {
    const originalSettings = props.originalCategory?.AiPrompts?.Insights?.PromptConfig
    if (!originalSettings) {
        return false
    }
    const currentSettings: Partial<IAiPromptConfig> = {
        System: promptLocalModel.value.System,
        Body: promptLocalModel.value.Body,
        Footer: promptLocalModel.value.Footer
    }

    return FieldsToCompare.some((field) => currentSettings[field] !== originalSettings?.[field])
})

function resetSettings () {
    const originalSettings = props.originalCategory?.AiPrompts?.Insights?.PromptConfig
    if (originalSettings) {
        const { System, Footer, Body } = originalSettings

        promptLocalModel.value = {
            ...promptLocalModel.value,
            System,
            Body,
            Footer
        }
    }
}

defineExpose({
    validate: () => promptFormRef.value?.validate(),
    resetForm: () => promptFormRef.value?.resetFields()
})
</script>
