import i18n from '@/plugins/i18n.ts'
import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import { computed, ComputedRef } from 'vue'

const tableAiCategoriesColumns: ComputedRef<ColumnType> = computed(() => {
    return [
        {
            columnItemKey: 'id-column',
            rowItemKeyKey: 'id-row',
            rowItemLabelKey: 'CategoryID',
            columnItemLabel: 'ID',
            sortBy: 'CategoryID',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'name-column',
            rowItemKeyKey: 'name-row',
            rowItemLabelKey: 'CategoryName',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.categoryName'),
            sortBy: 'CategoryName',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'account-column',
            rowItemKeyKey: 'account-row',
            rowItemLabelKey: 'AccountName',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.accountName'),
            sortBy: 'AccountName',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'questions-column',
            rowItemKeyKey: 'questions-row',
            rowItemLabelKey: 'QuestionsLength',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.questions'),
            columnAlignment: 'left',
            rowItemAlignment: 'left',
            sortBy: 'QuestionsLength'
        },
        {
            columnItemKey: 'assignAcc-column',
            rowItemKeyKey: 'assignAcc-row',
            rowItemLabelKey: 'AssignAccounts',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.assignAccounts'),
            columnAlignment: 'left',
            rowItemAlignment: 'left',
            sortBy: 'AssignAccounts'
        },
        {
            columnItemKey: 'assignUsers-column',
            rowItemKeyKey: 'assignUsers-row',
            rowItemLabelKey: 'AssignUsers',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.assignUsers'),
            columnAlignment: 'left',
            rowItemAlignment: 'left',
            sortBy: 'AssignUsers'
        },
        {
            columnItemKey: 'status-column',
            rowItemKeyKey: 'status-row',
            rowItemLabelKey: 'Status',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.categoryStatus'),
            columnAlignment: 'center',
            rowItemAlignment: 'center',
            sortBy: 'Status'
        },
        {
            columnItemKey: 'parent-column',
            rowItemKeyKey: 'parent-row',
            rowItemLabelKey: 'OriginalCategoryName',
            columnItemLabel: i18n.t('callHistoryView.manageAi.table.parentCategory'),
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'actions-column',
            rowItemKeyKey: 'actions-row',
            rowItemLabelKey: 'actions',
            columnItemLabel: ' '
        }
    ]
})

export default tableAiCategoriesColumns
