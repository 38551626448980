<template>
    <VcJsonSchema
        v-model="localModel"
        :disabled="disabled"
        :actions-text="i18n.t('callHistoryView.editSchema')"
        :root-type="defaultType"
        :modal-append-to="modalAppendToElement"
        show-overflow
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { ObjectAnyType } from '@/types/types'
import { modalAppendToElement } from '@/services/GlobalState.ts'
import i18n from '@/plugins/i18n.ts'

const DEFAULT_TYPE = 'object'
const DATA_TYPE_TYPES: Record<number, 'string' | 'boolean' | 'number' | 'integer' | 'object' | 'array' | 'null'> = {
    1: 'boolean',
    2: 'string',
    3: 'number',
    4: 'array',
    5: 'object',
    6: 'object'
}

const props = defineProps<{
    modelValue?: ObjectAnyType
    disabled?: boolean
    dataType?: number
}>()
const emit = defineEmits<{
    (e: 'update:modelValue', pl?: string): void
}>()

const localModel = useVModel(props, 'modelValue', emit)
const defaultType = computed(() => {
    return props.dataType ? DATA_TYPE_TYPES[props.dataType] || DEFAULT_TYPE : DEFAULT_TYPE
})
</script>
