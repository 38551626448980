<template>
    <div class="w-full dialler-data-tab">
        <div class="dialler-data-tab__content">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.dialler.campaignTitle') }}
            </div>
            <div class="queue-data-tab__info flex flex-wrap gap-y-3 gap-x-4 lg:gap-x-6 xl:gap-x-10">
                <CallInfoItemCard
                    v-for="(itemCard, i) in campaignInfoBlocks"
                    :key="`item-${i}`"
                    :content="itemCard"
                    style="min-width: 172px"
                />
            </div>
            <hr class="border-ui-lines my-10">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.dialler.callTitle') }}
            </div>
            <div class="queue-data-tab__info flex flex-wrap gap-y-3 gap-x-4 lg:gap-x-6 xl:gap-x-10">
                <CallInfoItemCard
                    v-for="(itemCard, i) in callInfoBlocks"
                    :key="`item-${i}`"
                    :content="itemCard"
                    style="min-width: 172px"
                />
            </div>
            <hr class="border-ui-lines my-10">
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import i18n from '@/plugins/i18n.ts'
import CallInfoItemCard from '@/components/Card/CallInfoItemCard.vue'
import { getDiallerData } from '@/composables/useCDRData.ts'

const diallerData = computed(() => {
    return getDiallerData()
})

const campaignInfoBlocks = computed(() => {
    return [
        {
            title: i18n.t('callHistoryView.dialler.campaignId'),
            content: diallerData.value?.CampaignID
        },
        {
            title: i18n.t('callHistoryView.dialler.campaignStatus'),
            content: diallerData.value?.StatusName
        },
        {
            title: i18n.t('callHistoryView.dialler.campaignType'),
            content: diallerData.value?.CampaignTypeName
        }
    ]
})
const callInfoBlocks = computed(() => {
    return [
        {
            title: i18n.t('callHistoryView.dialler.callId'),
            content: diallerData.value?.CallID
        },
        {
            title: i18n.t('callHistoryView.dialler.callStatus'),
            content: diallerData.value?.CallStatus
        },
        {
            title: i18n.t('callHistoryView.dialler.callInserted'),
            content: diallerData.value?.CallInserted?.toString()
        },
        {
            title: i18n.t('callHistoryView.dialler.callRetry'),
            content: i18n.t('callHistoryView.dialler.callRetryData', {
                num: diallerData.value?.RetryNum,
                amount: diallerData.value?.RetryAmount
            })
        }
    ]
})
</script>

