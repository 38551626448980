<template>
    <div
        ref="wrapper"
        class="call-history-widget relative"
    >
        <div
            v-if="isInitialized"
            class="call-history-widget__content"
        >
            <transition
                name="fade"
                mode="out-in"
                appear
            >
                <div
                    v-if="currentGlobalLoadingState"
                    class="flex-center absolute max-w-[90vw]"
                    style="left: 50%; top: 35%; transform: translate(-50%)"
                >
                    <component
                        :is="LOADERS[LoaderName]"
                    />
                </div>
                <div
                    v-else
                    class="w-full"
                >
                    <div
                        v-if="isAxiosError"
                        class="p-3"
                    >
                        <div
                            class="flex-center p-5 rounded-md bg-error-actions-bg--focus text-error font-semibold"
                        >
                            {{ errorMessage }}
                        </div>
                    </div>
                    <CallHistoryPreview
                        v-else
                        :call-id="callHistoryId"
                        :tabs-to-render="tabsToRender"
                    >
                        <template #header-actions>
                            <div
                                class="flex justify-end items-center gap-4"
                            >
                                <ManageAiCategories
                                    v-if="canManageAiCategoriesEdit"
                                />
                            </div>
                        </template>
                    </CallHistoryPreview>
                </div>
            </transition>
        </div>
        <VcNotification
            v-if="wrapper"
            :teleported-to="wrapper"
            group="w-bottom-right"
            position="bottom-right"
        />
        <VcConfirmModal
            v-if="wrapper"
            :append-to="wrapper"
        />
        <VcConfirmPopover
            v-if="wrapper"
            :options="{
                teleported: false
            }"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRef } from 'vue'
import defineTheme from '@voicenter-team/voicenter-ui-plus/src/theme'
import CallHistoryPreview from '@/components/View/CallHistoryPreview.vue'
import { OfficeringLoader, SimpleLoader, VoiceLoader } from '@/components/Loader'
import { fetchCallHistoryData } from '@/composables/globalApiDataComposable.ts'
import { setUrlQueryParams } from '@/composables/useQueryData.ts'
import { useShadowHost } from './utils.ts'
import {
    currentGlobalLoadingState,
    setTeleportedPopupsState,
    setWidgetState,
    usedWidgetShadowRootEl
} from '@/services/GlobalState.ts'
import { TWidgetOptions } from '@/types/widget'
import { useGlobalDataProvide } from '@/composables/useGlobalProvideInject.ts'
import themeColors from '@/theme/colors.json'
import TagsWorker from '@/services/TagsWorker.ts'
import { COLORS } from '@/enums/colors.enum.ts'
import { setGlobalThemeColors } from '@/composables/useColorsComposable.ts'
import { TAB_RELATED_CALLS_KEY } from '@/enums/tabs.enum.ts'
import { VcNotification } from '@voicenter-team/voicenter-ui-plus'
import { TGlobalColorsData, TUIColorName } from '@/types/colors'
import ManageAiCategories from '@/components/AiSettings/ManageAiCategories.vue'
import { isUserAdmin } from '@/composables/useJWTPayloadData.ts'
import useCustomQuestions from '@/composables/useCustomQuestions.ts'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import { ObjectAnyType } from '@/types/types'

defineOptions({
    inheritAttrs: false
})

type TOpenRelatedCallsFn = (callId: string) => void

const DEFAULT_LOADER_NAME = 'SimpleLoader'
const DEFAULT_CONFIGS: TWidgetOptions = {
    token: '',
    callId: '',
    injected: false,
    loaderName: DEFAULT_LOADER_NAME,
    tabsList: []
}
// eslint-disable-next-line
const LOADERS: Record<string, any> = {
    SimpleLoader,
    OfficeringLoader,
    VoiceLoader
}

/* Props */
const props = defineProps<{
    callId?: string
}>()
const emit = defineEmits<{
    (e: 'widget:ready'): void
    (e: 'open:related-call', pl: string): void
    (e: 'error:options', msg: string): void
    (e: 'error:initData', pl: ObjectAnyType): void
}>()

/* Data */
// const SECTION_TYPE = 2
// const SECTION_GROUP = 3
const wrapper = ref<HTMLElement>()
const isInitialized = ref(false)
const tabsToRender = ref<Array<string>>([])
const callHistoryId = ref<string | undefined>(undefined)
const LoaderName = ref(DEFAULT_LOADER_NAME)
const openRelatedCallsFn = ref<TOpenRelatedCallsFn | undefined>(undefined)
const isAxiosError = ref(false)
const errorMessage = ref('')

const host = toRef(useShadowHost())

/* Computed */
const canManageAiCategoriesEdit = computed(() => {
    return isUserAdmin.value
})

/* Methods */
async function initDataCallBack (ignored = false) {
    const withRelatedCalls = tabsToRender.value.includes(TAB_RELATED_CALLS_KEY)
    return fetchCallHistoryData(ignored, withRelatedCalls)
        .then(() => {
            if (callHistoryId.value) {
                const { fetchCustomQuestionList } = useCustomQuestions()
                const { fetchAiConfigs } = useManageAiCategoriesData()
                fetchCustomQuestionList(callHistoryId.value)
                if (!ignored) {
                    fetchAiConfigs()
                }
            }
        })
        .catch((e) => {
            console.log({ e })
            isAxiosError.value = true
            errorMessage.value = e.axiosResponse?.message || 'Widget Error!'
            emit('error:initData', e.axiosResponse)
        })
}

function checkOptions (options: TWidgetOptions) {
    return (!options.callId && !props.callId) || !options.token || options?.tabsList?.length
}

function getFinalOptions (options: TWidgetOptions) {
    const callId = props.callId || options.callId || DEFAULT_CONFIGS.callId

    const _uiColors = options.colors?.uiColors || {}
    const _baseColors = options.colors || {}
    return {
        ...DEFAULT_CONFIGS,
        ...options,
        callId,
        onOpenRelatedCalls: options.onOpenRelatedCalls,
        colors: {
            cdrColors: _baseColors.cdrColors || COLORS.cdrColors,
            rtcpColors: _baseColors.rtcpColors || COLORS.rtcpColors,
            agentColor: _baseColors.agentColor || COLORS.agentColor,
            clientColor: _baseColors.clientColor || COLORS.clientColor,
            defaultEmotionColor: _baseColors.defaultEmotionColor || COLORS.defaultEmotionColor,
            soundWavesColor: _baseColors.soundWavesColor || COLORS.soundWavesColor,
            soundProgressColor: _baseColors.soundProgressColor || COLORS.soundProgressColor,
            uiColors: {
                ...COLORS.uiColors,
                ..._uiColors
            }
        }
    } as TWidgetOptions
}

function openRelatedCallPage (callId: string) {
    if (openRelatedCallsFn.value) {
        openRelatedCallsFn.value(callId)
    }
    emit('open:related-call', callId)
}

function initData (options: TWidgetOptions) {
    isInitialized.value = true
    isAxiosError.value = false
    errorMessage.value = ''
    const isOptionsValid = checkOptions(options)
    if (!isOptionsValid) {
        emit('error:options', 'Widget options is not valid!')
        console.error('Widget options is not valid!')
        return
    }

    const finalOptions = getFinalOptions(options || DEFAULT_CONFIGS)
    defineWidgetTheme(finalOptions.colors?.uiColors)
    callHistoryId.value = finalOptions.callId
    setGlobalThemeColors(options.colors as TGlobalColorsData)
    tabsToRender.value = [ ...options.tabsList ]
    openRelatedCallsFn.value = options.onOpenRelatedCalls
    LoaderName.value = options.loaderName || DEFAULT_LOADER_NAME
    setUrlQueryParams(
        options?.injected || false,
        options?.callId || '',
        initDataCallBack,
        options?.token,
    )
}

function defineWidgetTheme (theme?: Record<TUIColorName, string>) {
    if (host.value) {
        defineTheme({
            type: 'customJson',
            config: theme || themeColors
        }, host.value)
    }
}

function initWidget () {
    setWidgetState(true)
    setTeleportedPopupsState(false)

    if (host.value) {
        defineWidgetTheme(themeColors)
        host.value.initData = initData
        host.value.defineTheme = defineWidgetTheme
        host.value.initWidget = initWidget
        TagsWorker.rootComponent = host.value
        usedWidgetShadowRootEl.value = wrapper.value
    }
    emit('widget:ready')
}

/* Mounted */
onMounted(initWidget)

/* Expose */
defineExpose({
    initData,
    defineTheme: defineWidgetTheme,
    initWidget,
})

/* Global Provided Data */
useGlobalDataProvide({
    openRelatedCallPage
})
</script>
