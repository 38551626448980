import { reactive } from 'vue'
import { CategoryUpsertBulkErrorItem, UpsertDataAccountsError, UpsertDataUsersError } from '@/types/aiManager'
import usePermissionEntities from '@/composables/usePermissionEntities.ts'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'

const { Accounts, Users } = usePermissionEntities()
const { AiCategories } = useManageAiCategoriesData()

export default function useCategoryUpsertErrors () {
    const upsertDataErrors = reactive<{
        Accounts: Array<UpsertDataAccountsError>
        Users: Array<UpsertDataUsersError>
    }>({
        Accounts: [],
        Users: []
    })

    const resetAllErrors = () => {
        upsertDataErrors.Accounts = []
        upsertDataErrors.Users = []
    }

    const setEntityDataErrors = (response: Array<CategoryUpsertBulkErrorItem>) => {
        const mapCategories = new Map(AiCategories.value.map((obj) => [ obj.CategoryID, obj ]))
        const mapAccounts = new Map(Accounts.value.map((obj) => [ obj.AccountID, obj.DistName ]))
        const mapUsers = new Map(Users.value.map((obj) => [ obj.UserID, obj.UserName ]))
        response.forEach(category => {
            if (category.Accounts.length) {
                upsertDataErrors.Accounts.push({
                    CategoryID: category.CategoryID,
                    CategoryName: category.CategoryName,
                    CategoryExists: mapCategories.has(category.CategoryID),
                    Accounts: category.Accounts.map(accountID => ({
                        AccountName: mapAccounts.get(accountID) || '---',
                        AccountID: accountID
                    }))
                })
            }
            if (category.Users.length) {
                upsertDataErrors.Users.push({
                    CategoryID: category.CategoryID,
                    CategoryName: category.CategoryName,
                    CategoryExists: mapCategories.has(category.CategoryID),
                    Users: category.Users.map(userId => ({
                        UserName: mapUsers.get(userId) || '---',
                        UserID: userId
                    }))
                })
            }
        })
    }

    return {
        upsertDataErrors,
        resetAllErrors,
        setEntityDataErrors
    }
}
