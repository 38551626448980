<template>
    <div
        ref="wrapperRef"
        class="answer-data-type-popover"
    >
        <span>{{ i18n.t('callHistoryView.customQuestions.answerFormat') }}</span>
        <VcPopover
            ref="popoverRef"
            :disabled="disabled"
            :teleported="isTeleported"
            placement="bottom-end"
            trigger="click"
        >
            <template #reference>
                <button
                    type="button"
                    class="inline-flex items-center gap-1 hover:text-primary-actions--focus"
                >
                    {{ activeModelData?.label }} <i class="vc-icon-down" />
                </button>
            </template>
            <div class="text-xs p-2">
                <div
                    v-for="(item, i) in parameterDataTypes"
                    :key="i"
                    :class="{'text-primary font-medium': item.ID === activeTypeModel.AnswerDataTypeID}"
                    class="p-1 rounded cursor-pointer hover:bg-active-elements-bg--focus"
                    @click="onSelectAnswerType(item)"
                >
                    {{ item.label }}
                </div>
            </div>
        </VcPopover>
        <VcJsonSchema
            v-if="activeTypeModel.AnswerDataTypeID > 3"
            v-model="activeTypeModel.AnswerDataSchema"
            :root-type="DATA_TYPE_TYPES[activeTypeModel.AnswerDataTypeID]"
            :disabled="disabled"
            :modal-append-to="modalAppendToElement"
            hide-preview
            class="w-max"
        >
            <template #default="{ handler }">
                <span
                    class="inline-flex items-center cursor-pointer hover:text-primary-actions--focus"
                    @click="handler"
                >
                    <i class="vc-lc-square-pen" />
                </span>
            </template>
        </VcJsonSchema>
    </div>
</template>

<script setup lang="ts">
import { isTeleported, modalAppendToElement } from '@/services/GlobalState.ts'
import { computed, ref } from 'vue'
import i18n from '@/plugins/i18n.ts'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import { useVModel } from '@vueuse/core'
import { IAiAnswerType } from '@/types/aiManager'
import { VcPopover } from '@voicenter-team/voicenter-ui-plus'
import { ICustomQAnswerDataType } from '@/types/customQuestions'

const { AiAnswerTypes } = useManageAiCategoriesData()

const DATA_TYPE_TYPES: Record<number, 'string' | 'boolean' | 'number' | 'integer' | 'object' | 'array' | 'null'> = {
    1: 'boolean',
    2: 'string',
    3: 'number',
    4: 'array',
    5: 'object',
    6: 'object'
}

const props = defineProps<{
    modelValue: ICustomQAnswerDataType
    disabled?: boolean
}>()
const emit = defineEmits<{
    (e: 'update:modelValue', pl: ICustomQAnswerDataType): void
}>()

const popoverRef = ref<typeof VcPopover>()
const activeTypeModel = useVModel(props, 'modelValue', emit)

const activeModelData = computed(() => {
    return parameterDataTypes.value?.find(i => i.ID === activeTypeModel.value.AnswerDataTypeID)
})

const parameterDataTypes = computed(() => {
    return AiAnswerTypes.value?.map((item) => ({
        ...item,
        label: i18n.t(`callHistoryView.dataTypes.${item.TypeName}`),
    })).sort((a, b) => a.label.localeCompare(b.label))
})

function onSelectAnswerType (data: IAiAnswerType) {
    activeTypeModel.value = {
        AnswerDataTypeID: data.ID,
        AnswerDataType: data.TypeName,
        AnswerDataSchema: undefined
    }
    popoverRef.value?.hide()
}
</script>

<style lang="scss">
.answer-data-type-popover {
    @apply inline-flex items-center gap-1;
}
</style>
