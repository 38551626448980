<template>
    <div class="call-emotion-analysis">
        <div class="call-emotion-analysis__header block-title">
            {{ i18n.t('callHistoryView.ai.emotionAnalysisHeader') }}
        </div>
        <div class="call-emotion-analysis__content">
            <CallAISatisfactionBlock
                class="border-b border-ui-lines"
            />
            <EmotionalLinesChart
                ref="lineChartRef"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CallAISatisfactionBlock from '@/components/Blocks/AI/CallAISatisfactionBlock.vue'
import EmotionalLinesChart from '@/components/Chart/EmotionalLinesChart.vue'
import { TEmotionCallerClickData } from '@/types/types'
import i18n from '@/plugins/i18n.ts'


/* Data */
const lineChartRef = ref<typeof EmotionalLinesChart>()

/* Methods */
function highlightEmotionsByUser (data: TEmotionCallerClickData) {
    lineChartRef.value?.highlight(data)
}

/* Expose */
defineExpose({
    highlightEmotionsByUser
})
</script>

<style scoped lang="scss">
.call-emotion-analysis {
    @apply pb-7;

    &__header {
        box-shadow: 0 4px 8px 0 rgba(38, 49, 72, 0.08);
        @apply py-3 flex justify-between items-center px-4 border-b border-ui-lines relative bg-light-bg;
    }
}
</style>
