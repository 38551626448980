import i18n from '@/plugins/i18n'
import { FormItemRule } from 'element-plus'

export default function () {

    const required: FormItemRule = {
        required: true,
        message: i18n.t('callHistoryView.validation.required')
    }

    const email: FormItemRule = {
        type: 'email',
        message: i18n.t('callHistoryView.validation.notValidEmail')
    }

    const min = (minValue: number): FormItemRule => {
        return {
            validator: (_, value, callback) => {
                if (value < minValue) {
                    callback(new Error(i18n.t('callHistoryView.validation.biggerThan', { value: minValue })))
                } else {
                    callback()
                }
            }
        }
    }

    const notZero: FormItemRule = {
        validator: (_, value, callback) => {
            if (value === undefined || value === 0) {
                callback(new Error(i18n.t('callHistoryView.validation.required')))
            } else {
                callback()
            }
        }
    }

    const number: FormItemRule = {
        validator: (_, value: number | string, callback) => {
            if (typeof value === 'number') {
                return true
            }
            const isStringValid = value.split('').every(el => !isNaN(Number(el)))

            if (!isStringValid) {
                callback(new Error(i18n.t('callHistoryView.validation.numeric')))
            } else {
                callback()
            }
        }
    }

    return {
        required,
        email,
        min,
        notZero,
        number,
    }
}
