<template>
    <div class="w-full h-full word-cloud-chart">
        <div
            class="w-full h-full relative"
        >
            <div
                v-if="showMask && keywords.length"
                class="mask"
                :style="{'background-image': `url(${cloud})`}"
            />
            <v-chart
                v-if="keywords.length"
                ref="chartRef"
                :option="options"
                class="word-cloud-chart__chart w-full h-full"
                autoresize
                @click="clickHandler"
            />
            <div
                v-else
                class="flex-center text-placeholders w-full h-full upload-redactor-placeholder font-medium"
            >
                {{ i18n.t('callHistoryView.noData') }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue'
import 'echarts-wordcloud'
import { TKeyWordsSpeakerMapped } from '@/types/types'
import set from 'lodash/set'
import { useCallAiTabInject } from '@/composables/callAiTabProvideInject.ts'
import i18n from '@/plugins/i18n.ts'
import cloud from './game-cloud.svg'
import {
    INIT_OPTIONS_KEY,
    TCallbackDataParams,
    TChartRender,
    THEME_KEY,
    TVChart
} from '@/composables/useEChartComponents.ts'
import { DEFAULT_CHART_THEME } from '@/composables/useColorsComposable.ts'

const { wordCloudItemClick } = useCallAiTabInject()

const DEFAULT_HEIGHT = '274px'

interface IProps {
    keywords: Array<TKeyWordsSpeakerMapped>
    theme?: string
    height?: string
    printMode?: boolean
    showMask?: boolean
}

const props = defineProps<IProps>()

const initOptions = computed(() => ({
    renderer: 'canvas' as TChartRender,
    height: props.height ?? DEFAULT_HEIGHT,
}))

provide(THEME_KEY, props.theme || DEFAULT_CHART_THEME)
provide(INIT_OPTIONS_KEY, initOptions)

/* Data */
const chartRef = ref<TVChart>()

const options = ref({
    animation: !props.printMode,
    tooltip: {
        show: !props.printMode,
        renderMode: 'html'
    },
    series: [
        {
            type: 'wordCloud',
            shape: 'circle',
            left: 'center',
            top: 'center',
            width: '100%',
            height: '100%',
            sizeRange: [ 20, 80 ], // min max in px
            rotationRange: [ 0, 0 ],
            // rotationStep: 45,
            gridSize: 10,
            drawOutOfBound: false,
            layoutAnimation: !props.printMode,
            textStyle: {
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                // Color can be a callback function or a color string
                color: function () {
                    // Random color
                    return 'rgb(' + [
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160)
                    ].join(',') + ')'
                }
            },
            emphasis: {
                focus: 'self',
                textStyle: {
                    textShadowBlur: 10,
                    textShadowColor: '#333'
                    // color: '#528',
                    // shadowBlur: 10,
                    // shadowColor: 'rgba(243,241,241,0.5)',
                    // textShadowColor: '#fff',
                    // textShadowBlur: 4,
                }
            },
            data: [ ...props.keywords ].sort((a, b) => b.value - a.value)
        }
    ]
})

function clickHandler (data: TCallbackDataParams) {
    if (wordCloudItemClick) {
        const { name, value = 0 } = data
        wordCloudItemClick({
            name,
            value: Number(value)
        })
        // highlight(name)
    }
}

// function highlight (word: string) {
//     chartRef.value?.dispatchAction({
//         type: 'downplay',
//         seriesIndex: [ 0 ]
//     })
//     if (word) {
//         const _index = props.keywords.findIndex(i => i.name === word)
//         if (_index > -1) {
//             chartRef.value?.dispatchAction({
//                 type: 'showTip',
//                 seriesIndex: 0,
//                 dataIndex: _index
//             })
//         }
//     }
// }

function init () {
    const maskImage = new Image()
    maskImage.src = cloud
    maskImage.onload = () => {
        if (props.printMode) {
            set(options.value, 'animation', false)
            set(options.value, 'series[0].gridSize', 5)
            set(options.value, 'series[0].sizeRange', [ 15, 60 ])
        }
        set(options.value, 'series[0].maskImage', maskImage)
        // const _options = options.value.series as Array<WordCloudSeriesOption>
        // if (_options[0]) {
        //     _options[0].maskImage = maskImage
        //     chartRef.value?.setOption(_options)
        // }
        // options.value.series[0].maskImage = maskImage
        chartRef.value?.setOption(options.value)
        // const _options = options.value.series as Array<WordCloudSeriesOption>
        // if (_options[0]) {
        //     _options[0].maskImage = maskImage
        //     chartRef.value?.setOption(_options)
        // }
        // options.value.series[0].maskImage = maskImage
        // chartRef.value.setOption(options)
    }
}

init()
</script>

<style lang="scss">
.word-cloud-chart {
    .mask {
        @apply absolute inset-0 w-full h-full;
        filter: opacity(0.05);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
    }
}
</style>

