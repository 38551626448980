import i18n from '@/plugins/i18n'


export function checkTranslateExist (key: string) {
    return i18n.t(key) !== key
}

export function getTranslateWithDefault (translateKey: string, defaultVal = '') {
    if (checkTranslateExist(translateKey)) {
        return i18n.t(translateKey)
    }
    return defaultVal
}
