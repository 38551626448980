<template>
    <div
        :class="`is--${type}`"
        class="w-full alert-message-card"
    >
        <div class="alert-message-card__content">
            <div class="flex-shrink-0 flex items-start">
                <i
                    :class="alertIcon"
                    class="icon-xl alert-message-card__icon"
                />
            </div>
            <div class="flex-grow">
                <div class="font-semibold">
                    {{ title }}
                </div>
                <div class="font-medium">
                    {{ message }}
                </div>
                <div class="w-full">
                    <slot name="actions" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    title?: string
    message?: string
    type?: 'info' | 'warning' | 'error'
}>()

const alertTypeIcons = {
    info: 'vc-lc-info',
    warning: 'vc-lc-triangle-alert',
    error: 'vc-lc-circle-alert'
}

const alertIcon = computed(() => {
    return props.type ? alertTypeIcons[props.type] || 'vc-lc-info' : 'vc-lc-info'
})
</script>

<style lang="scss">
.alert-message-card {
    @apply px-3 py-4 rounded-xl text-sm;
    &__content {
        @apply flex gap-2.5;
    }

    &.is--info {
        @apply bg-primary-actions-bg--focus;

        .alert-message-card__icon {
            @apply text-primary;
        }
    }

    &.is--warning {
        @apply bg-warning-actions-bg--focus;

        .alert-message-card__icon {
            @apply text-warning;
        }
    }

    &.is--error {
        @apply bg-error-actions-bg--focus;

        .alert-message-card__icon {
            @apply text-error-actions;
        }
    }
}
</style>
