<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="visible"
        :header="modalHeaderText"
        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        width="460px"
        @close="onCloseModal"
    >
        <div class="w-full">
            <AiGeneralQuestionForm
                ref="questionGeneralFormRef"
                v-model="questionModel"
                :used-keys="usedQuestionKeys"
            />
        </div>
        <template #footer>
            <div class="w-full flex items-center justify-between gap-2">
                <div
                    ref="optimizeBtnRef"
                    class="flex-center"
                >
                    <VcSplitButton
                        v-if="isButtonVisible"
                        :loading="optimizeLoading"
                        :disabled="isButtonDisabled"
                        :options="optimizePromptOptions"
                        :teleported="isTeleported"
                        color="primary-alternative"
                        @click="handleOptimizePrompt"
                    />
                </div>
                <div class="flex items-center gap-2">
                    <VcButton
                        type="outline"
                        color="secondary"
                        @click="onCloseModal"
                    >
                        {{ i18n.t('callHistoryView.cancel') }}
                    </VcButton>
                    <VcButton
                        :loading="loading"
                        color="primary"
                        @click="onSaveModal"
                    >
                        {{ i18n.t('callHistoryView.save') }}
                    </VcButton>
                </div>
            </div>
        </template>
    </VcModal>
</template>

<script setup lang="ts">
import { isTeleported, modalAppendToElement } from '@/services/GlobalState.ts'
import { computed, ref } from 'vue'
import AiGeneralQuestionForm from '@/components/AiSettings/Forms/AiGeneralQuestionForm.vue'
import { IAiQuestionRequest } from '@/types/aiManager'
import { QUESTION_RELATION_TYPES } from '@/enums/aiManagement.enum.ts'
import i18n from '@/plugins/i18n.ts'
import { VcSplitButton } from '@voicenter-team/voicenter-ui-plus'
import useOptimizeQuestionButton from '@/composables/useOptimizeQuestionButton.ts'

const DEFAULT_MODEL = {
    Key: '',
    Value: '',
    Source: '',
    QuestionRelationType: QUESTION_RELATION_TYPES.custom,
    AnswerDataTypeID: undefined,
    AnswerDataType: '',
    QuestionID: undefined,
    OriginalQuestionID: undefined,
}

defineProps<{
    usedQuestionKeys?: Array<{ Key: string, uid?: string | number }>
}>()
const emit = defineEmits<{
    (e: 'save', pl: IAiQuestionRequest): void
}>()

const loading = ref(false)
const questionGeneralFormRef = ref<typeof AiGeneralQuestionForm>()
const visible = ref(false)
const questionModel = ref<IAiQuestionRequest>({ ...DEFAULT_MODEL })
const optimizeBtnRef = ref()

/* Optimize prompt/schema button */
const {
    isButtonVisible,
    isButtonDisabled,
    optimizeLoading,
    optimizePromptOptions,
    handleOptimizePrompt
} = useOptimizeQuestionButton(questionModel, optimizeBtnRef)

const isReplacementQuestion = computed(() => {
    return questionModel.value.QuestionRelationType === QUESTION_RELATION_TYPES.replacement
})
const modalHeaderText = computed(() => {
    return isReplacementQuestion.value
        ? i18n.t('callHistoryView.manageAi.overwriteQuestionTitle')
        : i18n.t('callHistoryView.manageAi.editQuestionTitle')
})

function onCloseModal () {
    visible.value = false
    loading.value = false
    resetData()
}

function onOpenModal (initData?: IAiQuestionRequest) {
    if (initData) {
        questionModel.value = initData ? { ...initData } : {
            ...DEFAULT_MODEL
        }
    }
    visible.value = true

}

function resetData () {
    questionModel.value = { ...DEFAULT_MODEL }
}

async function onSaveModal () {
    loading.value = true
    const validateResult = await questionGeneralFormRef.value?.validate()
    if (validateResult?.isValid) {
        emit('save', {
            ...questionModel.value,
            Key: questionModel.value.Key?.replace(/\s+/g, '_')
        })
    } else {
        loading.value = false
    }
}

defineExpose({
    open: onOpenModal,
    close: onCloseModal,
    setLoading: (state: boolean) => loading.value = state
})
</script>
