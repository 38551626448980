<template>
    <div class="ai-category-questions w-full">
        <BaseVerticalExpandItem
            :block-title="i18n.t('callHistoryView.manageAi.form.questionsTitle')"
            :block-description="i18n.t('callHistoryView.manageAi.form.questionsText')"
        >
            <VcCard class="p-3 lg:p-6">
                <div class="w-full h-full">
                    <div class="w-full flex items-center justify-between gap-3">
                        <div class="flex items-center gap-2">
                            <div class="direction-ltr flex-center">
                                <VcSwitch
                                    v-model="showReplacedQuestions"
                                />
                            </div>
                            {{ i18n.t('callHistoryView.manageAi.form.showReplacedQuestions') }}
                        </div>
                        <div class="flex items-center gap-2">
                            <span class="text-secondary">
                                {{
                                    i18n.t('callHistoryView.manageAi.maxInsightQuestions', {total: maxInsightQuestions})
                                }}
                            </span>
                            <VcButton
                                type="borderless"
                                icon="vc-lc-plus"
                                @click="onAddNewQuestion"
                            >
                                {{ i18n.t('callHistoryView.manageAi.form.addQuestion') }}
                            </VcButton>
                        </div>
                    </div>
                    <div class="w-full pt-5 xl:pt-6">
                        <VcTable
                            :columns="tableAiCategoryQuestionsColumns"
                            :options="questionDataTable"
                            :loading="loading"
                            :empty-data-text="i18n.t('callHistoryView.noData')"
                            compact
                            stripe
                        >
                            <template #actions-row="{row}">
                                <div class="flex gap-1 px-2">
                                    <VcButtonIcon
                                        v-if="!hasReplacementQuestion(row)"
                                        icon="vc-lc-settings"
                                        @click="onEditQuestion(row)"
                                    />
                                    <VcButtonIcon
                                        v-if="row.QuestionRelationType !== QUESTION_RELATION_TYPES.original"
                                        icon="vc-lc-trash-2"
                                        @click="onRemoveQuestion(row)"
                                    />
                                </div>
                            </template>
                        </VcTable>
                    </div>
                </div>
            </VcCard>
        </BaseVerticalExpandItem>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import BaseVerticalExpandItem from '@/components/Forms/BaseVerticalExpandItem.vue'
import { IAiQuestionRequest } from '@/types/aiManager'
import i18n from '@/plugins/i18n.ts'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import tableAiCategoryQuestionsColumns from '@/enums/tables/aiCategoryQuestions.enum.ts'
import { QUESTION_RELATION_TYPES } from '@/enums/aiManagement.enum.ts'

const { AiQuestionRelationTypes } = useManageAiCategoriesData()

const props = defineProps<{
    loading?: boolean
    questions: Array<IAiQuestionRequest>
    maxInsightQuestions?: number
}>()

const emit = defineEmits<{
    (e: 'question:add'): void
    (e: 'question:edit', pl: IAiQuestionRequest): void
    (e: 'question:remove', pl: IAiQuestionRequest): void
}>()

const showReplacedQuestions = ref(false)

const questionRelationTypes = computed(() => {
    const entries = AiQuestionRelationTypes.value.map(item => [ item.AiQuestionRelationTypeID, item ])
    return Object.fromEntries(entries)
})
const questionDataTable = computed(() => {
    const questions = showReplacedQuestions?.value ? [ ...props.questions ]
        : [ ...props.questions ]
            .filter(i => {
                if (i.QuestionRelationType === QUESTION_RELATION_TYPES.original) {
                    return !props.questions.find(repl => repl.QuestionRelationType === QUESTION_RELATION_TYPES.replacement && repl.Key === i.Key)
                }
                return true
            })

    return questions.map((question) => {
        const relTypeDesc = question.QuestionRelationType ? questionRelationTypes.value[question.QuestionRelationType]?.Description || question.QuestionRelationType : ''
        return {
            ...question,
            RelationName: i18n.t(`callHistoryView.relationTypes.${relTypeDesc}`)
        }
    })
})

function onRemoveQuestion (question: IAiQuestionRequest) {
    if (question.QuestionRelationType !== QUESTION_RELATION_TYPES.original) {
        emit('question:remove', question)
    }
}

function onEditQuestion (question: IAiQuestionRequest) {
    emit('question:edit', question)
}

function hasReplacementQuestion (question: IAiQuestionRequest) {
    return question.QuestionRelationType === QUESTION_RELATION_TYPES.original ? !!props.questions.find(i => i.QuestionRelationType === QUESTION_RELATION_TYPES.replacement && i.Key === question.Key) : false
}

function onAddNewQuestion () {
    emit('question:add')
}
</script>
