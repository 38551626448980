<template>
    <div
        :class="{'is--full': fullWidthContent}"
        class="base-vertical-expand-form-block"
    >
        <div class="form-header-block px-3">
            <div
                class="form-header-block__content"
            >
                <div class="font-semibold text-lg">
                    {{ blockTitle }}
                    <span
                        v-if="required"
                        class="text-primary-actions"
                    >*</span>
                </div>
                <slot name="description">
                    <div
                        v-if="blockDescription"
                        class="text-sm pt-3 pb-2"
                    >
                        {{ blockDescription }}
                    </div>
                </slot>
            </div>
            <div class="form-header-block__action">
                <span
                    v-if="!fullWidthContent"
                    class="flex-grow border-t border-ui-lines mx-2"
                />
                <span
                    class="cursor-pointer"
                    @click="isOpen = !isOpen"
                >
                    <i
                        :class="isOpen ? 'vc-icon-up' : 'vc-icon-down'"
                        class="text-active-elements icon-lg transition-all duration-300"
                    />
                </span>
            </div>
        </div>
        <CollapseTransition>
            <div
                v-show="isOpen"
                class="form-content-block px-3"
            >
                <div class="pt-2 lg:pt-4 pb-2">
                    <slot />
                </div>
            </div>
        </CollapseTransition>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CollapseTransition from '@/components/Transition/CollapseTransition.vue'

interface IProps {
    blockTitle?: string
    blockDescription?: string
    fullWidthContent?: boolean
    required?: boolean
}

withDefaults(
    defineProps<IProps>(),
    {
        blockTitle: '',
        blockDescription: '',
        fullWidthContent: false
    }
)

const isOpen = ref<boolean>(true)


</script>

<style lang="scss">
.base-vertical-expand-form-block {
    .form-header-block {
        @apply flex items-center;

        &__content {
            @apply w-full sm:w-1/2 lg:w-1/3;
        }

        &__action {
            @apply w-full sm:w-1/2 lg:w-2/3 flex-grow flex items-center;
        }
    }

    &.is--full {
        .form-header-block__content {
            @apply flex-grow;
        }

        .form-header-block__action {
            @apply w-auto flex-grow-0;
        }
    }
}
</style>
