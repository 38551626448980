import { computed, Ref, ref } from 'vue'
import { optimizePromptOption, optimizePromptOptionsAll, QUESTION_RELATION_TYPES } from '@/enums/aiManagement.enum.ts'
import { IAiQuestionRequest } from '@/types/aiManager'
import { useConfirmPopup, VcSplitButtonOption } from '@voicenter-team/voicenter-ui-plus'
import useCustomQuestions from '@/composables/useCustomQuestions.ts'
import i18n from '@/plugins/i18n.ts'
import { ICustomQuestionOptimizeOutputData, ICustomQuestionOptimizeRequest } from '@/types/customQuestions'
import { AIDataRef } from '@/composables/useAIData.ts'
import ApiAiManager from '@/services/API/ApiAiManager.ts'

type IOptimizePromptItem = VcSplitButtonOption & {
    id?: number
    value?: {
        OptimizePrompt: boolean
        OptimizeSchema: boolean
    }
}

export default function (questionModel: Ref<IAiQuestionRequest>, optimizeBtnRef?: Ref<HTMLElement>) {
    const optimizeLoading = ref(false)
    const { inputTokensLimitExceeded } = useCustomQuestions()

    const isButtonVisible = computed(() => {
        return questionModel.value.QuestionRelationType !== QUESTION_RELATION_TYPES.original
    })

    const isButtonDisabled = computed(() => {
        return Boolean(!questionModel.value.Value || inputTokensLimitExceeded.value)
    })

    const optimizePromptOptions = computed<Array<IOptimizePromptItem>>(() => {
        if (questionModel.value.QuestionRelationType !== QUESTION_RELATION_TYPES.custom || (questionModel.value.AnswerDataTypeID || 0) < 4) {
            return [ ...optimizePromptOption.value ]
        }
        return [ ...optimizePromptOptionsAll.value ]
    })

    async function handleOptimizePrompt (option: IOptimizePromptItem | undefined) {
        if (!option?.value || !questionModel.value.Value) {
            return
        }
        await useConfirmPopup({
            target: optimizeBtnRef,
            icon: 'vc-lc-sparkles',
            iconClasses: 'text-primary text-2xl',
            popoverMaxWidth: '380px',
            message: i18n.t('callHistoryView.confirmation.optimizeCustomQuestion'),
            confirmAction: {
                color: 'primary-alternative',
                icon: 'vc-lc-sparkles'
            },
            confirm: () => {
                sendOptimizePrompt(option)
            }
        })
    }

    async function sendOptimizePrompt (option: IOptimizePromptItem) {
        try {
            if (!option.value) {
                return
            }
            optimizeLoading.value = true
            const { OptimizePrompt, OptimizeSchema } = option.value

            const formData: ICustomQuestionOptimizeRequest = {
                Value: questionModel.value.Value || '',
                Language: AIDataRef.value?.languageData?.LanguageID ?? 1,
                QuestionID: questionModel.value.QuestionID,
                OptimizePrompt,
                OptimizeSchema,
                AnswerDataSchema: questionModel.value.AnswerDataSchema,
                AnswerDataTypeID: questionModel.value.AnswerDataTypeID,
            }

            const result: ICustomQuestionOptimizeOutputData = await ApiAiManager.customQuestionOptimize(formData)
            questionModel.value.Value = result.newPrompt || questionModel.value.Value
            questionModel.value.AnswerDataSchema = result.newSchema || questionModel.value.AnswerDataSchema
        } finally {
            optimizeLoading.value = false
        }
    }

    return {
        isButtonVisible,
        isButtonDisabled,
        optimizeLoading,
        optimizePromptOptions,
        inputTokensLimitExceeded,
        handleOptimizePrompt
    }
}
