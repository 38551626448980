import { reactive, toRefs } from 'vue'
import { ICustomQuestionsQuestion, IRemainingLimits } from '@/types/customQuestions'
import ApiAiManager from '@/services/API/ApiAiManager.ts'

interface ICustomQuestionsState {
    loading: boolean
    isCustomQuestionsUsed: boolean
    inputTokensLimitExceeded: boolean
    remainingLimitsData: IRemainingLimits
    questions: Array<ICustomQuestionsQuestion>
}

const customQuestionsState = reactive<ICustomQuestionsState>({
    loading: false,
    inputTokensLimitExceeded: false,
    isCustomQuestionsUsed: false,
    remainingLimitsData: {},
    questions: []
})

function refreshRemainingLimitsData (data?: IRemainingLimits) {
    if (!data) {
        customQuestionsState.remainingLimitsData = {}
        return
    }
    customQuestionsState.remainingLimitsData = {
        ...customQuestionsState.remainingLimitsData,
        ...data
    }
}

function addNewCustomQuestion (question: ICustomQuestionsQuestion) {
    customQuestionsState.questions = [
        ...customQuestionsState.questions,
        question
    ]
}

export default function () {

    const {
        loading,
        isCustomQuestionsUsed,
        questions,
        remainingLimitsData,
        inputTokensLimitExceeded
    } = toRefs(customQuestionsState)

    async function fetchCustomQuestionList (ivrId: string) {
        try {
            customQuestionsState.loading = true
            setCustomQuestions(true)
            const res = await ApiAiManager.getCustomQuestionList(ivrId)
            customQuestionsState.questions = res.OutputData?.Questions || []
            customQuestionsState.inputTokensLimitExceeded = res.ConfigData?.RemainingLimitsData?.Status === 400
            customQuestionsState.remainingLimitsData = res.ConfigData?.RemainingLimitsData?.RemainingLimits || {}
        } catch (err) {
            console.error('Error fetching custom question list:', err)
        } finally {
            customQuestionsState.loading = false
        }
    }

    function setCustomQuestions (state: boolean) {
        customQuestionsState.isCustomQuestionsUsed = state
    }

    function setInputTokensLimitExceededState (state: boolean) {
        customQuestionsState.inputTokensLimitExceeded = state
    }

    return {
        customQuestionsLoading: loading,
        customQuestions: questions,
        remainingLimitsData,
        isCustomQuestionsUsed,
        inputTokensLimitExceeded,
        fetchCustomQuestionList,
        setCustomQuestions,
        refreshRemainingLimitsData,
        addNewCustomQuestion,
        setInputTokensLimitExceededState
    }
}
