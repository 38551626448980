<template>
    <div class="call-history-tabs w-full">
        <VcTabs
            v-model="tabsModel"
            nav-scrollable
        >
            <VcTabPane
                v-for="tabItem in tabs"
                :key="tabItem.key"
                :name="tabItem.key"
                :label="i18n.t(tabItem.label)"
                :icon="tabItem.icon"
                :lazy="tabItem.lazy"
            >
                <div class="call-history-tab-item py-3 lg:py-6">
                    <component :is="tabItem.component" />
                </div>
            </VcTabPane>
        </VcTabs>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { TAB_GENERAL_KEY, TTabComponentData } from '@/enums/tabs.enum.ts'
import i18n from '@/plugins/i18n.ts'

const props = defineProps<{
    tabs: Array<TTabComponentData>
}>()

const tabsModel = ref<string>(TAB_GENERAL_KEY)

onMounted(() => {
    const _defaultTabKey = props.tabs[0]?.key || TAB_GENERAL_KEY
    const params = new URL(document.location.toString()).searchParams
    const initialTabName = params.get('tab')
    if (initialTabName) {
        tabsModel.value = props.tabs.find(i => i.key === initialTabName)?.key || _defaultTabKey
    } else {
        tabsModel.value = _defaultTabKey
    }
})
</script>

<style lang="scss">
.call-history-tabs {
    .vc-tabs__nav {
        @apply gap-0;
    }
}
</style>
