import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import i18n from '@/plugins/i18n.ts'
import { computed, ComputedRef } from 'vue'


const columns: ComputedRef<ColumnType> = computed(() => {
    return [
        {
            columnItemKey: 'time-column',
            rowItemKeyKey: 'time-row',
            rowItemLabelKey: '@timestamp',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.timestamp'),
            sortBy: 'timestamp',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'srcip-column',
            rowItemKeyKey: 'srcip-row',
            rowItemLabelKey: 'srcip',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.sourceIP'),
            sortBy: 'srcip',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'src_port-column',
            rowItemKeyKey: 'src_port-row',
            rowItemLabelKey: 'src_port',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.sourcePort'),
            sortBy: 'src_port',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'dstip-column',
            rowItemKeyKey: 'dstip-row',
            rowItemLabelKey: 'dstip',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.destIP'),
            sortBy: 'dstip',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'dst_port-column',
            rowItemKeyKey: 'dst_port-row',
            rowItemLabelKey: 'dst_port',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.destPort'),
            sortBy: 'dst_port',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'callid-column',
            rowItemKeyKey: 'callid-row',
            rowItemLabelKey: 'callid',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.callId'),
            sortBy: 'callid',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'packets-column',
            rowItemKeyKey: 'packets-row',
            rowItemLabelKey: 'payload.sender_information.packets',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.packets'),
            sortBy: 'payload.sender_information.packets',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'mos-column',
            rowItemKeyKey: 'mos-row',
            rowItemLabelKey: 'mos_payload.instant.mos',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.mos'),
            sortBy: 'mos_payload.average.mos',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'jitter-column',
            rowItemKeyKey: 'jitter-row',
            rowItemLabelKey: 'mos_payload.average.jitter',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.jitter'),
            sortBy: 'mos_payload.average.jitter',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        },
        {
            columnItemKey: 'pkt_loss-column',
            rowItemKeyKey: 'pkt_loss-row',
            rowItemLabelKey: 'mos_payload.average.pkt_loss',
            columnItemLabel: i18n.t('callHistoryView.rtcp.table.pkt_loss'),
            sortBy: 'mos_payload.average.pkt_loss',
            columnAlignment: 'left',
            rowItemAlignment: 'left'
        }
    ]
})

export default columns
