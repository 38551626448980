<template>
    <div class="call-history-preview-block">
        <div class="call-history-preview__header mb-6 xl:mb-8">
            <div class="text-base md:text-2xl font-medium">
                {{ i18n.t('callHistoryView.callID', { id: callId }) }}
            </div>
            <div class="flex justify-end">
                <slot name="header-actions" />
            </div>
        </div>
        <div class="call-history-preview__content">
            <!--        Top Statistic component   -->
            <CallGeneralInfoBlock
                v-if="generalCallData.length"
                :general-call-data="generalCallData"
            />
            <!--        Tabs Example component    -->
            <div v-if="tabsFiltered.length > 1">
                <CallHistoryTabs
                    :tabs="tabsFiltered"
                />
            </div>
            <!--        One Tab component         -->
            <div v-else-if="tabsFiltered.length === 1">
                <component :is="tabsFiltered[0].component" />
            </div>
            <!--         No Data Tabs             -->
            <div
                v-else
                class="flex-center p-5 rounded-md text-center bg-main-bg"
            >
                {{ i18n.t('callHistoryView.noData') }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import i18n from '@/plugins/i18n.ts'
import { computed } from 'vue'
import CallGeneralInfoBlock from '@/components/Blocks/CallGeneralInfoBlock.vue'
import { TABS_LIST } from '@/enums/tabs.enum.ts'
import CallHistoryTabs from '@/components/Tabs/CallHistoryTabs.vue'
import { generalCallData } from '@/composables/useCDRData.ts'


const props = defineProps<{
    callId?: string
    tabsToRender: Array<string>
}>()

const tabsFiltered = computed(() => {
    if (!props.tabsToRender.length) {
        return []
    }
    return TABS_LIST.filter(tab => props.tabsToRender.includes(tab.key) && !!tab.isVisible?.value)
        .sort(((a, b) => (a.order || 0) - (b.order || 0)))
})

</script>

<style lang="scss">
.call-history-preview {
    &-block {
        @apply p-3 lg:p-6;
        container-type: inline-size;

        .block-title {
            @apply font-semibold text-lg;
        }
    }

    &__content {
        @apply flex flex-col gap-5 lg:gap-8 xl:gap-10;
    }

    &__header {
        @apply flex flex-col md:flex-row gap-2 justify-start md:justify-between;
    }
}
</style>
