import { computed, ref } from 'vue'
import { IAiCategoryUpsert, IAiPromptConfig, IAiQuestion, IAiQuestionRequest } from '@/types/aiManager'
import cloneDeep from 'lodash/cloneDeep'
import {
    CATEGORY_TYPE_ACCOUNT,
    categoryPromptDefaultModel,
    getDefaultCategoryModel,
    MAX_INSIGHT_QUESTIONS,
    QUESTION_RELATION_TYPES
} from '@/enums/aiManagement.enum.ts'
import useManageAiCategoriesData, { isCustomPromptSettings } from '@/composables/useManageAiCategories.ts'
import { useUniqueIDWithPrefix } from '@/helpers/useUniqueId.ts'

const { getCategoryById, AiOriginalCategories } = useManageAiCategoriesData()
const uniqueQuestionId = useUniqueIDWithPrefix('question')

function getQuestionsMappedWithUid (question: IAiQuestionRequest) {
    return {
        ...question,
        uid: question.uid || uniqueQuestionId()
    }
}

function addOriginalQuestions (questions: Array<Partial<IAiQuestion>>, originalCatID?: number) {
    const originalQuestions = getCategoryById(originalCatID)?.AiPrompts?.Insights?.Questions || []
    originalQuestions.forEach(item => {
        if (!questions.find(i => i.Key === item.Key && i.QuestionRelationType === QUESTION_RELATION_TYPES.original)) {
            questions.push({ ...item })
        }
    })

    return questions
}

function getRemainingQuestions (questionsArray: Array<IAiQuestionRequest>, limit: number = 10) {
    const uniqueQuestions = new Map()

    questionsArray.forEach(question => {
        if (!uniqueQuestions.has(question.Key)) {
            uniqueQuestions.set(question.Key, question)
        }
    })

    return limit - uniqueQuestions.size
}

export default function useAiCategoryEditableData (categoryInitData: IAiCategoryUpsert) {
    /* Data */
    const categoryGeneralModel = ref<IAiCategoryUpsert>(cloneDeep(categoryInitData))
    const categoryQuestionsModel = ref<Array<IAiQuestionRequest>>([])
    const categoryPromptConfigs = ref<IAiPromptConfig>({ ...categoryPromptDefaultModel })
    const maxInsightQuestions = ref(0)
    const categoryEngineModel = ref<number | undefined>()

    /* Computed */
    const originalCategoryData = computed(() => {
        return getCategoryById(categoryGeneralModel.value.OriginalCatID)
    })
    const usedQuestionKeys = computed(() => {
        return categoryQuestionsModel.value.map(categoryQuestion => {
            return {
                Key: categoryQuestion.Key || '',
                uid: categoryQuestion.uid
            }
        })
    })
    const availableCountQuestions = computed(() => {
        return getRemainingQuestions(categoryQuestionsModel.value, maxInsightQuestions.value)
    })

    /* Methods */
    function setCategoryDataModel (data: IAiCategoryUpsert, CategoryType = CATEGORY_TYPE_ACCOUNT) {
        const {
            CategoryID,
            CategoryName,
            Status,
            AccountID,
            MaxInsightQuestions,
            OriginalCatID,
            Description,
        } = data

        const questions = data.AiPrompts?.Insights?.Questions || []
        categoryQuestionsModel.value = addOriginalQuestions(questions, OriginalCatID).map(getQuestionsMappedWithUid)

        categoryGeneralModel.value = {
            CategoryName,
            Description,
            Status,
            AccountID,
            CategoryID,
            MaxInsightQuestions,
            OriginalCatID,
            CategoryType
        }
        const prompts = data?.AiPrompts?.Insights?.PromptConfig ?? categoryPromptDefaultModel
        categoryPromptConfigs.value = {
            ...prompts,
            Body: prompts.Body || '',
            Footer: prompts.Footer || '',
            System: prompts.System || '',
            MaxTokens: prompts.MaxTokens || 10,
            Transcript: prompts.Transcript || '',
        }
        maxInsightQuestions.value = MaxInsightQuestions || MAX_INSIGHT_QUESTIONS
        categoryEngineModel.value = data.AiPrompts?.Insights?.AiEngineID
    }

    function resetAllStates (setupData?: IAiCategoryUpsert) {
        const generalInitData = setupData ?? getDefaultCategoryModel()
        const category = AiOriginalCategories.value[0]
        categoryGeneralModel.value = cloneDeep(generalInitData)
        categoryGeneralModel.value.OriginalCatID = category?.CategoryID
        categoryEngineModel.value = category.AiPrompts?.Insights?.AiEngineID
        maxInsightQuestions.value = category.MaxInsightQuestions ?? 0
        const defaultPrompt = category ? category.AiPrompts.Insights?.PromptConfig : {}
        categoryPromptConfigs.value = cloneDeep({
            ...categoryPromptDefaultModel,
            ...defaultPrompt
        })
        const questions = category?.AiPrompts?.Insights?.Questions ?? []
        categoryQuestionsModel.value = [ ...questions ]
    }

    function addQuestionToGroup (question: IAiQuestionRequest) {
        if (!categoryQuestionsModel.value.find(q => q.Key === question.Key)) {
            categoryQuestionsModel.value.push(cloneDeep({
                ...question,
                uid: uniqueQuestionId()
            }))
        }
    }

    function isTheSameQuestionByID (questionFirst: IAiQuestionRequest, questionSecond: IAiQuestionRequest) {
        return questionFirst.QuestionID && questionSecond.QuestionID ? questionFirst.QuestionID === questionSecond.QuestionID : questionFirst.uid === questionSecond.uid
    }

    function updateGroupQuestion (question: IAiQuestionRequest) {
        if (question.QuestionRelationType === QUESTION_RELATION_TYPES.original) {
            categoryQuestionsModel.value.push(cloneDeep({
                ...question,
                QuestionID: undefined,
                QuestionRelationType: QUESTION_RELATION_TYPES.replacement,
                uid: question.uid || uniqueQuestionId()
            }))
        } else if (question.QuestionRelationType === QUESTION_RELATION_TYPES.replacement && !categoryQuestionsModel.value.find(i => i.QuestionRelationType === question.QuestionRelationType && i.Key === question.Key)) {
            categoryQuestionsModel.value.push(cloneDeep({
                ...question,
                QuestionRelationType: QUESTION_RELATION_TYPES.replacement,
                uid: question.uid || uniqueQuestionId()
            }))
        } else {
            categoryQuestionsModel.value = categoryQuestionsModel.value.map(i => {
                if (isTheSameQuestionByID(i, question) && i.QuestionRelationType === question.QuestionRelationType) {
                    return {
                        ...question,
                        uid: i.uid || uniqueQuestionId()
                    }
                }
                return {
                    ...i,
                    uid: i.uid || uniqueQuestionId()
                }
            })
        }
    }

    function getPromptSettings () {
        const { Body, Footer, System, MaxTokens, Transcript } = categoryPromptConfigs.value
        return isCustomPromptSettings(categoryPromptConfigs.value, categoryGeneralModel.value.OriginalCatID) ? {
            Body,
            Footer,
            System,
            MaxTokens,
            Transcript
        } : {
            Body: null,
            Footer: null,
            System: null,
            MaxTokens: null,
            Transcript: null
        }
    }

    return {
        categoryGeneralModel,
        categoryQuestionsModel,
        maxInsightQuestions,
        categoryEngineModel,
        categoryPromptConfigs,
        originalCategoryData,
        usedQuestionKeys,
        availableCountQuestions,
        setCategoryDataModel,
        resetAllStates,
        addQuestionToGroup,
        updateGroupQuestion,
        getQuestionsMappedWithUid,
        getPromptSettings,
        uniqueQuestionId
    }
}
