<template>
    <VcForm
        ref="formRef"
        :model="localModel"
        class="general-question-form"
    >
        <div class="w-full">
            <VcFormItem
                :rules="[required]"
                :label="i18n.t('callHistoryView.manageAi.form.prompt')"
                prop="Value"
            >
                <VcInput
                    v-model="localModel.Value"
                    :placeholder="i18n.t('callHistoryView.manageAi.form.promptPlaceholder')"
                    type="textarea"
                    autosize
                    rows="2"
                />
            </VcFormItem>
            <VcFormItem
                :rules="[required, { validator: isUniqueKeyValidate }]"
                :label="i18n.t('callHistoryView.manageAi.form.questionParameter')"
                prop="Key"
            >
                <p class="text-sm leading-1 text-secondary mb-2">
                    {{ i18n.t('callHistoryView.manageAi.form.questionNameHelpText') }}
                </p>
                <VcInput
                    v-model="localModel.Key"
                    :placeholder="i18n.t('callHistoryView.manageAi.form.questionParameterPlaceholder')"
                    :disabled="!isCustomQuestion"
                />
            </VcFormItem>
            <VcFormItem
                :rules="[required]"
                :label="i18n.t('callHistoryView.manageAi.form.parameterDataType')"
                prop="AnswerDataTypeID"
            >
                <VcSelect
                    v-model="localModel.AnswerDataTypeID"
                    :placeholder="i18n.t('callHistoryView.manageAi.form.parameterDataTypePlaceholder')"
                    :options="parameterDataTypes"
                    :disabled="!isCustomQuestion"
                    :config="{
                        labelKey: 'label',
                        valueKey: 'ID'
                    }"
                    :teleported="isTeleported"
                    @change="onAnswerDataTypeChange"
                />
            </VcFormItem>
            <VcFormItem
                v-if="isJsonScheme"
                :label="i18n.t('callHistoryView.manageAi.jsonSchema')"
                prop="AnswerDataSchema"
            >
                <JsonSchemaEditor
                    v-model="localModel.AnswerDataSchema"
                    :disabled="!isCustomQuestion"
                    :data-type="localModel.AnswerDataTypeID"
                />
            </VcFormItem>
        </div>
    </VcForm>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { VcForm } from '@voicenter-team/voicenter-ui-plus'
import useValidationRules from '@/composables/useValidationRules.ts'
import useManageAiCategoriesData from '@/composables/useManageAiCategories.ts'
import i18n from '@/plugins/i18n.ts'
import { IAiAnswerType, IAiQuestionRequest } from '@/types/aiManager'
import { useVModel } from '@vueuse/core'
import { QUESTION_RELATION_TYPES } from '@/enums/aiManagement.enum.ts'
import JsonSchemaEditor from '@/components/Common/JsonSchemaEditor.vue'
import { isTeleported } from '@/services/GlobalState.ts'

const props = defineProps<{
    modelValue: IAiQuestionRequest
    usedKeys?: Array<{ Key: string, uid?: string | number }>
}>()
const emit = defineEmits<{
    (e: 'update:modelValue', pl: IAiQuestionRequest): void
}>()

const { AiAnswerTypes } = useManageAiCategoriesData()
const { required } = useValidationRules()

const formRef = ref<typeof VcForm>()
const localModel = useVModel(props, 'modelValue', emit)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUniqueKeyValidate = (_: any, value: any, callback: any) => {
    if (localModel.value?.QuestionRelationType !== QUESTION_RELATION_TYPES.replacement) {
        const normalizedVal = getReplacedSpaces(value)
        const isUsedKey = props.usedKeys?.find(item => localModel.value.uid ? item.Key === normalizedVal && item.uid !== localModel.value.uid : item.Key === normalizedVal)
        if (isUsedKey) {
            callback(new Error(i18n.t('callHistoryView.manageAi.form.questionParameterUniqueError')))
        }
        callback()
    } else {
        callback()
    }
}

const isCustomQuestion = computed(() => {
    return localModel.value.QuestionRelationType === QUESTION_RELATION_TYPES.custom
})
const isJsonScheme = computed(() => {
    return (localModel.value.AnswerDataTypeID || 0) > 3
})
const parameterDataTypes = computed(() => {
    return AiAnswerTypes.value?.map((item) => ({
        ...item,
        label: i18n.t(`callHistoryView.dataTypes.${item.TypeName}`),
    })).sort((a, b) => a.label.localeCompare(b.label))
})

/* Methods */
function getReplacedSpaces (value: string) {
    return value.replace(/\s+/g, '_')
}

function onAnswerDataTypeChange (optionType?: IAiAnswerType) {

    localModel.value.AnswerDataType = optionType?.TypeName
    localModel.value.AnswerDataSchema = undefined // or optionType.Example but now it is [object Object]
}

watch(
    parameterDataTypes,
    (val) => {
        const defaultParameterType = 2
        const defaultParameter = val?.find(item => item.ID === defaultParameterType)
        localModel.value.AnswerDataTypeID = localModel.value.AnswerDataTypeID || defaultParameterType
        localModel.value.AnswerDataType = localModel.value.AnswerDataType || defaultParameter?.TypeName || ''
    },
    {
        immediate: true
    }
)

defineExpose({
    validate: () => formRef.value?.validate(),
    resetForm: () => formRef.value?.resetFields()
})
</script>
