<template>
    <div class="w-full call-rtcp-tab">
        <div class="call-rtcp-tab__content">
            <div class="block-title mb-3 lg:mb-6">
                {{ i18n.t('callHistoryView.rtcp.tableTitle') }}
            </div>
            <div class="call-rtcp-tab__table">
                <VcTable
                    :loading="dataLoading"
                    :columns="rtcpDataTableEnum"
                    :options="rtcpData"
                    :empty-data-text="i18n.t('callHistoryView.noData')"
                    with-pagination
                    compact
                    stripe
                />
            </div>
            <hr class="border-ui-lines my-10">
            <div
                class="call-rtcp-tab__chart"
                dir="ltr"
            >
                <CallRtcpTabChart
                    v-if="isChartVisible"
                    :data="rtcpTimelineData"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CallRtcpTabChart from '@/components/Chart/CallRtcpTabChart.vue'
import useRtcpStatsData from '@/composables/useRtcpStatsData.ts'
import rtcpDataTableEnum from '@/enums/tables/rtcpData.table.enum.ts'
import { computed } from 'vue'
import i18n from '@/plugins/i18n.ts'

const { dataLoading, rtcpTimelineData, rtcpData } = useRtcpStatsData()

const isChartVisible = computed(() => {
    return rtcpTimelineData.value && (rtcpTimelineData.value.avgJitter.length || rtcpTimelineData.value.avgMos.length || rtcpTimelineData.value.avgPctLoss.length)
})
</script>

